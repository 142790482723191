@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .md-objects {
        .md-object {
            a {
                &.btn {
                    min-width: 90px;
                }
            }
        }
    }
}

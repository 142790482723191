@import '_variables.scss';
// =================================================
// Cacher les infos sur le format shortcode durant l'édition d'un contenu */
.filter-guidelines-shortcode {
    display: none;
}

// =================================================
body {
    color: #666;
    font-size: 1.6rem;
    line-height: 1.6;
}

p {
    text-align: justify;
    font-weight: 400;
    margin-bottom: 10px;
}

/*
a:not(.btn) {
    color: #EF578E;
}

a:not(.btn):hover {
    text-decoration: none;
}*/

a,
a:hover {
    text-decoration: none;
    outline: none;
    transition: all 200ms ease 0s 0.5s linear;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    line-height: 1.3;
    margin: 0 0 22px;
}

h1 {
    font-size: 3rem;
}

h2,
table caption {
    font-size: 2rem;
}

h3 {
    font-size: 1.9rem;
}

h4 {
    font-size: 1.8rem;
}

h5 {
    font-size: 1.7rem;
}

h6 {
    font-size: 1.6rem;
}

p {
    margin-bottom: 22px;
}

.rtecenter {
    margin: auto;
    text-align: center;
}

.container-video, .container-iframe {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  iframe, video {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.container-iframe {
  overflow: hidden;
}

table caption {
    font-weight: 300;
    line-height: normal;
    margin: 10px 0;
    padding: 0px;
    text-align: left;
}

ul.pagination {
    margin: 20px 0;
    border-radius: 0;
}

ul.pagination li {
    display: inline-block;
    margin: 0 !important;
}

.region-content-bottom-second ul {
    list-style: none outside none;
}

.region-content-bottom-second ul li:before {
    color: #049cdb;
    content: "\002022 ";
    font-family: verdana;
    margin-left: -12px;
    font-size: 12px;
    margin-right: 10px;
}

a.site-logo img {
    width: 75%;
}

ul.star,
ul.tick,
ul.arrow {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.star li:before,
ul.tick li:before,
ul.arrow li:before {
    content: "\f006";
    font-family: FontAwesome;
    padding-right: 5px;
    font-size: 11px;
}

ul.tick li:before {
    content: "\f00c" !important;
}

ul.arrow li:before {
    content: "\f105" !important;
}

.front .box-feature h2.feature-title,
.box-feature .feature-text {
    display: none!important;
}

button,
input[type="submit"],
input[type="button"] {
    border: 0;
    outline: none;
}


/* listes */

.region-content ul,
.region-content ol,
body.cke_show_borders ul,
body.cke_show_borders ol {}

.region-content ul li,
.colonne_droite ul li,
body.cke_show_borders ul li {
    text-align: justify;
}

.region-content ul li ul li,
colonne_droite ul li ul li,
body.cke_show_borders ul li ul li,
#block-system-main ul li ul li {}

ul.inline li,
.tabs ul li {
    background: none !important
}

.region-content ol {
    margin-left: 30px
}

.region-content ol li {
    margin-bottom: 7px
}

.region-content ol li ul li,
.region-content ul li ul li {
    margin-top: 5px
}


/* fieldset */

fieldset {
    border: 1px solid #dadada;
    padding: 15px;
}

legend {
    font-weight: bold;
    color: #799e18;
    padding: 0 5px
}


/**** cartouche doc type *************/

.btn.btn-primary a {
    color: white
}

.btn-download,
.btn-download:visited {
    background: #1D61A1;
}

dl.doc {
    position: relative;
    margin: 0 0 25px;
    padding: 0;
    clear: both;
    float: left;
    width: 100%;
    background-color: #F9F9F9;
    dd.download {
        //margin: 20px auto;
        a {
            color: white
        }
    }
    dt.nomDoc {
        border-bottom: 1px solid #CCCCCC;
        color: #6294D1;
        line-height: 19px;
        width: 100%;
        background-color: white;
        &:before {
            font-family: fontawesome;
            font-size: 24px;
            margin-right: 10px;
        }
        &.pdf:before {
            content: '\f1c1';
        }
        &.lien:before {
            content: '\f0c1';
        }
        img {
            margin: 0 5px 0 0;
        }
        span {
            color: #666;
            font-size: 10px;
            font-weight: normal;
        }
    }
    dt {
        color: #999999;
        font-weight: normal;
    }
    dd p {
        margin: 0;
        padding: 0;
    }
    dd.titreDoc {
        span {
            color: #666;
            font-weight: normal;
        }
    }
    dt.resumeDoc,
    dd.resumeDoc {
        display: none;
    }
}


/* Tables */

table th,
.table-striped>tbody>tr:nth-child(1)>th,
.table-striped thead th {
    background-color: #1D61A1;
    color: white;
    font-weight: 100;
}

.content table {
    margin-bottom: 15px
}


/* Template Webmaster */

.template-edit {
    margin-right: 10px;
}

.hr {
    margin: auto;
    width: 100%;
    height: 1px
}

.cellspacing {
    border-spacing: 3px;
    border-collapse: separate;
}

@media only screen and (min-width: 768px) {
    dl.doc {
        dt {
            clear: both;
            float: left;
            width: 14%;
            margin: 0px;
            padding: 0 0 0 2px;
        }
        dd.download {
            position: absolute;
            top: -15px;
            right: 0;
        }
    }
}


/* Button */

dd.download,
dd.download:visited,
.btn,
.btn:visited {
    background: $gris-fonce;
    border: 0 none;
    border-radius: 3px;
    box-shadow: none;
    color: white;
    line-height: 22px;
    outline: none;
    padding: 9px 20px;
    text-shadow: none;
    -webkit-filter: none;
    filter: none;
    -webkit-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
    white-space: normal;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled],
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background: $gris-clair;
    color: white;
    outline: none;
    -webkit-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
}

.btn:active,
.btn.active,
.btn-group.open .btn.dropdown-toggle {
    background: #1e1e1e;
    background-color: rgba(30, 30, 30, .75);
    box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
    outline: none;
}

.btn-group.open .btn.dropdown-toggle {
    background: #1e1e1e;
    background-color: rgba(30, 30, 30, .75);
    box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
}

.btn.btn-inverse,
.btn.btn-inverse:visited {
    background: #ccc;
    background: rgba(30, 30, 30, .3);
}

.btn.btn-inverse:hover,
.btn.btn-inverse:focus,
.btn.btn-inverse:active,
.btn.btn-inverse.active,
.btn.btn-inverse.disabled,
.btn.btn-inverse[disabled] {
    color: #efefef;
    background-color: #000;
}

.btn.btn-inverse:active,
.btn.btn-inverse.active,
.btn-group.open .btn.dropdown-toggle.btn-inverse {
    background-color: #ccc;
}

.btn.btn-primary,
.btn.btn-primary:visited {
    background: #037ac5;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active,
.btn.btn-primary.disabled,
.btn.btn-primary[disabled] {
    color: white;
    background-color: #048ce2;
}

.btn.btn-primary:active,
.btn.btn-primary.active,
.btn-group.open .btn.dropdown-toggle.btn-primary {
    background-color: #048ce2;
}

.btn.btn-warning,
.btn.btn-warning:visited {
    background: #f89406;
}

.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning.active,
.btn.btn-warning.disabled,
.btn.btn-warning[disabled] {
    color: white;
    background-color: #fda425;
}

.btn.btn-warning:active,
.btn.btn-warning.active,
.btn-group.open .btn.dropdown-toggle.btn-warning {
    background-color: #fda425;
}

.btn.btn-danger,
.btn.btn-danger:visited {
    background: #c10841;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger.active,
.btn.btn-danger.disabled,
.btn.btn-danger[disabled] {
    color: white;
    background-color: #d70a49;
}

.btn.btn-danger:active,
.btn.btn-danger.active,
.btn-group.open .btn.dropdown-toggle.btn-danger {
    background-color: #d70a49;
}

.btn.btn-success,
.btn.btn-success:visited {
    background: #738d00;
}

.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active,
.btn.btn-success.disabled,
.btn.btn-success[disabled] {
    color: white;
    background-color: #8aa902;
}

.btn.btn-success:active,
.btn.btn-success.active,
.btn-group.open .btn.dropdown-toggle.btn-success {
    background-color: #8aa902;
}

.btn.btn-info,
.btn.btn-info:visited {
    background: #0098ca;
}

.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info.active,
.btn.btn-info.disabled,
.btn.btn-info[disabled] {
    color: white;
    background-color: #02a9e0;
}

.btn.btn-info:active,
.btn.btn-info.active,
.btn-group.open .btn.dropdown-toggle.btn-info {
    background-color: #02a9e0;
}

.btn.btn-link,
.btn.btn-link:active,
.btn.btn-link[disabled] {
    background: none;
    box-shadow: none;
}

.btn.btn-link,
.btn.btn-link:visited {
    cursor: pointer;
    color: #2e5481;
}

.btn.btn-link:hover,
.btn.btn-link:focus {
    background: none;
    color: #2e5481;
    text-decoration: underline;
}

.btn.btn-link[disabled]:hover,
.btn.btn-link[disabled]:focus {
    opacity: .8;
    filter: alpha(opacity=80);
}

.btn.btn-red,
.btn.btn-red:visited {
    background: #ed093a;
}

.btn.btn-red:hover,
.btn.btn-red:focus,
.btn.btn-red:active,
.btn.btn-red.active,
.btn.btn-red.disabled,
.btn.btn-red[disabled] {
    color: white;
    background-color: #fb4c71;
}

.btn.btn-white,
.btn.btn-white:visited {
    background: white;
    color: #1e1e1e;
}

.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active,
.btn.btn-white.active,
.btn.btn-white.disabled,
.btn.btn-white[disabled],
.btn-group.open .btn.dropdown-toggle.btn-white {
    color: #1e1e1e;
    background-color: white;
    background-color: rgba(255, 255, 255, .8);
}

.btn.disabled,
.btn[disabled] {
    box-shadow: none;
}

.btn.btn-border {
    background: none;
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
    padding-top: 9px;
    padding-bottom: 9px;
}

.btn.btn-border .caret {
    color: #1e1e1e;
}

.btn.btn-border.btn-inverse,
.btn.btn-border.btn-inverse .caret {
    border-color: rgba(30, 30, 30, .3);
    color: rgba(30, 30, 30, .3);
}

.btn.btn-border.btn-primary,
.btn.btn-border.btn-primary .caret {
    border-color: #037ac5;
    color: #037ac5;
}

.btn.btn-border.btn-warning,
.btn.btn-border.btn-warning .caret {
    border-color: #f89406;
    color: #f89406;
}

.btn.btn-border.btn-danger,
.btn.btn-border.btn-danger .caret {
    border-color: #c10841;
    color: #c10841;
}

.btn.btn-border.btn-success,
.btn.btn-border.btn-success .caret {
    border-color: #738d00;
    color: #738d00;
}

.btn.btn-border.btn-info,
.btn.btn-border.btn-info .caret {
    border-color: #0098ca;
    color: #0098ca;
}

.btn.btn-border:hover,
.btn.btn-border:focus,
.btn.btn-border:active,
.btn.btn-border.active,
.btn.btn-border.disabled,
.btn.btn-border[disabled],
.btn-group.open .btn.dropdown-toggle.btn-border,
.btn.btn-border:hover .caret,
.btn.btn-border:focus .caret,
.btn.btn-border:active .caret,
.btn.btn-border.active .caret,
.btn.btn-border.disabled .caret,
.btn.btn-border[disabled] .caret,
.btn-group.open .btn.dropdown-toggle.btn-border .caret {
    color: white;
}

.btn-group>.btn:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.btn-group>.btn:last-child,
.btn-group>.dropdown-toggle {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn.btn-lg {
    font-size: 14px;
    padding: 16px 45px;
}

.btn.btn-sm {
    font-size: 12px;
    padding: 5px 15px;
}

.btn.btn-xs {
    font-size: 10px;
    padding: 2px 10px;
}

.btn-group>.btn:before,
.btn-group>.btn:after {
    border-left: 1px solid rgba(255, 255, 255, .2);
    bottom: 0;
    display: inline-block;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
}

.btn-group>.btn.btn-border:before,
.btn-group>.btn.btn-border:after {
    display: none;
}

.btn-group>.btn:after {
    left: auto;
    right: 0;
}

.btn-group>.btn:first-child:before,
.btn-group>.btn:last-child:after,
.btn-group>.btn:only-of-type:after,
.btn-group>.btn:only-of-type:before {
    display: none;
}

.ladda-button {
    font-size: 100%;
}

.ladda-button[data-style=contract][data-loading] {
    width: 42px;
}


/* Tables */

.table {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 22px;
    text-align: center;
}

th {
    text-align: center;
}

.table.text-left,
.table.text-left th:not(.text-center) {
    text-align: left;
}

.table.text-right,
.table.text-right th:not(.text-center) {
    text-align: right;
}

.table th,
.table td,
.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    border-top: 1px solid #e1e1e1;
    line-height: 18px;
    padding: 16px 10px 15px;
}

.table>thead>tr>th {
    border-bottom: 1px solid #e1e1e1;
    font-size: 13px;
}

.table-striped thead th {
    background-color: #f7f7f7;
}

.table-bordered {
    border-radius: 0;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom-width: 1px;
}

.table-hover tbody tr>td,
.table-hover tbody tr>th {
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.table-hover tbody tr:hover>td,
.table-hover tbody tr:hover>th {
    background-color: whitedee;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
    padding: 4px 5px;
}

.table>thead>tr>td.warning,
.table>tbody>tr>td.warning,
.table>tfoot>tr>td.warning,
.table>thead>tr>th.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>tbody>tr.warning>td,
.table>tfoot>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr.warning>th,
.table>tfoot>tr.warning>th {
    background-color: #f89406;
    color: white;
}

.table>thead>tr>td.success,
.table>tbody>tr>td.success,
.table>tfoot>tr>td.success,
.table>thead>tr>th.success,
.table>tbody>tr>th.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>tbody>tr.success>td,
.table>tfoot>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr.success>th,
.table>tfoot>tr.success>th {
    background-color: #738d00;
    color: white;
}

.table>thead>tr>td.danger,
.table>tbody>tr>td.danger,
.table>tfoot>tr>td.danger,
.table>thead>tr>th.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>tbody>tr.danger>td,
.table>tfoot>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr.danger>th,
.table>tfoot>tr.danger>th {
    background-color: #c10841;
    color: white;
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
    background-color: #0098ca;
    color: white;
}

@media only screen and (max-width: 767px) {
    .page-header .title {
        font-size: 3rem;
    }
    h1.title {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 1rem;
    }
    #block-md-slider-homepage {
        display: none;
    }
}

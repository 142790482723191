    @media only screen and (min-width: 768px) {}
    
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .md-objects .md-object,
        .md-objects .md-object a,
        .slider-item>div {
            font-size: 180%;
            line-height: 20px;
        }
        .md-objects .md-object a.btn {
            padding: 5px;
            margin-top: -20px;
        }
    }

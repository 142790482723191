@import '_variables.scss';

/* WEB TV */

.page-node-134 .spacer {
    width: 100%;
    overflow: hidden;
    display: block;
    border-top: 1px solid #ddd;
    padding-bottom: 30px;
    margin: 70px auto 30px auto;
    width: 25%;
}
.page-node-134 .title-box {
    margin-bottom: 5px;
}

/* Fin WEB TV */

/*Evernements precedents*/

.page-node-136 .field-name-body ul {
    padding: 0 15px;
    font-size: 1.5rem;
}

/*Fin Evernements precedents*/

.btn.btn-danger, .btn.btn-danger:visited, .btn.btn-danger:focus, .btn.btn-danger:active, .btn.btn-danger.active, .btn.btn-danger.disabled, .btn.btn-danger[disabled] {
    background: #c10841 !important;
    color: white !important;
}
.btn.btn-danger:hover {
    background: #ffffff !important;
    color: #c10841 !important;
    border-top: 1px solid #c10841;
    border-bottom: 1px solid #c10841;
}

/*Contact*/

.page-node-50 .block-webform {
  background: #f2f2f2;
  border-radius: 5px;
  margin: 0 0 40px 0;
  padding: 27px 30px 25px;
}

/**********************************
Actualités accueil
**********************************/
.view-display-id-headline_actualites_accueil {
  margin-top: 10px;
}

.view-display-id-actualites_accueil {
  background: #623764 url(../img/actualites-accueil-fond.jpg) no-repeat;
  background-size: cover;
  padding: 1% 0;
  font-size : 18px;
  .actus-preview, .actus-headline  {
    background: #7E4A94;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    padding-top: 33.33%;
    z-index:2;
    @media only screen and (min-width: 767px)  {
      padding-top: 31%;
      }
    @media only screen and (min-width: 992px)  {
      padding-top: 31.66%;
      }
    @media only screen and (min-width: 1200px)  {
      padding-top: 32.2%;
      }
    .img-wrapper {
        img {
          position: absolute;
          width: 100%;
          top:0;
          left:0;
        }
      }
    .overlay {
      background: none;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      &:hover {
        background: rgba(0,0,0,.7);
        .title {
          display:block;
        }
      }
    }
      .title {
        display:none;
        position: absolute;
        z-index: 2;
        text-align: center;
        color: #fff;
        &:hover {
          display:block;
        }
      }
    }
  .actus-headline  {
    padding-top: 61.9%;
    justify-content: flex-end;
      @media only screen and (max-width: 767px)  {
        padding-bottom: 4%;
      }
      .img-wrapper {
        img {
          padding-right: 20px;
          padding-bottom: 20px;
          margin-left: 10px;
        }
    }
    .title-wrapper {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      height: 20%;
      width: calc(100% - 20px);
      bottom:9px;
      background: none;
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
       @media only screen and (min-width: 768px)  {
         bottom: -3px;
     }
       @media only screen and (min-width: 992px)  {
         bottom: -7px;
     }
       @media only screen and (min-width: 1200px)  {
         bottom: -13px;
     }
      .title {
        bottom: 9%;
        padding-left: 2%;
      }
    }
  }
  .actus-preview  {
    justify-content: center;
    text-align: center;
    img {
      height: 100%;
    }
    .title {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      max-width: 80%;
      margin: auto;
    }
    &:nth-child(1), &:nth-child(4)  {
      background-color: #87BA13;
    }
    &:nth-child(2), &:nth-child(3)  {
      background-color: #c10841;;
    }
    @media only screen and (max-width: 767px)  {
      padding-right: 0;
      padding-left: 0;
   }
    @media only screen and (min-width: 767px)  {
      .overlay {
        margin-left: -15px;
      }
    }
  }
  @media only screen and (max-width: 767px)  {
    .actus-preview-wrapper {
      padding: 0;
    }
 }
}

/**********************************
Accueil
**********************************/

.front {
  .title-video {
    border-bottom-width: 0;
    .title {
      font-weight: bold;
    }
    &::before {
      width: 0;
    }
  }
  .manufactures .make {
    background: transparent;
  }
}

/**********************************
Evenements précédents
**********************************/
.evenement-precedent {
	height:190px;
}
.evenement-precedent h3 {
	margin:0;
	padding:0 0 12px 0;
	color:#7d4a92;
}

.evenement-precedent p {
    margin:0 0 12px 0;
	font-size:12px;
}

.evenement-precedent img.media-element {
	border: 1px solid #dcdcdc;
	margin-right:10px;
}

.evenement-precedent a, .evenement-precedent a:visited{
    color: #d0253e;
	text-decoration:none;
}

.evenement-precedent a:hover{
    color: #d0253e;
	text-decoration:underline;
}

.evenement-precedent .file-icon {
	display: none;
}

.evenement-precedent .file::before {
	font-family: FontAwesome; content: "\f1c1";
}

/**********************************
Footer
**********************************/

.footer-top {
  ul {
    padding: 0 0 0 20px;
  }
}

/**********************************
Page maladies
**********************************/

.page-node-164 {
  .content {
    h2 {
      margin: 0 0 8px;
    }
    .caption {
      text-align: center;
      margin: 15px auto;
      display: block;
      height: 314px;
      overflow-y: hidden;
      img {
        width: 100%;
      }
    }
    .btn.btn-primary, .btn.btn-primary:visited {
    background: #d0253e;
}
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary.active, .btn.btn-primary.disabled, .btn.btn-primary[disabled] {
    color: white;
    background-color: #d0253e;
}
  }
}

/**********************************
Agenda
**********************************/

.view-agenda {
  h2 {
    margin-bottom: 0;
  }
  .views-field-field-date {
    padding-left: 2rem;
    line-height : 2;
    position: relative;
    font-style: italic;
    color: #bbb;
    &::before {
      position: absolute;
      margin-left: -2rem;
      font-family: $font-icon;
      color: #d0253e;
      content: $fa-calendar;
      font-style: normal;
    }
  }
}

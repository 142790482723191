$gris-fonce: #4f6470;
$gris-clair: #7591a1;
$orange: #e79c2c;
$vert: #bbda3b;
$bleu-fonce: #1d60a1;
$bleu-clair: #2daae1;
$bleu-sombre: #0f3254;
$rose:#B13770;
$vert: #76B82A;
$vert-fonce: #609822;
$noir: #0f3254;

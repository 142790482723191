/*
Theme Name: Progressive
Theme URI: http://template.progressive.itembridge.com/
Author: InfoStyle
Author URI: http://themeforest.net/user/InfoStyle
Description: Progressive responsive theme
Version: 2.2.1
License: ThemeForest Regular & Extended License
License URI: http://themeforest.net/licenses/regular_extended
*/

@charset "utf-8";

/******************************************************************************
  General
*******************************************************************************/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

* {
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 0;
  zoom: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus {
    outline: none !important;
  }
}

html, body, .full-height {
  height: 100%;
}

body {
  font-family: 'Arimo', sans-serif;
  height: 100%;
  line-height: 1.6;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  &.loaded {
    overflow-x: inherit;
  }
}

.scroll-width {
  height: 150px;
  left: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  top: 0;
  width: 200px;
  div {
    height: 200px;
    width: 100%;
  }
}

input, button, select, textarea {
  font-family: 'Arimo', sans-serif;
}

.page-box {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.page-box-content {
  padding-bottom: 310px;
}

img {
  max-width: 100%;
  height: auto;
}

svg image {
  display: none;
}

/* Text */

a {
  color: #2e5481;
  &:visited {
    color: #7996b7;
  }
  &:active {
    color: #000;
  }
  &.no-border {
    color: #7f7f7f;
    text-decoration: none;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
}

.no-border a {
  color: #7f7f7f;
  text-decoration: none;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
}

a.no-border:hover, .no-border a:hover {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
}

.white {
  a.no-border, .no-border a {
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
  }
  a.no-border:hover, .no-border a:hover {
    color: #fff;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1.3;
  margin: 0 0 22px;
  text-rendering: optimizelegibility;
}

.light {
  font-weight: normal;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 22px;
  &:last-child {
    margin-bottom: 0;
  }
}

address {
  font-size: 13px;
}

.white, .icon.white {
  color: #fff;
}

.muted, .icon.muted {
  color: #555;
}

.text-warning {
  color: #f89406;
  &:visited {
    color: #f89406;
  }
}

a.text-warning:hover, .icon.text-warning {
  color: #f89406;
}

.text-error {
  color: #c10841;
  &:visited {
    color: #c10841;
  }
}

a.text-error:hover, .icon.text-error {
  color: #c10841;
}

.text-info {
  color: #0098ca;
  &:visited {
    color: #0098ca;
  }
}

a.text-info:hover, .icon.text-info {
  color: #0098ca;
}

.text-success {
  color: #738d00;
  &:visited {
    color: #738d00;
  }
}

a.text-success:hover, .icon.text-success {
  color: #738d00;
}

.bg-white {
  background: #fff !important;
}

.bg-muted {
  background: #555 !important;
}

.bg-primary {
  background: #428bca !important;
}

.bg-warning {
  background: #f89406 !important;
}

.bg-error, .bg-danger {
  background: #c10841 !important;
}

.bg-info {
  background: #0098ca !important;
}

.bg-success {
  background: #738d00 !important;
}

.border-muted {
  border-color: #555 !important;
}

.border-warning {
  border-color: #f89406 !important;
}

.border-error {
  border-color: #c10841 !important;
}

.border-info {
  border-color: #0098ca !important;
}

.border-success {
  border-color: #738d00 !important;
}

.border-grey {
  border-color: #777 !important;
}

hr {
  border: 0;
  border-top: 1px solid #e1e1e1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0 none;
  margin: 40px 0;
  &.dotted {
    border-style: dotted;
    border-top-width: 1px;
  }
  &.dashed {
    border-style: dashed;
    border-top-width: 1px;
  }
  &.double {
    border-style: double;
    border-top-width: 3px;
  }
  &.shadow {
    border: 0 none;
    margin-bottom: 30px;
    overflow: hidden;
    padding-top: 10px;
    position: relative;
    &:after {
      bottom: 10px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      content: "";
      height: 20px;
      left: 5%;
      position: absolute;
      right: 5%;
      z-index: -1;
    }
  }
  &.no-line {
    border: 0 none;
    height: 1px;
  }
  &.margin-bottom {
    margin-top: 0;
  }
  &.margin-top {
    margin-bottom: 0;
  }
}

ul, ol {
  padding: 0 0 0 60px;
  margin: 0 0 22px 0;
}

.text-small {
  font-size: 12px;
  line-height: 18px;
}

.lead {
  margin-bottom: 26px;
  font-size: 16px;
  line-height: 24px;
}

.label-success, .badge-success {
  background-color: #8aa902;
}

.label-primary, .badge-primary {
  background-color: #048ce2;
}

.label-info, .badge-info {
  background-color: #02a9e0;
}

.label-warning, .badge-warning {
  background-color: #f89406;
}

.label-danger, .badge-danger {
  background-color: #c10841;
}

.help-block {
  margin-top: -10px;
  margin-bottom: 15px;
}

.caret {
  color: #777;
  border: 0 none;
  height: 6px;
  width: 9px;
  &:before {
    content: "\f107";
    display: block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 6px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 6px;
    position: absolute;
    width: 9px;
  }
}

.open .caret {
  color: #777;
}

.btn .caret {
  margin: -2px 0 0;
}

.btn-group .caret {
  color: #fff;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border: 0 none;
}

.dropup .caret:before, .navbar-fixed-bottom .dropdown .caret:before {
  content: "\f106";
}

.btn.dropdown-toggle {
  outline: none;
  &:hover, &:focus {
    outline: none;
  }
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rating-box {
  background-image: url("../img/svg/rating.svg"), none;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 12px;
  width: 73px;
  .rating {
    height: 12px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 73px;
    * {
      vertical-align: top;
    }
  }
}

.prising-info .rating-box .rating svg polygon {
  fill: #01a5db;
}

.pricing-success .rating-box .rating svg polygon {
  fill: #84a200;
}

.pricing-error .rating-box .rating svg polygon {
  fill: #d40746;
}

.switches a {
  background: #d8dee3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  //display: block;
  //float: left;
  height: 10px;
  margin: 0 5px;
  text-indent: -9999px;
  vertical-align: top;
  width: 10px;
  &.selected {
    background-color: #41474c;
    background-image: -webkit-linear-gradient(bottom, #41474c, #6a7279);
    background-image: -moz-linear-gradient(bottom, #41474c, #6a7279);
    background-image: -o-linear-gradient(bottom, #41474c, #6a7279);
    background-image: -ms-linear-gradient(bottom, #41474c, #6a7279);
    background-image: linear-gradient(to top, #41474c, #6a7279);
  }
  span {
    display: none;
  }
}

.close {
  float: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  color: #7f7f7f;
  position: relative;
  text-shadow: none;
  opacity: 1;
  filter: alpha(opacity = 100);
  vertical-align: top;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
  &:visited {
    float: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    color: #7f7f7f;
    position: relative;
    text-shadow: none;
    opacity: 1;
    filter: alpha(opacity = 100);
    vertical-align: top;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  &:hover, &:focus {
    color: #1e1e1e;
    opacity: 1;
    filter: alpha(opacity = 100);
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  span {
    display: inline-block;
    font-size: 12px;
    font-family: Arial;
    line-height: 12px;
    margin-top: 2px;
    vertical-align: top;
  }
}

.product-remove {
  color: #7f7f7f;
  height: 16px;
  text-decoration: none;
  width: 16px;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
  &:visited {
    color: #7f7f7f;
    height: 16px;
    text-decoration: none;
    width: 16px;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  &:hover {
    color: #1e1e1e;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  path {
    fill: currentColor;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }
  i {
    font-size: 16px;
  }
}

a.edit {
  color: #707070;
  font-size: 12px;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:hover {
    color: #707070;
    opacity: 0.8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  i:before {
    font-style: italic;
    line-height: 9px;
    margin: 0;
  }
}

.map-box {
  iframe {
    border: 0 none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
  }
  img {
    max-width: inherit;
  }
}

.map-canvas {
  height: 400px;
  * {
    border: 0 none;
  }
}

.map-content {
  width: 200px;
  .title {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

/* Alert */

.alert {
  background-color: #555;
  border: 0 none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #fff;
  min-height: 34px;
  margin-bottom: 22px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  text-shadow: none;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear;
}

.alert-black {
  background-color: #1e1e1e;
}

.alert-success {
  background-color: #738d00;
}

.alert-danger {
  background-color: #c10841;
}

.alert-info {
  background-color: #0098ca;
}

.alert-warning {
  background-color: #f89406;
}

.alert {
  .alert-link {
    color: #fff;
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      color: #fff;
    }
  }
  .close {
    color: #fff;
    line-height: 22px;
    position: absolute;
    right: 10px;
    top: 9px;
  }
  .alert-icon {
    display: block;
    float: left;
    margin: 0 20px 0 0;
    &:before {
      font-size: 34px;
    }
  }
  &.alert-border {
    background: none;
    border: 1px solid #e1e1e1;
    color: #1e1e1e;
    .close {
      color: #1e1e1e;
    }
    &.alert-black {
      background: none;
      border: 1px solid #1e1e1e;
    }
    &.alert-success {
      background: none;
      border: 1px solid #738d00;
    }
    &.alert-danger {
      background: none;
      border: 1px solid #c10841;
    }
    &.alert-warning {
      background: none;
      border: 1px solid #f89406;
    }
    &.alert-info {
      background: none;
      border: 1px solid #0098ca;
    }
  }
}

/* Tooltip */

.tooltip.left {
  margin-left: -12px;
}

#tooltip {
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  padding: 5px;
  position: absolute;
  z-index: 3000;
  h3, div {
    margin: 0;
  }
}

.text-warning + .tooltip {
  .tooltip-inner {
    background-color: #c09853;
  }
  &.top .tooltip-arrow {
    border-top-color: #c09853;
  }
  &.right .tooltip-arrow {
    border-right-color: #c09853;
  }
  &.left .tooltip-arrow {
    border-left-color: #c09853;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #c09853;
  }
}

.text-error + .tooltip {
  .tooltip-inner {
    background-color: #c10841;
  }
  &.top .tooltip-arrow {
    border-top-color: #c10841;
  }
  &.right .tooltip-arrow {
    border-right-color: #c10841;
  }
  &.left .tooltip-arrow {
    border-left-color: #c10841;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #c10841;
  }
}

.text-info + .tooltip {
  .tooltip-inner {
    background-color: #0098ca;
  }
  &.top .tooltip-arrow {
    border-top-color: #0098ca;
  }
  &.right .tooltip-arrow {
    border-right-color: #0098ca;
  }
  &.left .tooltip-arrow {
    border-left-color: #0098ca;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #0098ca;
  }
}

.text-success + .tooltip {
  .tooltip-inner {
    background-color: #738d00;
  }
  &.top .tooltip-arrow {
    border-top-color: #738d00;
  }
  &.right .tooltip-arrow {
    border-right-color: #738d00;
  }
  &.left .tooltip-arrow {
    border-left-color: #738d00;
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: #738d00;
  }
}

/* Popover */

.popover {
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

/* List Group */

a.list-group-item {
  -webkit-transition: background-color .3s linear, color .3s linear;
  transition: background-color .3s linear, color .3s linear;
  &:hover, &:focus {
    -webkit-transition: background-color .3s linear, color .3s linear;
    transition: background-color .3s linear, color .3s linear;
  }
}

.list-group-item-success {
  background-color: #738d00;
  border: 0 none;
  color: #fff;
}

.list-group-item-info {
  background-color: #0098ca;
  border: 0 none;
  color: #fff;
}

.list-group-item-warning {
  background-color: #f89406;
  border: 0 none;
  color: #fff;
}

.list-group-item-danger {
  background-color: #c10841;
  border: 0 none;
  color: #fff;
}

a {
  &.list-group-item-success, &.list-group-item-info, &.list-group-item-warning, &.list-group-item-danger {
    color: #fff;
  }
  &.list-group-item-success {
    &:hover, &:focus {
      background-color: #819819;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &.list-group-item-info {
    &:hover, &:focus {
      background-color: #19A2CF;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &.list-group-item-warning {
    &:hover, &:focus {
      background-color: #F99F1F;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &.list-group-item-danger {
    &:hover, &:focus {
      background-color: #C72154;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.list-group-item .badge {
  margin-top: 2px;
}

/* Forms */

form {
  margin-bottom: 22px;
}

label {
  font-weight: bold;
  margin: 0 0 3px;
}

.required {
  color: #c3293a;
  display: inline-block;
  font-size: 14px;
}

.selectBox, .form-control {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 22px;
  min-height: 40px;
  padding: 8px 12px;
  /*-webkit-appearance: none;*/
  width: 100%;
}

.selectBox:hover, .form-control:hover {
  border-color: #ccc;
  text-decoration: none;
}

.selectBox.selectBox-menuShowing, .form-control:focus {
  -webkit-box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  -moz-box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  border: solid 1px #839bb7;
  outline: none;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
}

textarea.form-control {
  height: 100px;
  max-width: 100%;
}

.selectBox {
  max-width: 100%;
  width: 100% !important;
}

.radio, .checkbox {
  font-weight: normal;
  margin: 0 0 8px;
  min-height: 18px;
  padding-left: 20px;
}

.new-radio, .new-checkbox {
  background: #fff;
  border: 1px solid #ccc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  float: left;
  height: 15px;
  margin: 2px 0 0 -20px;
  position: absolute;
  width: 15px;
}

.new-radio {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  height: 14px;
  width: 14px;
  input[type="radio"] {
    cursor: pointer;
    height: 100%;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity = 0);
    position: absolute;
    vertical-align: top;
    width: 100%;
  }
}

.new-checkbox {
  input[type="checkbox"] {
    cursor: pointer;
    height: 100%;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity = 0);
    position: absolute;
    vertical-align: top;
    width: 100%;
  }
  svg {
    display: none;
    height: 18px;
    margin: -3px 0 0 -2px;
    width: 18px;
    polygon {
      fill: #1e1e1e;
    }
  }
  &.checked svg {
    display: block;
  }
}

.new-radio {
  span {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    height: 6px;
    margin: 3px 0 0 3px;
    width: 6px;
  }
  &.checked span {
    background: #1e1e1e;
  }
  &.disabled {
    opacity: .5;
    filter: alpha(opacity = 50);
  }
}

.new-checkbox.disabled {
  opacity: .5;
  filter: alpha(opacity = 50);
}

.form-search {
  .radio, .checkbox {
    padding-left: 20px;
  }
}

.form-inline {
  .radio, .checkbox {
    padding-left: 20px;
  }
}

.form-search {
  .radio input[type="radio"], .checkbox input[type="checkbox"] {
    margin-left: -20px;
  }
}

.form-inline {
  .radio input[type="radio"], .checkbox input[type="checkbox"] {
    margin-left: -20px;
  }
}

.selectBox::-webkit-scrollbar, .selectBox-dropdown-menu::-webkit-scrollbar {
  background: #f2f2f2;
  width: 10px;
}

.selectBox::-webkit-scrollbar-thumb, .selectBox-dropdown-menu::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  background-color: #839bb7;
  width: 5px;
}

.selectBox {
  background: #fff;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  &:hover, &:focus {
    outline: none;
    text-decoration: none;
  }
}

button {
  outline: none;
  text-decoration: none;
}

.selectBox-label {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #555;
  display: block;
  max-width: 100%;
  padding: 0 25px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.selectBox-arrow {
  background: url("../img/svg/select.svg") 50% 50% no-repeat;
  border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -khtml-border-radius: 0 3px 3px 0;
  height: 100%;
  left: auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}

.selectBox-dropdown-menu {
  background: #fff;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  -moz-box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  border: solid 1px #839bb7;
  outline: none;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
  color: #5f5f5f;
  font-size: 12px;
  list-style: none;
  margin: 0;
  max-height: 117px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

.selectBox-options li {
  display: block;
  a {
    color: #1e1e1e;
    cursor: pointer;
    display: block;
    line-height: 1.5;
    padding: 2px 2px 2px 16px;
    margin: 0;
    min-height: 23px;
    text-decoration: none;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    &:hover {
      background: #f2f2f2;
      color: #111;
      text-decoration: none;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
  }
  + li a {
    border-top: 1px solid #839bb7;
  }
  &.selectBox-selected a {
    background: #e9e9e9;
    color: #000;
    text-decoration: none;
  }
}

.selectBox-inline {
  overflow-y: auto;
  padding: 0;
  width: 100%;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

fieldset[disabled] {
  .radio label, .checkbox label {
    cursor: default;
  }
  select {
    color: #999;
  }
  .selectBox {
    color: #999;
    .selectBox-label {
      color: #999;
    }
  }
}

.selectBox.selectBox-disabled {
  color: #999;
  .selectBox-label {
    color: #999;
  }
}

fieldset[disabled] .selectBox .selectBox-arrow {
  opacity: .3;
  filter: alpha(opacity = 30);
}

.selectBox.selectBox-disabled {
  .selectBox-arrow {
    opacity: .3;
    filter: alpha(opacity = 30);
  }
  &:focus {
    border-color: #e1e1e1;
    -webkit-box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
  }
}

.form-inline {
  .control-label, .form-control {
    margin-bottom: 0;
  }
}

.form-horizontal .form-group .form-control {
  margin-bottom: 0;
}

/* Form size */

.input-lg {
  border-radius: 4px;
  font-size: 18px;
  height: 46px;
  line-height: 1.33;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-sm {
  border-radius: 2px;
  font-size: 12px;
  height: 30px;
  line-height: 1.5;
  min-height: inherit !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Form color */

.has-feedback .form-control-feedback {
  height: 40px;
  line-height: 40px;
}

.has-success {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, .form-control-feedback {
    color: #738d00;
  }
}

.has-warning {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, .form-control-feedback {
    color: #f89406;
  }
}

.has-error {
  .help-block, .control-label, .radio, .checkbox, .radio-inline, .checkbox-inline, .form-control-feedback {
    color: #c10841;
  }
}

.has-success .form-control {
  border-color: #738d00;
}

.has-warning .form-control {
  border-color: #f89406;
}

.has-error .form-control {
  border-color: #c10841;
}

.has-success .form-control:focus {
  border-color: #738d00;
  -webkit-box-shadow: 0 0 10px rgba(115, 141, 0, 0.3);
  box-shadow: 0 0 10px rgba(115, 141, 0, 0.3);
  -webkit-transition: box-shadow linear .2s;
  transition: box-shadow linear .2s;
}

.has-warning .form-control:focus {
  border-color: #f89406;
  -webkit-box-shadow: 0 0 10px rgba(248, 148, 6, 0.3);
  box-shadow: 0 0 10px rgba(248, 148, 6, 0.3);
}

.has-error .form-control:focus {
  border-color: #c10841;
  -webkit-box-shadow: 0 0 10px rgba(193, 8, 65, 0.3);
  box-shadow: 0 0 10px rgba(193, 8, 65, 0.3);
}

/* IMG */

.alignnone {
  margin: 0 0 25px;
}

.aligncenter {
  clear: both;
  margin: 0 auto 25px;
  text-align: center;
}

.alignleft {
  float: left;
  margin: 0 25px 25px 0;
}

.alignright {
  float: right;
  margin: 0 0 25px 25px;
}

.caption {
  color: #7f7f7f;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 22px;
  max-width: 100%;
  img {
    display: block;
    margin: 0 auto 10px;
  }
}

.img-rounded {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.img-thumbnail {
  border-radius: 0;
  display: inline-block;
  line-height: 0;
  position: relative;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  &:hover {
    opacity: .8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
  }
  &.lightbox:hover {
    opacity: 1;
    filter: alpha(opacity = 100);
  }
  .bg-images {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  &:hover > .bg-images {
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  .bg-images i {
    margin: 5px 0 0 5px;
    &:before {
      color: #fff;
      font-size: 20px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
    }
  }
  &:hover .bg-images i:before {
    color: #000;
    font-size: 30px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  &.img-rounded {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    img, .bg-images {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }
  &.img-circle {
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    border-radius: 500px;
    img {
      -webkit-border-radius: 500px;
      -moz-border-radius: 500px;
      border-radius: 500px;
    }
    .bg-images {
      -webkit-border-radius: 500px;
      -moz-border-radius: 500px;
      border-radius: 500px;
      i {
        margin: 17% 0 0 17%;
      }
    }
  }
  .bg-images i {
    margin: 9px 0 0 9px;
  }
}

.fancybox-wrap {
  -webkit-overflow-scrolling: touch;
}

/* Video */

embed, iframe, object, video, audio {
  max-width: 100%;
}

.video-box {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  height: 0;
  margin-bottom: 22px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  embed, iframe, object, video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &.html5 {
    height: auto;
    padding: 0;
    .video-js {
      height: auto !important;
      line-height: 0;
      width: 100% !important;
      .vjs-tech {
        height: auto;
        margin: 0;
        position: relative;
        width: 100%;
      }
    }
    .vjs-default-skin {
      color: #fff;
      .vjs-play-progress, .vjs-volume-level {
        background-color: #555;
      }
      .vjs-control-bar, .vjs-big-play-button, .vjs-slider {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

/* Audio */

.audio-box audio {
  width: 100%;
}

/* Nav Bar */

.navbar-inverse {
  .brand, .nav > li > a {
    color: #7b7b7b;
    text-shadow: none;
  }
}

/* Drop Down */

.dropdown-backdrop {
  position: static;
}

.btn-group {
  .dropdown-toggle, &.open .dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.dropdown-menu {
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  min-width: 156px;
  padding: 0;
  z-index: 800;
}

.open > .dropdown-menu {
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;
}

/* Button */

.btn {
  background: #1e1e1e;
  border: 0 none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
  line-height: 22px;
  outline: none;
  padding: 9px 20px;
  text-shadow: none;
  filter: none;
  -webkit-transition: background .2s linear, color .2s linear;
  transition: background .2s linear, color .2s linear;
  white-space: normal;
  &:visited {
    background: #1e1e1e;
    border: 0 none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #fff;
    line-height: 22px;
    outline: none;
    padding: 9px 20px;
    text-shadow: none;
    filter: none;
    -webkit-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
    white-space: normal;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    background: #565656;
    *background: #565656;
    color: #fff;
    outline: none;
    -webkit-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
  }
}

.btn-default {
  &:hover, &:focus, &:active, &.active {
    background: #565656;
    *background: #565656;
    color: #fff;
    outline: none;
    -webkit-transition: background .2s linear, color .2s linear;
    transition: background .2s linear, color .2s linear;
  }
}

.open .dropdown-toggle.btn-default {
  background: #565656;
  *background: #565656;
  color: #fff;
  outline: none;
  -webkit-transition: background .2s linear, color .2s linear;
  transition: background .2s linear, color .2s linear;
}

.btn {
  &:active, &.active {
    background: #1e1e1e;
    background-color: rgba(30, 30, 30, 0.75);
    -webkit-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
    outline: none;
  }
}

.btn-group.open .btn.dropdown-toggle {
  background: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.75);
  -webkit-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
  outline: none;
  background: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.75);
  -webkit-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
}

.btn.btn-inverse {
  background: #ccc;
  background: rgba(30, 30, 30, 0.3);
  &:visited {
    background: #ccc;
    background: rgba(30, 30, 30, 0.3);
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #efefef;
    background-color: #000;
  }
  &:active, &.active {
    background-color: #ccc;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-inverse {
  background-color: #ccc;
}

.btn.btn-primary {
  background: #037ac5;
  &:visited {
    background: #037ac5;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #048ce2;
  }
  &:active, &.active {
    background-color: #048ce2;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-primary {
  background-color: #048ce2;
}

.btn.btn-warning {
  background: #f89406;
  &:visited {
    background: #f89406;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #fda425;
  }
  &:active, &.active {
    background-color: #fda425;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-warning {
  background-color: #fda425;
}

.btn.btn-danger {
  background: #c10841;
  &:visited {
    background: #c10841;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #d70a49;
  }
  &:active, &.active {
    background-color: #d70a49;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-danger {
  background-color: #d70a49;
}

.btn.btn-success {
  background: #738d00;
  &:visited {
    background: #738d00;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #8aa902;
  }
  &:active, &.active {
    background-color: #8aa902;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-success {
  background-color: #8aa902;
}

.btn.btn-info {
  background: #0098ca;
  &:visited {
    background: #0098ca;
  }
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #02a9e0;
  }
  &:active, &.active {
    background-color: #02a9e0;
  }
}

.btn-group.open .btn.dropdown-toggle.btn-info {
  background-color: #02a9e0;
}

.btn {
  &.btn-link {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &:active, &[disabled] {
      background: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    cursor: pointer;
    color: #2e5481;
    &:visited {
      cursor: pointer;
      color: #2e5481;
    }
    &:hover, &:focus {
      background: none;
      color: #2e5481;
      text-decoration: underline;
    }
    &[disabled] {
      &:hover, &:focus {
        opacity: .8;
        filter: alpha(opacity = 80);
      }
    }
  }
  &.btn-red {
    background: #ed093a;
    &:visited {
      background: #ed093a;
    }
    &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
      color: #ffffff;
      background-color: #fb4c71;
    }
  }
  &.btn-white {
    background: #fff;
    color: #1e1e1e;
    &:visited {
      background: #fff;
      color: #1e1e1e;
    }
    &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
      color: #1e1e1e;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.btn-group.open .btn.dropdown-toggle.btn-white {
  color: #1e1e1e;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}

.btn {
  &.disabled, &[disabled] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  &.btn-border {
    background: none;
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
    padding-top: 9px;
    padding-bottom: 9px;
    .caret {
      color: #1e1e1e;
    }
    &.btn-inverse {
      border-color: rgba(30, 30, 30, 0.3);
      color: rgba(30, 30, 30, 0.3);
      .caret {
        border-color: rgba(30, 30, 30, 0.3);
        color: rgba(30, 30, 30, 0.3);
      }
    }
    &.btn-primary {
      border-color: #037ac5;
      color: #037ac5;
      .caret {
        border-color: #037ac5;
        color: #037ac5;
      }
    }
    &.btn-warning {
      border-color: #f89406;
      color: #f89406;
      .caret {
        border-color: #f89406;
        color: #f89406;
      }
    }
    &.btn-danger {
      border-color: #c10841;
      color: #c10841;
      .caret {
        border-color: #c10841;
        color: #c10841;
      }
    }
    &.btn-success {
      border-color: #738d00;
      color: #738d00;
      .caret {
        border-color: #738d00;
        color: #738d00;
      }
    }
    &.btn-info {
      border-color: #0098ca;
      color: #0098ca;
      .caret {
        border-color: #0098ca;
        color: #0098ca;
      }
    }
    &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
      color: #fff;
    }
  }
}

.btn-group.open .btn.dropdown-toggle.btn-border {
  color: #fff;
}

.btn.btn-border {
  &:hover .caret, &:focus .caret, &:active .caret, &.active .caret, &.disabled .caret, &[disabled] .caret {
    color: #fff;
  }
}

.btn-group {
  &.open .btn.dropdown-toggle.btn-border .caret {
    color: #fff;
  }
  > {
    .btn {
      &:first-child {
        -webkit-border-top-left-radius: 3px;
        -moz-border-radius-topleft: 3px;
        border-top-left-radius: 3px;
        -webkit-border-bottom-left-radius: 3px;
        -moz-border-radius-bottomleft: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        -webkit-border-top-right-radius: 3px;
        -moz-border-radius-topright: 3px;
        border-top-right-radius: 3px;
        -webkit-border-bottom-right-radius: 3px;
        -moz-border-radius-bottomright: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    .dropdown-toggle {
      -webkit-border-top-right-radius: 3px;
      -moz-border-radius-topright: 3px;
      border-top-right-radius: 3px;
      -webkit-border-bottom-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.btn {
  &.btn-lg {
    font-size: 14px;
    padding: 16px 45px;
  }
  &.btn-sm {
    font-size: 12px;
    padding: 5px 15px;
  }
  &.btn-xs {
    font-size: 10px;
    padding: 2px 10px;
  }
}

.btn-group > .btn {
  &:before, &:after {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    bottom: 0;
    display: inline-block;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
  }
  &.btn-border {
    &:before, &:after {
      display: none;
    }
  }
  &:after {
    left: auto;
    right: 0;
  }
  &:first-child:before, &:last-child:after {
    display: none;
  }
  &:only-of-type {
    &:after, &:before {
      display: none;
    }
  }
}

.ladda-button {
  font-size: 100%;
  &[data-style=contract][data-loading] {
    width: 42px;
  }
}

/* Tables */

.table {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 22px;
  text-align: center;
}

th {
  text-align: center;
}

.table {
  &.text-left {
    text-align: left;
    th:not(.text-center) {
      text-align: left;
    }
  }
  &.text-right {
    text-align: right;
    th:not(.text-center) {
      text-align: right;
    }
  }
  th, td {
    border-top: 1px solid #e1e1e1;
    line-height: 18px;
    padding: 16px 10px 15px;
  }
  > {
    thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
      border-top: 1px solid #e1e1e1;
      line-height: 18px;
      padding: 16px 10px 15px;
    }
    thead > tr > th {
      border-bottom: 1px solid #e1e1e1;
      font-size: 13px;
    }
  }
}

.table-striped thead th {
  background-color: #f7f7f7;
}

.table-bordered {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  > thead > tr > {
    th, td {
      border-bottom-width: 1px;
    }
  }
}

.table-hover tbody tr {
  > {
    td, th {
      -webkit-transition: all 0.4s linear;
      transition: all 0.4s linear;
    }
  }
  &:hover > {
    td, th {
      background-color: #fffdee;
      -webkit-transition: all 0.4s linear;
      transition: all 0.4s linear;
    }
  }
}

.table-condensed > {
  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    padding: 4px 5px;
  }
}

.table > {
  thead > tr > td.warning, tbody > tr > td.warning, tfoot > tr > td.warning, thead > tr > th.warning, tbody > tr > th.warning, tfoot > tr > th.warning, thead > tr.warning > td, tbody > tr.warning > td, tfoot > tr.warning > td, thead > tr.warning > th, tbody > tr.warning > th, tfoot > tr.warning > th {
    background-color: #f89406;
    color: #fff;
  }
  thead > tr > td.success, tbody > tr > td.success, tfoot > tr > td.success, thead > tr > th.success, tbody > tr > th.success, tfoot > tr > th.success, thead > tr.success > td, tbody > tr.success > td, tfoot > tr.success > td, thead > tr.success > th, tbody > tr.success > th, tfoot > tr.success > th {
    background-color: #738d00;
    color: #fff;
  }
  thead > tr > td.danger, tbody > tr > td.danger, tfoot > tr > td.danger, thead > tr > th.danger, tbody > tr > th.danger, tfoot > tr > th.danger, thead > tr.danger > td, tbody > tr.danger > td, tfoot > tr.danger > td, thead > tr.danger > th, tbody > tr.danger > th, tfoot > tr.danger > th {
    background-color: #c10841;
    color: #fff;
  }
  thead > tr > td.info, tbody > tr > td.info, tfoot > tr > td.info, thead > tr > th.info, tbody > tr > th.info, tfoot > tr > th.info, thead > tr.info > td, tbody > tr.info > td, tfoot > tr.info > td, thead > tr.info > th, tbody > tr.info > th, tfoot > tr.info > th {
    background-color: #0098ca;
    color: #fff;
  }
}

/* Quotes */

blockquote {
  padding: 0 0 0 20px;
  margin: 0 0 22px;
  border-left: 2px solid #e1e1e1;
  &.pull-right {
    border-left: 0;
    border-right: 2px solid #e1e1e1;
    float: right;
    padding-left: 0;
    padding-right: 20px;
  }
  p {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 16px;
    line-height: 1.625;
    margin-bottom: 0;
  }
  small {
    line-height: 26px;
  }
}

/* Tabs */

.nav-tabs {
  margin-bottom: 0;
  margin-left: 0;
  > li > a {
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
    color: #1e1e1e;
    font-size: 12px;
    padding: 10px 20px 11px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
  }
}

.nav > li > a {
  &:hover, &:focus {
    background-color: #f7f7f7;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
  }
}

.nav-tabs > {
  li > a {
    &:hover, &:focus {
      border-color: #f7f7f7 #f7f7f7 #e1e1e1;
    }
  }
  .active {
    > a {
      border: 1px solid #e1e1e1;
      border-bottom-color: transparent;
      color: #1e1e1e;
      font-weight: bold;
      &:hover, &:focus {
        border: 1px solid #e1e1e1;
        border-bottom-color: transparent;
        color: #1e1e1e;
        font-weight: bold;
      }
    }
    &.dropdown > a {
      z-index: 911;
    }
  }
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  padding: 36px 0 0;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
  float: none;
  margin: 0;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
  min-width: 110px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > {
  .nav-tabs {
    border: 0 none;
    border-right: 1px solid #ddd;
    float: left;
    margin: 0 -1px 0 0;
    > li > a {
      margin-right: -1px;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
      border-radius: 4px 0 0 4px;
      &:hover, &:focus {
        border-color: #f7f7f7 #ddd #f7f7f7 #f7f7f7;
      }
    }
    .active > a {
      border-color: #ddd transparent #ddd #ddd;
      &:hover, &:focus {
        border-color: #ddd transparent #ddd #ddd;
      }
    }
  }
  .tab-content {
    border-left: 1px solid #ddd;
    margin: 0;
    overflow: auto;
    > .tab-pane {
      padding: 0 0 0 20px;
    }
  }
}

.tabs-right > {
  .nav-tabs {
    border: 0 none;
    border-left: 1px solid #ddd;
    float: right;
    margin: 0 0 0 -1px;
    > li > a {
      margin-left: -1px;
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 0 4px 4px 0;
      &:hover, &:focus {
        border-color: #f7f7f7 #f7f7f7 #f7f7f7 #ddd;
      }
    }
    .active > a {
      border-color: #ddd #ddd #ddd transparent;
      &:hover, &:focus {
        border-color: #ddd #ddd #ddd transparent;
      }
    }
  }
  .tab-content {
    border-right: 1px solid #ddd;
    margin: 0;
    overflow: auto;
    > .tab-pane {
      padding: 0 20px 0 0;
    }
  }
}

/* Accordion */

.panel {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 22px;
}

.panel-heading {
  padding: 9px 20px;
  border: 0 none;
  border-radius: 0;
}

.panel-footer {
  background-color: #e1e1e1;
  border-top: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.panel-title {
  font-size: 14px;
}

.panel-body {
  padding: 10px 20px;
}

.list-group-item {
  padding-left: 20px;
  padding-right: 20px;
}

.panel-primary {
  border-color: #037ac5;
}

.panel-success {
  border-color: #738d00;
}

.panel-info {
  border-color: #0098ca;
}

.panel-warning {
  border-color: #f89406;
}

.panel-danger {
  border-color: #c10841;
}

.panel-default > .panel-heading {
  background-color: #f2f2f2;
  color: #1e1e1e;
}

.panel-primary > .panel-heading {
  background-color: #037ac5;
  color: #fff;
}

.panel-success > .panel-heading {
  background-color: #738d00;
  color: #fff;
}

.panel-info > .panel-heading {
  background-color: #0098ca;
  color: #fff;
}

.panel-warning > .panel-heading {
  background-color: #f89406;
  color: #fff;
}

.panel-danger > .panel-heading {
  background-color: #c10841;
  color: #fff;
}

.panel-default {
  border-color: #e1e1e1;
  > .panel-heading {
    background-color: #e1e1e1;
  }
}

.panel-group {
  margin-bottom: 22px;
  .panel {
    background-color: inherit;
    border: 0 none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    .panel-heading {
      background-color: inherit;
      padding: 0;
      position: relative;
      > .panel-title:before {
        background: #e1e1e1;
        background: rgba(255, 255, 255, 0.4);
        bottom: 0;
        content: "";
        display: block;
        left: 45px;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: 1;
      }
    }
  }
  .active.panel > .panel-heading > .panel-title:before {
    background: #444;
    background: rgba(255, 255, 255, 0.2);
  }
  .panel .panel-heading > .panel-title > a {
    background-color: #f2f2f2;
    color: #1e1e1e;
    display: block;
    line-height: 22px;
    padding: 9px 9px 9px 60px;
    text-decoration: none;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    &:hover {
      background-color: #e9e9e9;
      -webkit-transition: background-color .2s linear;
      transition: background-color .2s linear;
    }
  }
  .active.panel > .panel-heading > .panel-title > a {
    background-color: #1e1e1e;
    color: #fff;
    display: block;
    padding: 9px 9px 9px 60px;
    text-decoration: none;
    &:hover {
      background-color: #1e1e1e;
      color: #fff;
      display: block;
      padding: 9px 9px 9px 60px;
      text-decoration: none;
    }
  }
  .panel .panel-heading > .panel-title > a {
    &:before {
      background: #1e1e1e;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      margin: 19px 0 0 15px;
      position: absolute;
      top: 0;
      width: 16px;
    }
    &:after {
      background: #1e1e1e;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      margin: 19px 0 0 15px;
      position: absolute;
      top: 0;
      width: 16px;
      height: 16px;
      margin: 12px 0 0 22px;
      width: 2px;
    }
  }
  .active.panel > .panel-heading > .panel-title > a {
    &:before {
      background: #fff;
    }
    &:after {
      background: #fff;
      display: none;
    }
  }
  .panel-heading + .panel-collapse .panel-body {
    border-top: 0 none;
  }
  .panel.e-hidden {
    margin: 0;
    overflow: hidden;
  }
  > {
    .panel-default > .panel-heading > .panel-title > a {
      background-color: #f2f2f2;
      &:hover {
        background-color: #e9e9e9;
      }
    }
    .panel-primary > .panel-heading > .panel-title > a {
      background-color: #037ac5;
      color: #fff;
      &:hover {
        background-color: rgba(3, 122, 197, 0.9);
        color: #fff;
      }
    }
    .panel-success > .panel-heading > .panel-title > a {
      background-color: #738d00;
      color: #fff;
      &:hover {
        background-color: rgba(115, 141, 0, 0.9);
      }
    }
    .panel-info > .panel-heading > .panel-title > a {
      background-color: #0098ca;
      color: #fff;
      &:hover {
        background-color: rgba(0, 152, 202, 0.9);
      }
    }
    .panel-warning > .panel-heading > .panel-title > a {
      background-color: #f89406;
      color: #fff;
      &:hover {
        background-color: rgba(248, 148, 6, 0.9);
      }
    }
    .panel-danger > .panel-heading > .panel-title > a {
      background-color: #c10841;
      color: #fff;
      &:hover {
        background-color: rgba(193, 8, 65, 0.9);
      }
    }
    .panel-primary > .panel-heading > .panel-title > a {
      &:before, &:after {
        background-color: #fff;
      }
    }
    .panel-success > .panel-heading > .panel-title > a {
      &:before, &:after {
        background-color: #fff;
      }
    }
    .panel-info > .panel-heading > .panel-title > a {
      &:before, &:after {
        background-color: #fff;
      }
    }
    .panel-warning > .panel-heading > .panel-title > a {
      &:before, &:after {
        background-color: #fff;
      }
    }
    .panel-danger > .panel-heading > .panel-title > a {
      &:before, &:after {
        background-color: #fff;
      }
    }
  }
  &.one-open .panel.active .panel-heading > .panel-title > a {
    cursor: default;
  }
  .panel-body {
    padding: 10px 0 10px 1px;
  }
}

.panel.panel-bg .panel-body {
  background: #f7f7f7;
  padding-left: 20px;
  padding-right: 20px;
}

/* Progress */

.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 40px;
}

.progress-bar {
  font-size: 14px;
  background: #1e1e1e;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  line-height: 40px;
  text-align: left;
  text-indent: 10px;
  text-shadow: none;
  -webkit-transition: background 0.2s linear;
  transition: background 0.2s linear;
}

.progress-bar-info {
  background-color: #0098ca;
}

.progress-bar-success {
  background-color: #738d00;
}

.progress-bar-warning {
  background-color: #f89406;
}

.progress-bar-danger {
  background-color: #c10841;
}

.progress {
  &.border-radius {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  &[data-appear-progress-animation] .progress-bar {
    text-indent: -100%;
  }
  &.hover {
    .progress-bar:hover {
      background: #1e1e1e;
    }
    .progress-bar-info:hover {
      background: #0098ca;
    }
    .progress-bar-success:hover {
      background: #738d00;
    }
    .progress-bar-warning:hover {
      background: #c09853;
    }
    .progress-bar-danger:hover {
      background: #c10841;
    }
  }
}

.progress-circular {
  position: relative;
  text-align: center;
  > div {
    display: inline-block;
    vertical-align: top;
  }
  input.knob {
    border: 0 none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #7f7f7f;
    font-size: 0;
    font-style: italic;
    font-weight: normal !important;
  }
}

/* Pagination */

.pagination {
  margin: 20px 0;
  > {
    li > {
      a, span {
        background-color: #ccc;
        border: 0 none;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        color: #fff;
        float: left;
        font-size: 12px;
        font-weight: bold;
        line-height: 24px;
        margin: 0 5px 0 0;
        min-width: 6px;
        padding: 0 9px;
        text-align: center;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      a:hover {
        background-color: #1e1e1e;
        color: #fff;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
    .active > {
      a {
        background-color: #1e1e1e;
        background-color: rgba(30, 30, 30, 0.7);
        color: #fff;
      }
      span {
        background-color: #1e1e1e;
        background-color: rgba(30, 30, 30, 0.7);
        color: #fff;
        &:hover {
          background-color: #1e1e1e;
          background-color: rgba(30, 30, 30, 0.7);
          color: #fff;
        }
      }
    }
    li {
      &:first-child > {
        a, span {
          border-left-width: 0;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          font-size: 15px;
          font-weight: normal;
        }
      }
      &:last-child > {
        a, span {
          border-left-width: 0;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          font-size: 15px;
          font-weight: normal;
        }
      }
    }
    .disabled > {
      span {
        background-color: #ccc;
        color: #fff;
      }
      a {
        background-color: #ccc;
        color: #fff;
        &:hover, &:focus {
          background-color: #ccc;
          color: #fff;
        }
      }
    }
  }
  + .pagination-text {
    color: #999;
    font-size: 12px;
    float: right;
    line-height: 24px;
    vertical-align: top;
  }
  &.pagination-lg > li > {
    a, span {
      font-size: 16px;
      line-height: 28px;
      padding: 4px 14px;
    }
  }
  &.pagination-sm > li > {
    a, span {
      font-size: 10px;
      line-height: 20px;
      padding: 0 7px;
    }
  }
}

.pagination-sm > li:first-child > {
  a, span {
    -webkit-border-top-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    border-top-left-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-bottom-left-radius: 2px;
  }
}

.pagination-lg > li:first-child > {
  a, span {
    -webkit-border-top-left-radius: 2px;
    -moz-border-radius-topleft: 2px;
    border-top-left-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-bottom-left-radius: 2px;
  }
}

.pagination-sm > li:last-child > {
  a, span {
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topright: 2px;
    border-top-right-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    border-bottom-right-radius: 2px;
  }
}

.pagination-lg > li:last-child > {
  a, span {
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topright: 2px;
    border-top-right-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    border-bottom-right-radius: 2px;
  }
}

/* Icons */

.icon {
  background: none;
  color: #1e1e1e;
  display: inline-block;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  margin: 0 0 4px 0;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  width: 32px;
  &.pull-left {
    float: left;
    margin-right: 10px;
  }
  &.pull-right {
    float: right;
    margin-left: 10px;
  }
  &.icon-24 {
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
  &.icon-40 {
    font-size: 26px;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  &.icon-60 {
    font-size: 32px;
    height: 60px;
    line-height: 60px;
    width: 60px;
  }
  &.icon-100 {
    font-size: 50px;
    height: 100px;
    line-height: 100px;
    width: 100px;
  }
  .fa {
    width: 100%;
  }
  &.rounded {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  &.circle {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  &.border {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 1px;
  }
}

.sbtnf {
  &:before, &:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

/* Frames */

.frame {
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  > img {
    width: 100%;
  }
}

.frame-padding {
  background: #fff;
  padding: 4px;
}

.frame-border {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.frame-shadow {
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

[class*="frame-shadow"] {
  background: #fff;
  position: relative;
}

.frame-shadow-lifted {
  &:before {
    bottom: 15px;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 20px;
    left: 10px;
    position: absolute;
    width: 50%;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
    z-index: -1;
  }
  &:after {
    bottom: 15px;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 20px;
    left: 10px;
    position: absolute;
    width: 50%;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);
    z-index: -1;
    right: 10px;
    left: auto;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

.frame-shadow-perspective:before {
  bottom: 5px;
  -webkit-box-shadow: (-80px) 0 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: (-80px) 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: (-80px) 0 8px rgba(0, 0, 0, 0.4);
  content: "";
  height: 35%;
  left: 80px;
  max-width: 200px;
  max-height: 50px;
  position: absolute;
  width: 50%;
  -webkit-transform: skew(50deg);
  -moz-transform: skew(50deg);
  -ms-transform: skew(50deg);
  -o-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  z-index: -1;
}

.frame-shadow-curved:before {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 10px/100px;
  -moz-border-radius: 10px/100px;
  border-radius: 10px/100px;
  bottom: 10px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: -1;
}

.frame-shadow-raised {
  -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.rotated-box {
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.rotated-right-box {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
}

/* Modal */

.modal {
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  &.modal-center .modal-dialog {
    transform: translate(0, 0%) !important;
    -ms-transform: translate(0, 0%) !important;
    -webkit-transform: translate(0, 0%) !important;
  }
  .close {
    font-size: 26px;
    position: absolute;
    right: 20px;
    top: 20px;
    span {
      line-height: 18px;
      margin-right: 5px;
      margin-top: 0;
      text-transform: uppercase;
    }
  }
}

.modal-header {
  border: 0 none;
  padding: 30px 20px 0;
  .title-box {
    margin-bottom: 20px;
    .title {
      margin: 0 0 12px 0;
      padding: 0;
    }
  }
}

.modal-body {
  padding: 0 20px 20px;
}

.modal-footer {
  background-color: #f7f7f7;
  border-top: 1px solid #e1e1e1;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  padding: 20px;
}

/* Product */

.product-name a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:hover {
    opacity: 0.8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.product-image {
  background: #f2f2f2;
  display: inline-block;
  line-height: 0;
  > img {
    width: 100%;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
  }
  &:hover > img {
    opacity: .9;
    filter: alpha(opacity = 90);
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
  }
}

.rotation .product-image:hover > img {
  opacity: 1;
  filter: alpha(opacity = 100);
}

.price-old {
  color: #7f7f7f;
  display: inline-block;
  margin-right: 6px;
  position: relative;
  &:before {
    background: #f00;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    margin: 0 0 0 -23px;
    position: absolute;
    top: 50%;
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    width: 46px;
  }
}

.sale {
  border-top: 56px solid #f89406;
  border-right: 56px solid transparent;
  display: block;
  height: 0;
  line-height: 22px;
  position: absolute;
  width: 0;
  z-index: 1;
  -webkit-transform: translateZ(0px);
  &:before {
    color: #fff;
    content: "Sale";
    display: block;
    font-size: 11px;
    font-weight: bold;
    margin: -46px 0 0 5px;
    position: absolute;
    text-transform: uppercase;
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }
  &.top {
    border-top: 56px solid #738d00;
    &:before {
      content: "Top";
    }
  }
  &.new {
    border-top: 56px solid #c10841;
    &:before {
      content: "New";
    }
  }
  &.best {
    border-top: 56px solid #0098ca;
    &:before {
      content: "Best";
    }
  }
}

/* Additional Classes */

.display-t {
  display: table;
}

.display-tc {
  display: table-cell;
}

.slim {
  font-weight: normal;
}

.border-radius {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.bg {
  background-color: #f2f2f2;
}

.border {
  border-width: 1px;
}

.width-auto {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
    width: auto;
  }
}

.margin-center {
  margin: 0 auto;
}

.bottom-padding {
  margin-bottom: 80px;
}

.bottom-padding-mini {
  margin-bottom: 40px;
}

.bottom-padding.last {
  margin-bottom: 0;
}

.no-top-padding {
  padding-top: 0 !important;
}

.col2-set {
  .col-1 {
    float: left;
    width: 48.5%;
  }
  .col-2 {
    float: left;
    width: 48.5%;
    float: right;
  }
}

/******************************************************************************
  Top
*******************************************************************************/

#top-box {
  background: #1e1e1e;
  min-height: 30px;
  padding: 5px 0;
  width: 100%;
}

/* Language/Currency */

.btn-group.btn-select {
  color: #7f7f7f;
  font-size: 12px;
  vertical-align: top;
}

#top-box .btn-group.btn-select {
  margin: 3px 0 0 0;
  &.language {
    margin-right: 6px;
  }
}

.btn-group.btn-select {
  .dropdown-toggle {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #7f7f7f;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 7px 3px;
    z-index: 2;
  }
  &.open .btn.dropdown-toggle {
    background: #fff;
    border: 1px solid #ccc;
    border-bottom: 0 none;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #7f7f7f;
  }
  .btn.dropdown-toggle .caret {
    color: #fff;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 0;
  }
  &.open .btn.dropdown-toggle {
    .caret {
      color: #777;
    }
    &:after {
      background: #fff;
      bottom: 0;
      content: "";
      display: block;
      height: 6px;
      left: 0px;
      margin: 0 0 -6px;
      position: absolute;
      right: 0;
      top: auto;
      z-index: 3;
      -webkit-animation: fadeIn 0.3s;
      animation: fadeIn 0.3s;
    }
  }
  .dropdown-menu {
    -webkit-border-radius: 0 3px 3px 3px;
    -moz-border-radius: 0 3px 3px 3px;
    border-radius: 0 3px 3px 3px;
    margin: 0;
    padding-top: 8px;
    z-index: 1;
    > li > a {
      font-size: 12px;
      line-height: 22px;
      padding: 4px 6px;
      &:hover {
        background: #f2f2f2;
      }
      img {
        margin: 5px 9px 0 0;
        vertical-align: top;
      }
    }
  }
}

.dropdown-menu > li > a i {
  font-size: 18px;
  margin-right: 10px;
  min-width: 18px;
  vertical-align: middle;
}

#top-box .btn-group.btn-select {
  .dropdown-toggle {
    z-index: 911;
  }
  .dropdown-menu {
    z-index: 910;
  }
  &.open .btn.dropdown-toggle:after {
    z-index: 912;
  }
}

/* Menu */

.top-navbar {
  background: none;
  border: 0 none;
  border-radius: 0;
  height: auto;
  margin: 0;
  min-height: 30px;
  .collapse {
    padding: 0;
  }
  .nav {
    margin-top: -5px;
    margin-bottom: -5px;
    > li {
      a {
        font-size: 12px;
        line-height: 22px;
        padding: 9px 15px 9px;
        -webkit-transition: background 0.2s linear, color 0.2s linear;
        transition: background 0.2s linear, color 0.2s linear;
        .fa {
          font-size: 120%;
          margin-right: 4px;
          &.after {
            margin-left: 4px;
            margin-right: 0;
          }
        }
        .count {
          background: rgba(255, 255, 255, 0.1);
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          display: inline-block;
          font-size: 110%;
          line-height: 1;
          margin-left: 4px;
          padding: 3px 5px;
          -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
        }
      }
      &:hover {
        a .count {
          background: rgba(0, 0, 0, 0.1);
          -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
        }
        > a {
          background: #efefef;
          color: #7b7b7b;
          -webkit-transition: background 0.2s linear, color 0.2s linear;
          transition: background 0.2s linear, color 0.2s linear;
        }
      }
      > a:hover {
        background: #efefef;
        color: #7b7b7b;
        -webkit-transition: background 0.2s linear, color 0.2s linear;
        transition: background 0.2s linear, color 0.2s linear;
      }
    }
  }
}

/* Social Icons */

.top-social {
  margin: 0;
  .icon-item {
    color: #fff;
    color: rgba(255, 255, 255, 0.3);
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-left: 1px;
    margin-right: 1px;
    position: relative;
    text-align: center;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
    vertical-align: top;
    width: 30px;
    i {
      bottom: 0;
      left: 0;
      line-height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      &:before {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
    }
    &:hover {
      color: #fff;
      -webkit-transition: color 0.2s linear;
      transition: color 0.2s linear;
      i:before {
        text-shadow: none;
      }
    }
  }
  &.bg-social .icon-item {
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    &:hover {
      opacity: 1;
      filter: alpha(opacity = 100);
    }
    i {
      -webkit-border-radius: 2px;
      border-radius: 2px;
      opacity: .7;
      filter: alpha(opacity = 70);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    &:hover i {
      opacity: 1;
      filter: alpha(opacity = 100);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    i:before {
      text-shadow: none;
    }
    .fa-facebook {
      background: #3b5998;
    }
    .fa-twitter {
      background: #00bdec;
    }
    .fa-google-plus {
      background: #d94a39;
    }
    .fa-linkedin {
      background: #1b92bd;
    }
  }
}

/* Search */

.top-search {
  margin: 0 0 0 auto;
  max-width: 300px;
  position: relative;
  .form-control {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #e1e1e1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #fff;
    height: 30px;
    line-height: 14px;
    margin: 0;
    min-height: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
    &:focus {
      border-color: rgba(255, 255, 255, 0.7);
    }
    &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
      color: #fff;
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .search-submit {
    background: none;
    height: 30px;
    opacity: .5;
    filter: alpha(opacity = 50);
    padding: 6px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    width: 30px;
    &:hover {
      opacity: .7;
      filter: alpha(opacity = 70);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
}

/* Info */

.top-info {
  color: #fff;
  font-size: 13px;
  padding: 5px 0;
  a {
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
    &:hover {
      color: #fff;
      text-decoration: none;
      -webkit-transition: color .2s linear;
      transition: color .2s linear;
    }
  }
  .separator {
    color: rgba(255, 255, 255, 0.4);
    vertical-align: top;
  }
}

/******************************************************************************
  Header
*******************************************************************************/

.header {
  width: 100%;
}

.header-wrapper {
  background: rgba(255, 255, 255, 0.97);
  min-height: 100px;
  padding: 2px 0;
}

.header {
  .container {
    position: relative;
  }
  .logo-box {
    display: table;
    table-layout: fixed;
  }
  .logo {
    display: table-cell;
    height: 100px;
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
    a {
      display: inline-block;
      max-width: 100%;
    }
    img, .logo-img {
      max-height: 100px;
    }
  }
  .logo-box.auto-height {
    display: block;
    height: 100px;
    .logo {
      display: block;
    }
    img {
      max-height: inherit;
    }
  }
  .site-description {
    color: #bbb;
    font-size: 9px;
    line-height: 1.3;
  }
}

.fixed-header {
  margin-top: 0;
  .page-box {
    padding-top: 104px;
  }
  .header {
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 870;
    -webkit-transition: margin-top 0.2s linear;
    transition: margin-top 0.2s linear;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    width: auto;
  }
  .header-wrapper {
    background: #fff;
    -webkit-transition: background 0.2s linear;
    transition: background 0.2s linear;
  }
  &.background-opacity .header-wrapper {
    background: rgba(255, 255, 255, 0.97);
    -webkit-transition: background 0.2s linear;
    transition: background 0.2s linear;
  }
  &.hidden-top.visible-top .header-wrapper, &.fixed .header-wrapper {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  &.hidden-top {
    .page-box {
      padding-top: 112px;
      -webkit-transition: padding-top 0.2s linear;
      transition: padding-top 0.2s linear;
    }
    &.fixed .page-box {
      padding-top: 104px;
      -webkit-transition: padding-top 0.2s linear;
      transition: padding-top 0.2s linear;
    }
    #top-box {
      left: 0;
      margin-top: -32px;
      position: fixed;
      top: 0;
      z-index: 910;
      -webkit-transition: margin-top 0.2s linear;
      transition: margin-top 0.2s linear;
    }
    .header {
      margin-top: 8px;
      top: 0;
    }
    &.visible-top {
      #top-box {
        margin-top: 0;
        -webkit-transition: margin-top 0.2s linear;
        transition: margin-top 0.2s linear;
      }
      .header {
        margin-top: 40px;
        -webkit-transition: margin-top 0.2s linear;
        transition: margin-top 0.2s linear;
      }
    }
    &.fixed #top-box, &.visible-top.fixed #top-box {
      margin-top: -40px;
      -webkit-transition: margin-top 0.2s linear;
      transition: margin-top 0.2s linear;
    }
    &.fixed .header, &.visible-top.fixed .header {
      margin-top: 0;
      -webkit-transition: margin-top 0.2s linear;
      transition: margin-top 0.2s linear;
    }
  }
}

.header {
  .right-box {
    display: table;
  }
  .right-box-wrapper {
    display: table-cell;
    height: 100px;
    vertical-align: middle;
  }
  .primary {
    float: left;
    margin-bottom: 10px;
    z-index: 900;
    .navbar {
      background: none;
      border: 0 none;
      margin: 0;
      min-height: 44px;
      .navbar-collapse {
        padding: 0;
        position: relative;
      }
      .nav {
        margin: 0;
        position: relative;
        > {
          li {
            padding: 12px 3px 0;
            position: relative;
            > a {
              background: none;
              border: 1px solid #fff;
              border: 1px solid transparent;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              color: #1e1e1e;
              padding: 11px 13px;
              position: static;
              text-shadow: none;
              -webkit-transition: all 0.2s linear;
              transition: all 0.2s linear;
              z-index: 901;
              &:visited {
                background: none;
                border: 1px solid #fff;
                border: 1px solid transparent;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
                color: #1e1e1e;
                padding: 11px 13px;
                position: static;
                text-shadow: none;
                -webkit-transition: all 0.2s linear;
                transition: all 0.2s linear;
                z-index: 901;
              }
            }
          }
          .selected > a {
            color: #777;
          }
        }
        a {
          > i {
            margin-right: 2px;
            opacity: .8;
            filter: alpha(opacity = 80);
            text-align: center;
            width: 15px;
          }
          .item-new {
            background: #ff0068;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            color: #fff;
            font-size: 11px;
            line-height: 1;
            letter-spacing: 0.01em;
            padding: 4px 10px;
            position: absolute;
            right: 19px;
            top: 1px;
            text-transform: uppercase;
          }
        }
        .megamenu a .item-new {
          right: 14px;
          top: -12px;
        }
        a .item-new {
          .before, &:before {
            border: 5px solid transparent;
            border-right-color: #ff0068;
            bottom: -5px;
            content: "";
            display: block;
            position: absolute;
            right: 0;
          }
          &.bg-info {
            .before, &:before {
              border-right-color: #0098ca;
            }
          }
          &.bg-success {
            .before, &:before {
              border-right-color: #738d00;
            }
          }
          &.bg-primary {
            .before, &:before {
              border-right-color: #428bca;
            }
          }
          &.bg-warning {
            .before, &:before {
              border-right-color: #f89406;
            }
          }
          &.bg-danger {
            .before, &:before {
              border-right-color: #c10841;
            }
          }
        }
        .sub .item-new {
          float: right;
          left: 0;
          margin: 2px 0 0 10px;
          position: relative;
          top: 0;
        }
        .megamenu .sub a .item-new {
          float: right;
          left: 0;
          margin: 2px 0 0 10px;
          position: relative;
          top: 0;
          float: none;
        }
        .sub .item-new {
          .before, &:before {
            display: none;
          }
        }
        > li:hover > a {
          background: #fff;
          border-color: #ccc;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        .parent {
          position: relative;
        }
        > li.parent {
          &:hover {
            > a {
              background: #fff;
              border-bottom-color: transparent;
              -webkit-border-radius: 3px 3px 0 0;
              -moz-border-radius: 3px 3px 0 0;
              border-radius: 3px 3px 0 0;
            }
            > a:after {
              background: #fff;
              display: block;
              -webkit-transition: background 0.1s linear;
              transition: background 0.1s linear;
            }
          }
          > a:after {
            background: transparent;
            bottom: 0;
            content: "";
            display: none;
            height: 12px;
            left: 4px;
            margin: 0 0 -6px 0;
            position: absolute;
            right: 4px;
            -webkit-transition: background 0.5s linear;
            transition: background 0.5s linear;
            z-index: 906;
          }
          &.megamenu:hover > a:after {
            left: 0;
            right: 0;
          }
        }
      }
    }
    .sub {
      background: #fff;
      border: 1px solid #ccc;
      -webkit-border-radius: 0 3px 3px 3px;
      -moz-border-radius: 0 3px 3px 3px;
      border-radius: 0 3px 3px 3px;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      display: none;
      font-size: 13px;
      line-height: 24px;
      margin: -1px 0 0;
      padding: 15px 20px;
      position: absolute;
      z-index: 905;
      li {
        font-size: 13px;
        line-height: 24px;
      }
    }
    .parent:hover > .sub {
      display: block;
      -webkit-animation: fadeIn 0.4s;
      animation: fadeIn 0.4s;
    }
    .sub {
      a {
        color: #1e1e1e;
        display: block;
        text-decoration: none;
        width: 175px;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
        &:visited {
          color: #1e1e1e;
          display: block;
          text-decoration: none;
          width: 175px;
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
        &:hover {
          opacity: .6;
          filter: alpha(opacity = 60);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
      }
      .sub {
        left: 100%;
        top: 0;
      }
    }
    .navbar .nav .parent {
      .parent > a:after {
        content: "\f105";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        display: block;
        line-height: 1;
        position: absolute;
        right: -10px;
        top: 6px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &.megamenu {
        overflow: hidden;
        position: static;
        > a {
          position: relative;
        }
      }
    }
    .megamenu {
      > .sub {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        left: -15px;
        padding: 0;
        width: 886px;
        z-index: 900;
        .sub-wrapper {
          display: table;
          width: 100%;
        }
        .promo-block {
          border-right: 1px solid #ccc;
          display: table-cell;
          font-size: 12px;
          line-height: 18px;
          position: relative;
          text-align: center;
          vertical-align: middle;
          width: 34%;
        }
        .sub-list {
          + .promo-block {
            border-left: 1px solid #ccc;
            border-right: 0 none;
          }
          display: table-cell;
          padding: 35px 5px;
          vertical-align: top;
          width: 100%;
        }
      }
      &.promo {
        > .sub .sub-list {
          width: 66%;
        }
        > .sub .box {
          &:nth-child(2n+1) {
            clear: left;
          }
          clear: none;
          width: 50%;
        }
      }
      > .sub .box {
        float: left;
        padding: 0 20px 28px;
        width: 33%;
        &:nth-child(3n+1) {
          clear: left;
        }
      }
      &.four-columns > .sub .box {
        clear: none;
        &:nth-child(4n+1) {
          clear: left;
        }
      }
      &.promo.four-columns > .sub .box {
        &:nth-child(3n+1) {
          clear: left;
        }
        clear: none;
      }
      &.four-columns > .sub {
        .promo-block, .box {
          width: 25%;
        }
        .sub-list {
          width: 75%;
        }
      }
      &.promo.four-columns > .sub .box {
        width: 33%;
      }
      &.five-columns > .sub .box {
        clear: none;
        &:nth-child(5n+1) {
          clear: left;
        }
      }
      &.promo.five-columns > .sub .box {
        &:nth-child(4n+1) {
          clear: left;
        }
        clear: none;
      }
      &.five-columns > .sub {
        .promo-block, .box {
          width: 20%;
        }
        .sub-list {
          width: 80%;
        }
      }
      &.promo.five-columns > .sub .box {
        width: 25%;
      }
      .title {
        font-size: 13px;
        line-height: 23px;
        margin: 0 0 3px;
        text-transform: uppercase;
      }
      > .sub a {
        width: 100%;
      }
    }
    .sub .sub .sub {
      .sub {
        background: none;
        border: 0 none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        display: block;
        left: 0;
        padding: 0;
        position: relative;
        -webkit-animation: fadeIn 0;
        animation: fadeIn 0;
      }
      .parent > a {
        background: none !important;
      }
    }
    .navbar .nav > li {
      &.text-primary > a {
        color: #428bca;
      }
      &.text-success > a {
        color: #738d00;
      }
      &.text-info > a {
        color: #0098ca;
      }
      &.text-warning > a {
        color: #f89406;
      }
      &.text-danger > a {
        color: #c10841;
      }
      &[class*="item-"]:hover > a {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
    [class*="item-"] .sub {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .navbar .nav > li {
      &.parent.item-bg:hover > a:after {
        display: none;
      }
      &.item-primary:hover > a {
        border-color: #428bca;
      }
    }
    .item-primary {
      .sub, &.megamenu > .sub .promo-block {
        border-color: #428bca;
      }
    }
    .navbar .nav > li.item-success:hover > a {
      border-color: #738d00;
    }
    .item-success {
      .sub, &.megamenu > .sub .promo-block {
        border-color: #738d00;
      }
    }
    .navbar .nav > li.item-info:hover > a {
      border-color: #0098ca;
    }
    .item-info {
      .sub, &.megamenu > .sub .promo-block {
        border-color: #0098ca;
      }
    }
    .navbar .nav > li.item-warning:hover > a {
      border-color: #f89406;
    }
    .item-warning {
      .sub, &.megamenu > .sub .promo-block {
        border-color: #f89406;
      }
    }
    .navbar .nav > li.item-danger:hover > a {
      border-color: #c10841;
    }
    .item-danger {
      .sub, &.megamenu > .sub .promo-block {
        border-color: #c10841;
      }
    }
    .navbar .nav > li {
      &.item-bg {
        &:hover a, .sub a {
          color: #fff;
        }
      }
      &.item-primary.item-bg:hover > a {
        background: #428bca;
      }
    }
    .item-primary.item-bg {
      .sub {
        background: #428bca;
      }
      &.parent {
        .sub, &:hover > .sub {
          background: #428bca;
        }
      }
    }
    .navbar .nav > li.item-success.item-bg:hover > a {
      background: #738d00;
    }
    .item-success.item-bg {
      .sub {
        background: #738d00;
      }
      &.parent {
        .sub, &:hover > .sub {
          background: #738d00;
        }
      }
    }
    .navbar .nav > li.item-info.item-bg:hover > a {
      background: #0098ca;
    }
    .item-info.item-bg {
      .sub {
        background: #0098ca;
      }
      &.parent {
        .sub, &:hover > .sub {
          background: #0098ca;
        }
      }
    }
    .navbar .nav > li.item-warning.item-bg:hover > a {
      background: #f89406;
    }
    .item-warning.item-bg {
      .sub {
        background: #f89406;
      }
      &.parent {
        .sub, &:hover > .sub {
          background: #f89406;
        }
      }
    }
    .navbar .nav > li.item-danger.item-bg:hover > a {
      background: #c10841;
    }
    .item-danger.item-bg {
      .sub {
        background: #c10841;
      }
      &.parent {
        .sub, &:hover > .sub {
          background: #c10841;
        }
      }
    }
    .navbar .nav > li {
      &.item-inverse > a {
        color: #fff;
      }
      &.item-primary.item-inverse > a {
        background: #428bca;
      }
      &.item-success.item-inverse > a {
        background: #738d00;
      }
      &.item-info.item-inverse > a {
        background: #0098ca;
      }
      &.item-warning.item-inverse > a {
        background: #f89406;
      }
      &.item-danger.item-inverse > a {
        background: #c10841;
      }
      &.item-inverse:hover > a {
        background: #fff;
        color: #1e1e1e;
      }
    }
  }
  .header-icons {
    float: right;
    margin: 9px 0;
    text-align: right;
  }
  .cart-header {
    display: inline-block;
    font-size: 12px;
    margin: 0 -15px 0 -1px;
    white-space: normal;
    &.no-open {
      opacity: 0;
      filter: alpha(opacity = 0);
    }
    .dropdown-toggle {
      border-width: 1px;
      border-color: #fff;
      border-color: transparent;
      border-bottom: 0 none;
      color: #1e1e1e;
      display: block;
      line-height: 22px;
      outline: none;
      padding: 11px 15px 12px;
      position: relative;
      text-decoration: none;
      text-shadow: none;
      -webkit-transition: border-color 0.4s linear, box-shadow 0.4s linear, -moz-box-shadow 0.4s linear, -webkit-box-shadow 0.4s linear;
      transition: border-color 0.4s linear, box-shadow 0.4s linear, -moz-box-shadow 0.4s linear, -webkit-box-shadow 0.4s linear;
      z-index: 891;
      .icon {
        display: inline-block;
        height: 22px;
        margin: 0 8px 0 0;
        vertical-align: top;
        width: 16px;
        svg {
          margin-top: 2px;
          vertical-align: top;
        }
      }
    }
    &.open .dropdown-toggle {
      background: #fff;
      border-color: #ccc;
      border-bottom: 0 none;
      border-radius: 3px 3px 0 0;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -webkit-transition: border-color 0.4s linear, box-shadow 0.4s linear, -moz-box-shadow 0.4s linear, -webkit-box-shadow 0.4s linear;
      transition: border-color 0.4s linear, box-shadow 0.4s linear, -moz-box-shadow 0.4s linear, -webkit-box-shadow 0.4s linear;
      &:after {
        background: #fff;
        bottom: 0;
        content: "";
        display: block;
        height: 8px;
        left: -3px;
        margin: 0 0 -5px 0;
        position: absolute;
        right: 0;
      }
    }
    .dropdown-menu {
      left: auto;
      margin: -4px 0 0 0;
      padding: 17px 20px 20px;
      right: 0;
      text-align: left;
      width: 355px;
      -webkit-animation: fadeIn 0.4s;
      animation: fadeIn 0.4s;
      z-index: 890;
      .close-box {
        line-height: 12px;
        margin: 0 0 13px;
        text-align: right;
      }
      .close {
        font-size: 26px;
        line-height: 12px;
        text-transform: uppercase;
        vertical-align: top;
        span {
          line-height: 14px;
          margin: 0 5px 0 0;
        }
      }
      > {
        strong {
          font-size: 13px;
          line-height: 22px;
          text-transform: uppercase;
        }
        ul {
          margin: 0 0 20px;
          li {
            border-bottom: 1px solid #e1e1e1;
            padding: 24px 20px 24px 0;
            position: relative;
          }
        }
      }
      .product-image {
        display: block;
        float: left;
        margin: 0 15px 0 0;
        width: 70px;
      }
      .product-remove {
        margin: 0 0 0 5px;
        position: absolute;
        right: 0;
        top: 24px;
      }
      .product-name {
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        margin: 0 0 10px;
        padding-left: 85px;
      }
      .product-price {
        font-size: 12px;
        line-height: 16px;
        padding-left: 85px;
        .price {
          font-size: 16px;
        }
      }
      .cart-button {
        text-align: right;
        .checkout {
          margin-left: 6px;
        }
      }
    }
  }
}

/* Primary Menu */

/* Four columns */

/* Five columns */

/* Menu Color */

/* Cart  */

/* Phone & Search Header */

.phone-header, .search-header {
  border-right: 1px solid #ccc;
  border-right: 1px solid rgba(204, 204, 204, 0.8);
  color: #1e1e1e;
  display: inline-block;
  height: 30px;
  margin: 8px 0;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 41px;
}

.phone-header:last-child, .search-header:last-child {
  border-right: 0 none;
}

.phone-header a, .search-header a {
  display: block;
  height: 23px;
  padding-top: 7px;
  width: 100%;
}

.phone-header a svg, .search-header a svg {
  overflow: visible;
  opacity: 1;
  filter: alpha(opacity = 100);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  -webkit-transform: translateZ(0px);
}

.phone-header a:hover svg, .search-header a:hover svg {
  opacity: .5;
  filter: alpha(opacity = 50);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

/* Phone & Search Active Header */

.phone-active, .search-active {
  bottom: 0;
  display: none;
  margin: 18px 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 920;
}

.phone-active {
  background: #fff;
  color: #ebebeb;
  font-size: 48px;
  line-height: 58px;
  .close {
    float: right;
    font-size: 26px;
    margin: 24px 0 0 5px;
  }
}

.search-active .close {
  float: right;
  font-size: 26px;
  margin: 24px 0 0 5px;
}

.phone-active .close span, .search-active .close span {
  line-height: 18px;
  margin-right: 5px;
  margin-top: 0;
  text-transform: uppercase;
}

.phone-active strong {
  color: #1e1e1e;
  letter-spacing: 1px;
}

.search-active {
  .close {
    position: absolute;
    right: 79px;
    z-index: 1;
  }
  form {
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%;
  }
  .search-string {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #1e1e1e;
    font-size: 24px;
    height: 100%;
    line-height: 30px;
    margin: 0;
    padding: 14px 135px 14px 18px;
    width: 100%;
    &:hover {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      color: #1e1e1e;
      font-size: 24px;
      height: 100%;
      line-height: 30px;
      margin: 0;
      padding: 14px 135px 14px 18px;
      width: 100%;
    }
    &:focus {
      border-color: #ccc;
      outline: 0;
      outline: thin dotted \9;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  .search-submit {
    background: none;
    border-left-width: 1px;
    height: 30px;
    line-height: 1;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    right: 1px;
    top: 17px;
    width: 46px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    svg {
      margin-top: 2px;
      path {
        fill: #1e1e1e;
      }
    }
    &:hover {
      opacity: .5;
      filter: alpha(opacity = 50);
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
  }
}

.header {
  &.header-two .phone-header {
    border-right: 0 none;
    margin-right: -15px;
  }
  &.header-three {
    background: rgba(255, 255, 255, 0.9);
    min-height: inherit;
    .row, .container {
      min-height: inherit;
    }
    .logo-box {
      text-align: center;
    }
  }
}

/******************************************************************************
  Top Fixed Box
*******************************************************************************/

.top-fixed-box {
  background: #fff;
  background: rgba(255, 255, 255, 0.97);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #e1e1e1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: -60px;
  padding: 7px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-transition: margin-top .3s linear;
  transition: margin-top .3s linear;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  &.fixed {
    margin-top: 0;
    -webkit-transition: margin-top .3s linear;
    transition: margin-top .3s linear;
  }
  .social {
    display: inline-block;
    margin: 0 6px;
    vertical-align: top;
  }
  .contact-box {
    color: #7f7f7f;
    font-size: 12px;
    margin-top: 11px;
    .phone {
      margin-right: 20px;
    }
    .fa {
      font-size: 14px;
      margin-right: 3px;
    }
    .phone .fa {
      font-size: 20px;
      line-height: 17px;
      vertical-align: top;
    }
  }
}

/******************************************************************************
  Breadcrumbs
*******************************************************************************/

.breadcrumb-box {
  background: #f2f2f2;
  overflow: hidden;
}

.breadcrumb {
  background: #f2f2f2;
  color: #7f7f7f;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  padding: 11px 0;
  > li {
    line-height: 18px;
    text-shadow: none;
    > a {
      color: #7f7f7f;
      text-decoration: none;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
    + li:before {
      padding: 0 6px;
      color: #7f7f7f;
    }
  }
}

.breadcrumb-none {
  height: 10px;
}

/******************************************************************************
  Slider
*******************************************************************************/

.slider {
  background: #f2f2f2;
  padding: 54px 0;
  > .container {
    position: relative;
  }
  &.load .sliders-box > .col-md-12 + .col-md-12 {
    display: none;
  }
  .slid {
    position: relative;
    .slid-img {
      max-height: 550px;
      z-index: 1;
    }
  }
  .slid-content {
    bottom: 93px;
    color: #7b7b7b;
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    padding: 38px 40px 0;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 3;
    .title {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 34px;
    }
    .descriptions {
      margin: 0 0 37px 0;
    }
    .btn {
      background: #f8f8f8;
      color: #1e1e1e;
      font-weight: bold;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      &:hover {
        background: #fff;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
      }
    }
  }
  .slider-nav {
    background-color: #1e1e1e;
    background-color: rgba(30, 30, 30, 0.97);
    bottom: 0;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 2;
    .nav-box {
      bottom: 33px;
      left: 40px;
      position: absolute;
      right: 40px;
      text-align: center;
      z-index: 4;
    }
    .prev {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      color: #838282;
      display: block;
      height: 60px;
      left: 0;
      line-height: 60px;
      opacity: 0;
      filter: alpha(opacity = 0);
      position: absolute;
      text-align: center;
      text-decoration: none;
      visibility: hidden;
      width: 60px;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
    .next {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      color: #838282;
      display: block;
      height: 60px;
      left: 0;
      line-height: 60px;
      opacity: 0;
      filter: alpha(opacity = 0);
      position: absolute;
      text-align: center;
      text-decoration: none;
      visibility: hidden;
      width: 60px;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      left: auto;
      right: 0;
    }
    .prev *, .next * {
      vertical-align: middle;
    }
    .prev {
      margin-right: 2px;
    }
    .next {
      margin-left: 2px;
    }
  }
  .container:hover .slider-nav {
    .prev, .next {
      opacity: 1;
      filter: alpha(opacity = 100);
      visibility: visible;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
  }
}

.page-slider:hover .slider-nav {
  .prev, .next {
    opacity: 1;
    filter: alpha(opacity = 100);
    visibility: visible;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
}

.slider .slider-nav {
  .prev:hover, .next:hover {
    background-color: #fff;
    color: #a0a0a0;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
  .prev:hover polygon, .next:hover polygon {
    fill: #9f9f9f;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
  .pagination {
    padding: 0 65px;
    text-align: center;
  }
}

.switches a {
  background: #fff;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  height: 14px;
  margin: 0 3px;
  width: 14px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  &:hover {
    background: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
  &.selected {
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
}

.progressive-slider .slider-nav .pagination a {
  vertical-align: middle;
}

.page-slider {
  background: none;
  padding: 0;
  position: relative;
}

.progressive-slider-two {
  .sliders-container {
    margin: 0 -15px 0 15px;
    padding: 0;
    position: relative;
  }
  &.load .sliders-box img + img {
    display: none;
  }
  .pagination {
    bottom: 0;
    left: 0;
    margin: 0 0 15px;
    position: absolute;
    right: 0;
    text-align: center;
    a {
      background: #1e1e1e;
      background: rgba(30, 30, 30, 0.5);
      &:hover {
        background: #1e1e1e;
      }
    }
    .selected {
      background: #1e1e1e;
    }
  }
  .slider-banners {
    display: table;
    height: 100%;
    table-layout: fixed;
    .banner {
      display: table-row;
      height: 33.33333333%;
      background: #f6a232;
      &:nth-child(1) {
        background: #ee485c;
      }
      &:nth-child(2) {
        background: #65a93c;
      }
    }
  }
}

.progressive-slider-three {
  .slider-wrapper {
    border: solid 6px #fff;
    border-radius: 6px;
  }
  &.load .slider-wrapper img + img {
    display: none;
  }
}

.progressive-slider-four {
  &.load .slider-wrapper img + img {
    display: none;
  }
  background: none;
  padding: 0;
  position: relative;
  .prev {
    color: #fff;
    font-size: 40px;
    left: 30px;
    line-height: 1;
    margin-top: -20px;
    position: absolute;
    top: 50%;
  }
  .next {
    color: #fff;
    font-size: 40px;
    left: 30px;
    line-height: 1;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    left: auto;
    right: 30px;
  }
}

.content {
  .progressive-slider .container {
    padding: 0;
    width: 100% !important;
  }
  .slider {
    &.progressive-slider {
      padding: 0;
    }
    .slider-nav {
      right: 0;
    }
    .slid-content {
      bottom: 84px;
      padding: 28px 30px 0;
      right: 8px;
      width: 47.333333%;
    }
  }
}

.slider .slider-nav .nav-box {
  left: 23px;
  right: 23px;
}

.content {
  .slider .slider-nav {
    width: 47.333333%;
    .prev, .next {
      height: 36px;
      line-height: 36px;
      width: 36px;
    }
  }
  .switches a {
    width: 11px;
    height: 11px;
  }
  .slider {
    .slider-nav .nav-box {
      bottom: 30px;
    }
    .pagination {
      margin: 5px 0;
    }
  }
}

/******************************************************************************
  Slider Revolution
*******************************************************************************/

.slider {
  &.rs-slider {
    background: none;
    border: 0 none;
    max-height: 500px;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  &.load .tp-banner-container {
    visibility: hidden;
  }
  &.rs-slider {
    .container {
      position: relative;
    }
    .title {
      color: #fff;
      font-size: 40px;
      font-weight: normal;
      margin: 0;
    }
    .description {
      color: #fff;
      font-size: 17px;
      line-height: 25px;
      margin: 0;
      padding: 0 15px;
    }
    .btn {
      color: #fff;
      &.orang {
        background: #ea4e16;
      }
      &.cherry {
        background: #752033;
      }
    }
    .phone-text {
      color: #fff;
      font-size: 17px;
    }
  }
}

.rs-slider ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tp-bannertimer {
  background: #777 !important;
  background: rgba(0, 0, 0, 0.1) !important;
  height: 5px !important;
}

.tp-bullets.simplebullets.navbar {
  height: 35px;
  padding: 0;
}

.slider.rs-slider .tp-bullets.simplebullets .bullet {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0 none !important;
  display: inline-block;
  height: 14px;
  float: none;
  margin: 0 3px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  vertical-align: top;
  width: 14px;
}

.tp-bullets.simplebullets .bullet.last {
  margin-right: 0px;
}

.slider.rs-slider {
  .tp-bullets.simplebullets .bullet {
    &:hover {
      background: #fff;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
    &.selected {
      background: rgba(255, 255, 255, 0.7);
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
  }
  .tparrows {
    background: #1e1e1e;
    background: rgba(30, 30, 30, 0.5);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 40px !important;
    width: 40px !important;
    -webkit-transition: background .3s linear;
    transition: background .3s linear;
    -webkit-transition: opacity 0.2s ease-out;
    -webkit-transform: inherit;
    z-index: 50 !important;
    &:before {
      font-family: 'revicons';
      color: #fff;
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      margin-right: 0;
      margin-top: 6px;
      text-align: center;
      width: 40px;
      font-size: 19px;
    }
  }
}

.tp-leftarrow:before {
  content: '\e824';
}

.tp-rightarrow:before {
  content: '\e825';
}

.slider.rs-slider .tparrows:hover {
  background: #1e1e1e;
  -webkit-transition: background .3s linear;
  transition: background .3s linear;
}

.slider4container .tparrows {
  &:before {
    color: #000 !important;
  }
  &:hover {
    color: #000 !important;
    background: #fff !important;
  }
  color: #000 !important;
  background: #fff !important;
  background: rgba(255, 255, 255, 0.5) !important;
}

.slider.rs-slider .tp-loader {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  background-color: inherit;
  height: 40px;
  margin: -20px 0 0 -20px;
  width: 40px;
  z-index: 700;
}

.layerslider {
  margin: 0 auto;
}

.slider.rs-slider.full-width {
  max-height: inherit;
  .title {
    font-size: 60px;
    line-height: 0.9;
  }
  .btn-link {
    font-size: 14px !important;
    font-weight: bold;
    line-height: 31px;
    letter-spacing: 1px;
    padding: 0;
    text-decoration: none;
    .fa {
      border: 2px solid #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      font-size: 180%;
      height: 36px;
      line-height: 32px;
      margin-left: 10px;
      padding: 0 0 0 3px;
      text-align: center;
      vertical-align: middle;
      width: 36px;
    }
    &:hover {
      opacity: .8;
    }
  }
  .tp-rightarrow, .tp-leftarrow {
    display: none !important;
  }
}

/******************************************************************************
  Slider Royal
*******************************************************************************/

.royal-slider {
  background: none;
  padding: 0;
  width: 100%;
  &.load {
    background-image: url("../img/svg/loader.svg"), none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40px;
    background-color: inherit;
    overflow: hidden;
  }
}

.rsMinW {
  background: #f2f2f2;
  .rsOverflow, .rsSlide, .rsVideoFrameHolder, .rsThumbs {
    background: #f2f2f2;
  }
}

.coloredBlock {
  padding: 12px;
  background: rgba(255, 0, 0, 0.6);
  color: #FFF;
  width: 200px;
  left: 20%;
  top: 5%;
}

.infoBlock {
  position: absolute;
  top: 30px;
  right: 30px;
  left: auto;
  max-width: 25%;
  padding-bottom: 0;
  background: #FFF;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  padding: 20px;
}

.infoBlockLeftBlack {
  color: #FFF;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  left: 30px;
  right: auto;
}

.infoBlock {
  h4 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
    padding-bottom: 3px;
  }
  p {
    font-size: 14px;
    margin: 4px 0 0;
  }
  a {
    color: #FFF;
    text-decoration: underline;
  }
}

.photosBy {
  position: absolute;
  line-height: 24px;
  font-size: 12px;
  background: #FFF;
  color: #000;
  padding: 0px 10px;
  position: absolute;
  left: 12px;
  bottom: 12px;
  top: auto;
  border-radius: 2px;
  z-index: 25;
  a {
    color: #000;
  }
}

.fullWidth {
  max-width: 1400px;
  margin: 0 auto 24px;
}

.layer-slider {
  overflow: hidden;
}

/******************************************************************************
  Metro Box
*******************************************************************************/

.metro-banners {
  background: #b3768e;
  background: -moz-linear-gradient(left, #b3768e 0%, #54638e 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #b3768e), color-stop(100%, #54638e));
  background: -webkit-linear-gradient(left, #b3768e 0%, #54638e 100%);
  background: -o-linear-gradient(left, #b3768e 0%, #54638e 100%);
  background: -ms-linear-gradient(left, #b3768e 0%, #54638e 100%);
  background: linear-gradient(to right, #b3768e 0%, #54638e 100%);
  padding: 54px 0 24px;
  .banner {
    margin-bottom: 30px;
    text-align: center;
  }
}

.banner {
  display: block;
  text-align: center;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:hover {
    opacity: .8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.metro-banners .banner img {
  -webkit-box-shadow: 3px 3px 0px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 3px 3px 0px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 3px 3px 0px 0px rgba(50, 50, 50, 0.5);
}

/******************************************************************************
  Sidebar
*******************************************************************************/

.sidebar .widget {
  margin: 0 0 80px;
  &:last-child {
    margin-bottom: 0;
  }
  ol, ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  header, .title-block {
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 40px;
    padding: 0 0 15px;
    position: relative;
  }
}

.product-bottom .related-products header {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 0 40px;
  padding: 0 0 15px;
  position: relative;
}

.sidebar .widget {
  header:before, .title-block:before {
    background: #1e1e1e;
    bottom: 0px;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: 0 0 -1px;
    position: absolute;
    width: 40px;
  }
}

.product-bottom .related-products header:before {
  background: #1e1e1e;
  bottom: 0px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}

.sidebar .widget {
  header .title, .title-block .title {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
  }
}

.product-bottom .related-products header h3 {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}

.sidebar {
  .widget .subtitle {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 25px;
  }
  .whats-news {
    margin-bottom: 40px;
    overflow: hidden;
  }
  .carousel-box header .title {
    line-height: 40px;
  }
  .whats-news header {
    margin: 0;
  }
  .carousel-box .product {
    display: block;
    float: left;
    margin: 40px 15px;
  }
  .action {
    overflow: hidden;
    .clear-all {
      float: left;
      font-size: 14px;
      line-height: 40px;
      text-transform: none;
      &.close {
        font-size: 20px;
        line-height: 38px;
      }
      span {
        font-size: 14px;
        line-height: 39px;
        margin: 0 0 0 2px;
        vertical-align: middle;
      }
    }
    .btn {
      float: right;
    }
  }
  .more {
    color: #7f7f7f;
    font-size: 12px;
    margin: 5px 0 0;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      text-decoration: none;
    }
  }
  .menu {
    li {
      margin: 0 0 3px;
      a {
        background-color: #f2f2f2;
        background-color: rgba(0, 0, 0, 0.05);
        color: #1e1e1e;
        display: block;
        padding: 9px 9px 9px 60px;
        position: relative;
        text-decoration: none;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        &:before {
          background: #e1e1e1;
          background: rgba(255, 255, 255, 0.4);
          bottom: 0;
          content: "";
          display: block;
          left: 45px;
          position: absolute;
          top: 0;
          width: 1px;
        }
        &:hover {
          background-color: #e9e9e9;
          background-color: rgba(0, 0, 0, 0.1);
          -webkit-transition: all .2s linear;
          transition: all .2s linear;
        }
      }
      &.active > a {
        background-color: #1e1e1e;
        color: #fff;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        &:before {
          background: #444;
          background: rgba(255, 255, 255, 0.2);
        }
      }
      &.parent {
        > a .open-sub {
          bottom: 0;
          display: block;
          left: 0;
          position: absolute;
          top: 0;
          width: 45px;
          &:before {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
          }
          &:after {
            background: #1e1e1e;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: 19px 0 0 15px;
            position: absolute;
            top: 0;
            width: 16px;
            height: 16px;
            margin: 12px 0 0 22px;
            width: 2px;
          }
        }
        &.active > a .open-sub {
          &:before {
            background: #fff;
          }
          &:after {
            background: #fff;
            display: none;
          }
        }
      }
    }
    .sub {
      background-color: #f2f2f2;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 7px 0 4px 5px;
      li {
        margin: 0;
      }
      a {
        background: none;
        color: #7f7f7f;
        margin: 0;
        padding: 4px 9px 4px 60px;
        &:before {
          display: none;
        }
        &:hover {
          background-color: #e9e9e9;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      .active > a {
        background: none;
        color: #000;
      }
      .sub {
        background: none;
      }
      li.parent > a .open-sub {
        left: 10px;
        &:before {
          background: #7f7f7f;
          margin: 14px 0 0 22px;
          width: 12px;
        }
        &:after {
          background: #7f7f7f;
          margin: 14px 0 0 22px;
          width: 12px;
          height: 12px;
          margin: 9px 0 0 27px;
          width: 2px;
        }
      }
    }
    li {
      > a .item-icon {
        left: 15px;
        position: absolute;
        top: 13px;
      }
      &:not(.active) > a .item-icon {
        opacity: .5;
        filter: alpha(opacity = 50);
      }
    }
  }
  .section {
    + .section {
      border-top-width: 1px;
      margin: 20px 0 0;
      padding: 20px 0 0;
    }
    li {
      line-height: 18px;
      + li {
        margin: 12px 0 0;
      }
      > a {
        color: #1e1e1e;
        &:visited {
          color: #1e1e1e;
        }
      }
      .fa {
        color: #7f7f7f;
      }
    }
    .selected li {
      background: url("../img/point.png") right 13px repeat-x #fff;
      margin: 0 0 8px;
      padding: 0 16px 0 0;
      position: relative;
    }
    li {
      > span {
        background: #fff;
        color: #7f7f7f;
      }
      span span {
        color: #1e1e1e;
      }
    }
    .selected .close {
      background: #fff;
      color: #7f7f7f;
      font-size: 20px;
      padding: 0 2px;
      position: absolute;
      right: 0px;
      top: 3px;
      &:hover {
        color: #1e1e1e;
      }
    }
  }
  .list {
    ul {
      margin-bottom: 0;
    }
    li {
      padding: 4px 0;
      a {
        color: #1e1e1e;
      }
      span {
        color: #7f7f7f;
      }
    }
  }
  .specials li {
    padding: 24px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    + li {
      border-top-width: 1px;
    }
    .product-image {
      background: #f7f7f7;
      border: 1px solid #f5f5f5;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      float: left;
      height: 74px;
      margin: 0 15px 1px 0;
      overflow: hidden;
      width: 74px;
    }
    .product-name {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      margin: 0 0 4px;
      padding-left: 85px;
    }
    .price-box {
      font-size: 16px;
      line-height: 22px;
      padding-left: 85px;
    }
    .rating-box {
      margin: 3px 0 14px 85px;
      overflow: hidden;
    }
  }
  .poll {
    strong {
      display: block;
      margin-bottom: 21px;
    }
    ul {
      margin: 0;
      padding: 0 0 27px;
    }
    li {
      margin-bottom: 11px;
    }
  }
  .compare-products {
    ul {
      margin: 0;
      padding: 0 0 17px;
    }
    li {
      background: url("../img/point.png") right 13px repeat-x #fff;
      margin: 0 0 8px;
      padding: 0 16px 0 0;
      position: relative;
      .title {
        background: #fff;
        color: #1e1e1e;
        padding-right: 2px;
      }
      .close {
        background: #fff;
        font-size: 20px;
        padding: 0 2px;
        position: absolute;
        right: 0px;
        top: 3px;
      }
    }
  }
  .wishlist {
    li {
      padding: 0 46px 0 0;
    }
    .add-cart {
      background: #fff;
      color: #7f7f7f;
      height: 100%;
      padding: 0 3px;
      position: absolute;
      right: 26px;
      top: 1px;
      path {
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      &:hover path {
        fill: #1e1e1e;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
  }
  .newsletter {
    form {
      margin: 5px 0 0;
      position: relative;
    }
    input {
      margin: 0;
      padding-right: 43px;
    }
    .submit {
      background: none;
      color: #ccc;
      height: 38px;
      outline: none;
      padding: 0;
      position: absolute;
      right: 1px;
      top: 1px;
      width: 38px;
      .glyphicon {
        line-height: 20px;
      }
    }
    input:focus + .submit {
      color: #000;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
  }
  .banners {
    overflow: hidden;
    .slide {
      float: left;
      max-width: 270px;
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 0;
      padding-right: 0;
      position: relative;
    }
    .banner-text {
      background: rgb(30, 30, 30);
      background: rgba(30, 30, 30, 0.65);
      bottom: 0;
      color: #fff;
      left: 0;
      min-height: 58px;
      padding: 20px;
      position: absolute;
      right: 0;
      text-align: right;
      .title {
        font-size: 20px;
        margin: 0;
      }
      p {
        line-height: 18px;
        margin: 0;
      }
    }
  }
  .tags {
    ul {
      margin: 0;
    }
    li {
      float: left;
      margin: 0 3px 3px 0;
    }
    a {
      background: #1e1e1e;
      background: rgba(30, 30, 30, 0.7);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      color: #fff;
      display: block;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      padding: 0 5px;
      text-decoration: none;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      &:hover {
        background: #1e1e1e;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
  }
}

/* Menu */

/* Shop By */

/* List */

/* Specials */

/* Community Poll */

/* Compare Products */

/* Newsletter */

/* Recommended */

/* Tags */

/* Social */

.social .sbtnf {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  display: block;
  height: 40px;
  float: left;
  line-height: 40px;
  margin: 0 10px 0 0;
  opacity: .35;
  filter: alpha(opacity = 35);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  text-align: center;
  width: 40px;
  &:hover {
    opacity: 1;
    filter: alpha(opacity = 100);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.sidebar {
  .widget.social .sbtnf {
    margin-bottom: 10px;
  }
  .links {
    li {
      line-height: 21px;
    }
    a {
      color: #7f7f7f;
      text-decoration: none;
      -webkit-transition: color .2s linear;
      transition: color .2s linear;
      &:visited {
        color: #7f7f7f;
        text-decoration: none;
        -webkit-transition: color .2s linear;
        transition: color .2s linear;
      }
      &:hover {
        color: #000;
        -webkit-transition: color .2s linear;
        transition: color .2s linear;
      }
    }
  }
  .calendar-wrap {
    background: rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    table {
      margin: 0;
      td, th {
        padding: 3px;
      }
    }
    caption {
      background: #1e1e1e;
      color: #fff;
      font-size: 14px;
      padding: 5px;
    }
    tfoot {
      td {
        border: 0 none;
      }
      a {
        text-decoration: none;
        &:hover {
          opacity: .8;
          filter: alpha(opacity = 80);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
      }
    }
  }
  .facebook-widget .fb-like-box {
    border: 1px solid #e1e1e1;
    max-width: 270px;
    width: 100%;
    > span {
      max-width: 100% !important;
    }
  }
  .calendar .datepicker-box .datepicker {
    background: rgba(0, 0, 0, 0.02);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 0;
    width: 270px;
    max-width: 100%;
    table {
      width: 100%;
      max-width: 100%;
    }
  }
}

/* Links */

/* Calendar */

/* Datepicker */

.datepicker table tr td {
  &.active {
    &:hover, &.disabled:hover, &:active, &:hover:active {
      background: #037ac5;
    }
    &.disabled {
      &:active, &:hover:active {
        background: #037ac5;
      }
    }
    &.active, &:hover.active {
      background: #037ac5;
    }
    &.disabled {
      &.active, &:hover.active {
        background: #037ac5;
      }
      background: #037ac5;
    }
    &:hover.disabled {
      background: #037ac5;
    }
    &.disabled {
      &.disabled, &:hover.disabled {
        background: #037ac5;
      }
    }
    &[disabled], &:hover[disabled] {
      background: #037ac5;
    }
    &.disabled {
      &[disabled], &:hover[disabled] {
        background: #037ac5;
      }
    }
    background: #037ac5;
    &:hover {
      background: #037ac5;
    }
    &.disabled {
      background: #037ac5;
      &:hover {
        background: #037ac5;
      }
    }
  }
  &.today {
    &:hover, &.disabled:hover, &:active, &:hover:active {
      background: #f89406;
      color: #fff;
    }
    &.disabled {
      &:active, &:hover:active {
        background: #f89406;
        color: #fff;
      }
    }
    &.active, &:hover.active {
      background: #f89406;
      color: #fff;
    }
    &.disabled {
      &.active, &:hover.active {
        background: #f89406;
        color: #fff;
      }
      background: #f89406;
      color: #fff;
    }
    &:hover.disabled {
      background: #f89406;
      color: #fff;
    }
    &.disabled {
      &.disabled, &:hover.disabled {
        background: #f89406;
        color: #fff;
      }
    }
    &[disabled], &:hover[disabled] {
      background: #f89406;
      color: #fff;
    }
    &.disabled {
      &[disabled], &:hover[disabled] {
        background: #f89406;
        color: #fff;
      }
    }
    background: #f89406;
    color: #fff;
    &:hover {
      background: #f89406;
      color: #fff;
    }
    &.disabled {
      background: #f89406;
      color: #fff;
      &:hover {
        background: #f89406;
        color: #fff;
      }
    }
  }
}

/******************************************************************************
  Banner Set
*******************************************************************************/

.banner-set {
  border-top-width: 1px;
  padding-bottom: 16px;
  overflow: hidden;
  &:before {
    border-top: 1px solid #e1e1e1;
    content: "";
    display: block;
    left: 0;
    margin-top: -1px;
    position: absolute;
    right: 0;
  }
  &.bottom-padding {
    padding-bottom: 0;
  }
}

.load.banner-set {
  .container {
    background-image: url("../img/svg/loader.svg"), none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40px;
    height: 314px;
    overflow: hidden;
  }
  .nav-box .container, &.banner-set-mini .nav-box .container {
    background: none;
    height: auto;
  }
  .container > * {
    opacity: 0;
    filter: alpha(opacity = 0);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.container .banner-set .container {
  padding: 0;
  width: auto;
}

.banner-set {
  .container > * {
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  .banner {
    border-right-width: 1px;
    color: #7f7f7f;
    display: block;
    float: left;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    width: 292px;
    padding: 19px;
    text-decoration: none;
    -webkit-transition: color .2s linear, box-shadow .2s linear;
    transition: color .2s linear, box-shadow .2s linear;
    &:first-child {
      border-left-width: 1px;
    }
    img {
      margin: 0 0 28px 0;
      max-height: 158px;
      width: 100%;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    .title {
      color: #7f7f7f;
      font-size: 20px;
      font-weight: normal;
      line-height: 1.2;
      margin: 0;
      padding: 0 0 12px;
      text-decoration: none;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
    .description {
      height: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      color: #1e1e1e;
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
      -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
      opacity: 1;
      filter: alpha(opacity = 100);
      -webkit-transition: color .2s linear, box-shadow .2s linear;
      transition: color .2s linear, box-shadow .2s linear;
      img {
        opacity: .9;
        filter: alpha(opacity = 90);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
      .title {
        color: #1e1e1e;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
  }
  .nav-box {
    padding: 0;
    text-align: center;
    vertical-align: top;
  }
  > .container:after {
    border-top: 1px solid #e1e1e1;
    content: "";
    display: block;
    left: 0;
    margin-top: -1px;
    position: absolute;
    right: 0;
  }
  .next, .prev, .pagination {
    display: inline-block;
    margin-top: 15px;
    vertical-align: top;
  }
  .next, .prev {
    color: #ccc;
    font-size: 13px;
    height: 14px;
    line-height: 14px;
    vertical-align: top;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  .next:hover, .prev:hover {
    color: #1e1e1e;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
  .next.disabled, .prev.disabled {
    cursor: default;
    opacity: .12;
    filter: alpha(opacity = 12);
  }
  .next .glyphicon, .prev .glyphicon {
    margin: 0;
    top: 0;
    vertical-align: top;
  }
  .pagination {
    height: 14px;
    margin: 15px 7px 0;
    a {
      background: #ececec;
      &:hover {
        background: #000;
      }
      &.selected {
        background: #ccc;
      }
    }
  }
  &.banner-set-mini {
    overflow: visible;
    padding-bottom: 0;
  }
}

.load.banner-set.banner-set-mini .container {
  height: 127px;
  overflow: hidden;
  .banners {
    height: 127px;
    overflow: hidden;
  }
}

.banner-set.banner-set-mini {
  .banner {
    padding: 9px;
    width: 146px;
    img {
      margin-bottom: 14px;
    }
    .title {
      font-size: 14px;
      padding: 0;
    }
  }
  .pagination {
    margin-bottom: 15px;
  }
  &.banner-set-no-pagination {
    .nav-box {
      position: relative;
      .container {
        position: relative;
      }
    }
    .pagination {
      display: none !important;
    }
    .prev {
      left: 0;
      margin: -70.5px 0 0 -15px;
      position: absolute;
      top: 0;
    }
    .next {
      left: 0;
      margin: -70.5px 0 0 -15px;
      position: absolute;
      top: 0;
      left: auto;
      margin-left: 0;
      margin-right: -15px;
      right: 0;
    }
  }
}

.container .banner-set.banner-set-mini.banner-set-no-pagination {
  .prev {
    margin-left: -30px;
  }
  .next {
    margin-right: -30px;
  }
}

/******************************************************************************
  Main
*******************************************************************************/

#main {
  padding: 80px 0;
  &.no-padding {
    padding: 0;
  }
}

.page-header {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 0 80px;
  padding: 0;
  .title {
    font-weight: normal;
    margin: -4px 0 18px;
    padding: 0;
    position: relative;
  }
  .container {
    position: relative;
    &:before {
      background: #1e1e1e;
      bottom: 0px;
      content: "";
      display: block;
      height: 1px;
      left: 15px;
      margin: 0 0 -1px;
      position: absolute;
      width: 40px;
    }
  }
  .page-description {
    padding: 0 0 18px;
  }
}

.title-box {
  border-bottom-width: 1px;
  margin-bottom: 40px;
  position: relative;
  &.no-margin {
    margin-bottom: 0 !important;
  }
}

.no-margin {
  margin-bottom: 0 !important;
}

.title-box {
  &:before {
    background: #1e1e1e;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin: 0 0 -1px;
    position: absolute;
    width: 40px;
  }
  &.text-right:before {
    left: auto;
    right: 0;
  }
  &.text-center:before {
    left: 50%;
    margin-left: -20px;
  }
  .title {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    margin: 0 0 12px 0;
    padding: 14px 0 0;
  }
  h1.title, .h1.title {
    font-size: 24px;
  }
  .btn {
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    top: 0;
    .glyphicon {
      margin: -1px 0 0 3px;
    }
  }
  &.title-white {
    border-color: rgba(255, 255, 255, 0.3);
  }
}

.page-header.white {
  border-color: rgba(255, 255, 255, 0.3);
}

.title-box.title-white:before, .page-header.white .container:before {
  background: #fff;
}

.title-box.title-white .title {
  color: #fff;
}

/******************************************************************************
  Home Pages
*******************************************************************************/
/* Packages */

.package {
  background: #f7f7f7;
  margin: 0 auto 40px;
  max-width: 370px;
  .title {
    line-height: 32px;
    padding: 20px 20px 15px;
    a {
      color: #1e1e1e;
      font-size: 28px;
      font-weight: bold;
      line-height: 32px;
      text-decoration: none;
    }
  }
  .price-box {
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    padding: 0 20px 20px;
    .icon {
      color: #505050;
      height: 96px;
      text-align: center;
      width: 96px;
      i {
        background: none;
        font-size: 50px;
        height: auto;
        line-height: 100px;
        margin: 0;
        width: auto;
      }
    }
    .description {
      margin: 0 120px 17px 0;
    }
    .price {
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
      margin: 7px 0 0;
      span {
        font-size: 12px;
      }
    }
  }
  .bottom-box {
    border-top-width: 1px;
    overflow: hidden;
    padding: 19px 19px 20px;
    .more {
      color: #7f7f7f;
      display: block;
      float: left;
      font-size: 12px;
      line-height: 1;
      text-decoration: none;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .65;
        filter: alpha(opacity = 65);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
      span {
        font-size: 17px;
        line-height: 12px;
        margin: 0 0 0 3px;
        vertical-align: top;
      }
    }
    .rating-box {
      float: right;
    }
  }
}

/* Features */

.features-block .header-box {
  display: block;
  min-height: 30px;
  margin: 0 0 13px;
  overflow: hidden;
  .icon-box {
    background: none;
    color: #1e1e1e;
    float: left;
    height: 30px;
    margin: 0 10px 0 0;
    text-align: center;
    width: 24px;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  a.icon-box:hover {
    opacity: 0.8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  .icon-box i:before {
    font-size: 20px;
    line-height: 30px;
  }
  h6 {
    margin: 6px 0 0;
  }
}

/* Welcome block */

.content-block {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  .btn {
    + .btn {
      margin-left: 14px;
    }
    &.pull-left {
      margin: 0 10px 0 0;
    }
    &.pull-right {
      margin: 0 0 0 10px;
    }
  }
}

/* Services */

.service {
  .icon {
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    color: #1e1e1e;
    display: block;
    height: 70px;
    float: left;
    margin: 0 30px 0 0;
    text-align: center;
    text-decoration: none;
    width: 70px;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    i:before {
      font-size: 30px;
      line-height: 70px;
    }
  }
  .title {
    margin: 2px 0 17px;
  }
  a:not(.btn) {
    color: #1e1e1e;
    display: inline-block;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.big-services-box a:not(.btn), .features-block a:not(.btn) {
  color: #1e1e1e;
  display: inline-block;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.service a:hover, .big-services-box a:hover, .features-block a:hover {
  text-decoration: none;
  opacity: 0.8;
  filter: alpha(opacity = 80);
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.white {
  .service a, .big-services-box a {
    color: #fff;
  }
}

.service .text-small {
  overflow: hidden;
}

.big-services-box {
  margin-bottom: 40px;
  text-align: center;
}

.big-icon {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  color: #1e1e1e;
  display: block;
  height: 100px;
  margin: 0 auto 20px;
  text-align: center;
  width: 100px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:visited {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    color: #1e1e1e;
    display: block;
    height: 100px;
    margin: 0 auto 20px;
    text-align: center;
    width: 100px;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  i:before {
    font-size: 50px;
    line-height: 100px;
  }
}

a.big-icon:hover {
  opacity: .8;
  filter: alpha(opacity = 80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.big-icon.pull-left {
  margin: 0 20px 20px 0;
}

/* Why Choose Us */

.why-choose {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    font-size: 13px;
    line-height: 20px;
    padding: 11px 0 8px 23px;
    position: relative;
    &:before {
      content: "✓";
      display: block;
      font-size: 16px;
      left: 2px;
      position: absolute;
      top: 11px;
    }
    + li {
      border-top-width: 1px;
    }
  }
}

/* Promo Partners */

.promo-partners {
  font-size: 13px;
  line-height: 20px;
  .col-md-2 {
    margin-bottom: 37px;
  }
}

/* Latest Posts */

.latest-posts {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    overflow: hidden;
    padding: 22px 0;
    + li {
      border-top-width: 1px;
    }
  }
}

.latest-postst li:first-child {
  padding-top: 0;
}

.latest-posts li {
  &:last-child {
    padding-bottom: 0;
  }
  .image {
    float: left;
    height: 84px;
    margin: 0 25px 0 0;
    width: 84px;
  }
  .meta {
    color: #7f7f7f;
    font-size: 11px;
    line-height: 18px;
    margin: 0 0 10px;
  }
  .description {
    overflow: hidden;
  }
  a {
    color: #1e1e1e;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
  }
}

.latest-posts-white li {
  .meta {
    color: rgba(255, 255, 255, 0.6);
  }
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  border-color: rgba(255, 255, 255, 0.3);
}

/******************************************************************************
  Carousel
*******************************************************************************/

.carousel {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    display: block;
    float: left;
  }
  .col-md-1 {
    max-width: 80px;
  }
  .col-md-2 {
    max-width: 161px;
  }
  .col-md-3 {
    max-width: 242px;
  }
  .col-md-4 {
    max-width: 323px;
  }
  .col-md-5 {
    max-width: 404px;
  }
  .col-md-6 {
    max-width: 485px;
  }
  .col-md-7 {
    max-width: 565px;
  }
  .col-md-8 {
    max-width: 646px;
  }
  .col-md-9 {
    max-width: 727px;
  }
  .col-md-10 {
    max-width: 808px;
  }
  .col-md-11 {
    max-width: 889px;
  }
  .col-md-12 {
    max-width: 970px;
  }
}

.carousel-links a {
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:hover {
    opacity: .8;
    filter: alpha(opacity = 80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.carousel [class*="span"].pull-right {
  float: right;
}

.load.carousel-box {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  > * {
    opacity: 0;
    filter: alpha(opacity = 0);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
}

.carousel-box > * {
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.load.carousel-box .carousel > {
  a + a, div + div {
    visibility: hidden;
  }
}

.caroufredsel_wrapper {
  margin-bottom: 0 !important;
}

.carousel-box {
  .next, .prev {
    background-color: #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #fbfbfb;
    display: block;
    float: right;
    height: 40px;
    line-height: 40px;
    margin: 0 0 0 10px;
    text-align: center;
    text-decoration: none;
    width: 40px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }
  .next:hover, .prev:hover {
    background-color: #000;
    color: #efefef;
    text-decoration: none;
    -webkit-transition: color .2s linear, background-color .2s linear;
    transition: color .2s linear, background-color .2s linear;
  }
  .next:hover polygon, .prev:hover polygon {
    fill: #efefef;
  }
  .next svg {
    margin-left: 2px;
  }
  .prev svg {
    margin-right: 2px;
  }
  .next *, .prev * {
    vertical-align: middle;
  }
  &.no-nav {
    .next, .prev {
      display: none;
    }
  }
  .pagination {
    margin-bottom: 0;
    text-align: center;
    width: 100%;
  }
  &.no-pagination .pagination {
    display: none;
  }
}

.overflow {
  overflow: hidden;
}

.carousel-box .pagination a {
  background: #ececec;
  &:hover {
    background: #000;
  }
  &.selected {
    background: #ccc;
    background: rgba(0, 0, 0, 0.3);
  }
}

/******************************************************************************
  Products
*******************************************************************************/

.product {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  float: none;
  margin: 0 0 40px;
  min-width: 240px;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: top;
  &.product-mini {
    min-width: 180px;
  }
}

.carousel .product.product-mini {
  width: 200px;
}

.product.rotation, .employee.rotation {
  overflow: visible;
}

.double-product {
  min-width: 240px;
  .product.rotation {
    min-width: 210px;
  }
}

.carousel {
  .product.rotation {
    margin-top: 40px;
  }
  .double-product .product.rotation + .product.rotation {
    margin-top: 0;
  }
}

.product {
  .default {
    background: #f7f7f7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
  }
  .product-image {
    display: block;
    line-height: 0;
    margin: 0;
  }
  .limit-offer {
    background: #1e1e1e;
    background: rgba(30, 30, 30, 0.9);
    bottom: 72px;
    color: #fff;
    font-size: 12px;
    left: 0;
    line-height: 1;
    padding: 10px 0 13px;
    position: absolute;
    right: 0;
    width: auto !important;
    .title {
      margin: 0 0 3px;
    }
    .county-days-wrapper, .county-hours-wrapper, .county-minutes-wrapper, .county-seconds-wrapper {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      display: inline-block;
      font-size: 30px;
      height: 26px;
      overflow: hidden;
      padding: 0 7px 2px 6px;
      position: relative;
      text-align: center;
    }
    .county-days, .county-hours, .county-minutes, .county-seconds {
      font-size: 30px;
      left: 0;
      right: 0;
    }
    .loaded {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      padding-left: 7px;
      padding-right: 6px;
      position: absolute;
    }
    .county-days-wrapper span, .county-hours-wrapper span, .county-minutes-wrapper span, .county-seconds-wrapper span {
      opacity: 1 !important;
    }
    .county-label-days, .county-label-hours, .county-label-minutes, .county-label-seconds {
      color: rgba(255, 255, 255, 0.7);
      display: inline-block;
      text-align: center;
      text-transform: lowercase;
    }
    .county-days-wrapper:before, .county-hours-wrapper:before, .county-minutes-wrapper:before {
      background: #fff;
      background: rgba(255, 255, 255, 0.4);
      content: "";
      display: block;
      height: 11px;
      position: absolute;
      right: 0;
      top: 11px;
      width: 1px;
    }
  }
}

.county-label-days i, .county-label-hours i, .county-label-minutes i, .county-label-seconds i {
  font-style: normal;
}

.product {
  .limit-offer {
    .county-label-days i, .county-label-hours i, .county-label-minutes i, .county-label-seconds i {
      display: none;
    }
  }
  .not-rotation-actions {
    background: #1e1e1e;
    background: rgba(30, 30, 30, 0.9);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 9px 10px;
    position: absolute;
    text-align: center;
    -webkit-transition: bottom .2s linear;
    transition: bottom .2s linear;
    z-index: 1;
  }
  &:hover .not-rotation-actions {
    bottom: 72px;
    -webkit-transition: bottom .2s linear;
    transition: bottom .2s linear;
  }
  .not-rotation-actions + .product-description .limit-offer {
    -webkit-transition: bottom .2s linear;
    transition: bottom .2s linear;
  }
  &:hover .not-rotation-actions + .product-description .limit-offer {
    bottom: 144px;
    -webkit-transition: bottom .2s linear;
    transition: bottom .2s linear;
  }
  .product-description {
    background: #f2f2f2;
    border-top: 1px solid #e1e1e1;
    color: #505050;
    display: table;
    font-size: 13px;
    height: 72px;
    line-height: 18px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
    .vertical {
      display: table-cell;
      height: 72px;
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: middle;
    }
  }
  .product-name {
    color: #1e1e1e;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    margin: 0 0 4px;
    a {
      text-decoration: none;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: 0.8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
}

.products-tab .product .product-name {
  text-transform: none;
}

.product {
  .price {
    font-weight: bold;
  }
  .product-hover {
    background: #1e1e1e;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    bottom: 0;
    color: #7f7f7f;
    display: none;
    font-size: 12px;
    line-height: 20px;
    left: 0;
    padding: 16px 20px 90px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto !important;
  }
}

.col-md-1.product .product-hover, .col-md-2.product .product-hover, .col-md-3.product .product-hover, .col-md-4.product .product-hover, .col-md-5.product .product-hover, .col-md-6.product .product-hover, .col-md-7.product .product-hover, .col-md-8.product .product-hover, .col-md-9.product .product-hover, .col-md-10.product .product-hover, .col-md-11.product .product-hover {
  left: 15px;
  right: 15px;
}

.product {
  &:hover .product-hover {
    display: block;
    -webkit-animation: fadeIn 0.8s;
    animation: fadeIn 0.8s;
  }
  .product-hover {
    .product-name {
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
      margin: 0 0 7px;
      a {
        color: #fff;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
        &:hover {
          opacity: 0.8;
          filter: alpha(opacity = 80);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
          text-decoration: none;
        }
      }
    }
    .price {
      font-size: 18px;
      font-weight: normal;
      line-height: 1;
      margin: 0 0 24px;
    }
    .product-image {
      background: #f7f7f7;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      display: block;
      height: 70px;
      float: right;
      line-height: 0;
      margin: 4px 0 0 5px;
      padding: 0;
      position: relative;
      width: 70px;
      z-index: 1;
      img {
        background: #f7f7f7;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
    }
    ul {
      margin: 0;
      max-height: 120px;
      overflow: hidden;
      padding: 0;
      li {
        background-image: url("../img/svg/check-icon.svg"), none;
        background-repeat: no-repeat;
        background-position: 0 5px;
        line-height: 20px;
        list-style: none;
        padding: 0 0 0 15px;
        position: relative;
      }
    }
    .actions {
      bottom: 20px;
      left: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
  .actions {
    a {
      background: #fff;
      display: inline-block;
      height: 54px;
      margin: 0 -2px;
      padding-top: 19px;
      text-align: center;
      text-decoration: none;
      vertical-align: top;
      width: 54px;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      + a {
        border-left-width: 1px;
      }
    }
    .add-cart {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }
    .add-compare {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
    a {
      &:hover {
        background: #f2f2f2;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      i {
        color: #1e1e1e;
        line-height: 57px;
        &:before {
          font-size: 16px;
          width: auto;
        }
      }
    }
  }
  &.product-danger {
    .product-hover, .limit-offer, .not-rotation-actions {
      background-color: #c10841;
    }
    .limit-offer, .not-rotation-actions {
      background-color: rgba(212, 7, 70, 0.9);
    }
    .actions a svg path {
      fill: #c10841;
    }
  }
  &.product-success {
    .product-hover, .limit-offer, .not-rotation-actions {
      background-color: #84a200;
    }
    .limit-offer, .not-rotation-actions {
      background-color: rgba(132, 162, 0, 0.9);
    }
    .actions a svg path {
      fill: #84a200;
    }
  }
  &.product-info {
    .product-hover, .limit-offer, .not-rotation-actions {
      background-color: #01a5db;
    }
    .limit-offer, .not-rotation-actions {
      background-color: rgba(1, 165, 219, 0.9);
    }
    .actions a svg path {
      fill: #01a5db;
    }
  }
  &.product-warning {
    .product-hover, .limit-offer, .not-rotation-actions {
      background-color: #f89406;
    }
    .limit-offer, .not-rotation-actions {
      background-color: rgba(248, 148, 6, 0.9);
    }
    .actions a svg path {
      fill: #f89406;
    }
  }
}

.rotation {
  background: none;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
  .default, .front-end {
    overflow: hidden;
    position: relative;
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-transform: rotateX(0deg) rotateY(0deg);
    -moz-transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
    transform: rotateY(0deg);
    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    z-index: 24;
  }
  .default {
    background: #f7f7f7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  &.hover {
    .default, .front-end {
      z-index: 35;
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      transform: rotateY(180deg);
      -webkit-transition-delay: .2s;
      -moz-transition-delay: .2s;
      transition-delay: .2s;
    }
  }
  .product-hover, .back-end {
    display: block;
    height: inherit;
    -webkit-transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-transform: rotateY(-180deg);
    -moz-transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
    transform: rotateY(-180deg);
    -moz-transition: all .6s ease-in-out;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    width: 100%;
    z-index: 13;
  }
  &.hover {
    .product-hover, .back-end {
      z-index: 46;
      -webkit-transform: rotateX(0deg) rotateY(0deg);
      -moz-transform: rotateX(0deg) rotateY(0deg);
      transform: rotateY(0deg);
      -webkit-transition-delay: .2s;
      -moz-transition-delay: .2s;
      transition-delay: .2s;
    }
  }
}

.list .product {
  background: none;
  display: block;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  width: auto;
  .product-image {
    background: #f7f7f7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .product-name {
    color: #1e1e1e;
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
    margin: 0 0 14px;
  }
}

.reviews-box {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 14px;
}

.list .product .reviews-box {
  margin: 0 0 16px;
}

.reviews-box {
  .rating-box {
    float: left;
    margin: 0 20px 0 0;
  }
  .separator {
    margin: 0 4px;
  }
  .add-review {
    color: #7f7f7f;
    text-decoration: none;
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
}

.list .product {
  .excerpt {
    color: #7f7f7f;
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 15px;
  }
  .price-box {
    margin: 0 0 30px;
  }
  .price {
    font-size: 24px;
    font-weight: normal;
  }
  .price-old {
    font-size: 18px;
  }
  .actions a {
    background: #f2f2f2;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    &:hover {
      background: #1e1e1e;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
    path {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
    &:hover path {
      fill: #fff;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
  }
}

/******************************************************************************
  Products Tab
*******************************************************************************/

.products-tab {
  .nav-tabs > {
    li > a {
      color: #7f7f7f;
      font-size: 18px;
      line-height: 33px;
    }
    .active > a {
      color: #7f7f7f;
      font-weight: normal;
      &:hover, &:focus {
        color: #7f7f7f;
        font-weight: normal;
      }
    }
  }
  .tab-content > .tab-pane {
    overflow: hidden;
    padding: 40px 0 0;
  }
  .product.rotation {
    overflow: visible;
  }
}

/******************************************************************************
  Manufactures
*******************************************************************************/

.manufactures .make-wrapper {
  float: left;
  height: 164px;
  margin: 0 15px;
  width: 164px;
}

.manufactures-list {
  text-align: center;
  .make-wrapper {
    display: inline-block;
    float: none;
    margin-bottom: 30px;
  }
}

.manufactures {
  .make {
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: table-cell;
    height: 164px;
    line-height: 0;
    max-height: 100%;
    opacity: .6;
    filter: alpha(opacity = 60);
    padding: 0 20px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: background .2s linear, border .2s linear, opacity .2s linear;
    transition: background .2s linear, border .2s linear, opacity .2s linear;
  }
  a.make:hover {
    background: #f2f2f2;
    border: 1px solid #ccc;
    opacity: 1;
    filter: alpha(opacity = 100);
    -webkit-transition: background .2s linear, border .2s linear, opacity .2s linear;
    transition: background .2s linear, border .2s linear, opacity .2s linear;
  }
}

/******************************************************************************
  About Us
*******************************************************************************/

.about-us {
  font-size: 13px;
  strong {
    font-size: 14px;
  }
}

/******************************************************************************
  Clients
*******************************************************************************/

.client {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 20px;
  max-width: 100%;
  text-align: center;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  width: 200px;
  &:hover {
    border-color: #e1e1e1;
    -webkit-transition: border-color .2s ease-in;
    transition: border-color .2s ease-in;
  }
  + .tooltip {
    font-size: 14px;
    z-index: 800;
    .tooltip-inner {
      padding: 10px;
    }
  }
}

.carousel .client {
  margin-bottom: 0;
}

/******************************************************************************
  News
*******************************************************************************/

.news .news-item {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 35px;
  .time {
    color: #7f7f7f;
    margin-bottom: 2px;
  }
  .title {
    font-size: 14px;
    margin-bottom: 7px;
    a {
      color: #1e1e1e;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      &:hover {
        opacity: .75;
        filter: alpha(opacity = 75);
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
  }
  .more {
    color: #7f7f7f;
    margin: 5px 0 0;
    text-decoration: none;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      vertical-align: top;
    }
  }
}

/******************************************************************************
  Category
*******************************************************************************/

#catalog .category-img {
  background: #f2f2f2;
  margin: 0 0 80px;
  position: relative;
  img {
    width: 100%;
  }
  .description {
    background: rgb(30, 30, 30);
    background: rgba(30, 30, 30, 0.7);
    bottom: 0;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    left: 0;
    padding: 35px 38px;
    position: absolute;
    right: 0;
  }
}

.toolbar {
  .grid-list {
    float: left;
    .grid, .list {
      background-color: #1e1e1e;
      background-color: rgba(30, 30, 30, 0.7);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      display: block;
      float: left;
      height: 24px;
      margin: 0 7px 0 0;
      text-align: center;
      width: 24px;
    }
    a {
      &.grid, &.list {
        background: #ccc;
        -webkit-transition: background .2s linear;
        transition: background .2s linear;
      }
      &.grid:hover, &.list:hover {
        background: #1e1e1e;
        -webkit-transition: background .2s linear;
        transition: background .2s linear;
      }
    }
    .grid .glyphicon, .list .glyphicon {
      color: #fff;
      font-size: 12px;
      margin: 5px 0 0;
      top: 0;
      vertical-align: top;
    }
  }
  .sort-catalog {
    float: left;
    margin: 0 17px 0 13px;
    .dropdown-toggle {
      background-color: #1e1e1e;
      background-color: rgba(30, 30, 30, 0.7);
      color: #ccc;
      z-index: 26;
      span {
        color: #f7f7f7;
      }
    }
    .open .dropdown-toggle span {
      color: #7f7f7f;
    }
  }
}

.btn-group.btn-select {
  .dropdown-menu {
    z-index: 25;
  }
  &.open .btn.dropdown-toggle:after {
    z-index: 27;
  }
}

.toolbar {
  .up-down {
    background-color: #1e1e1e;
    background-color: rgba(30, 30, 30, 0.7);
    background-image: url("../img/up.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 24px;
    margin: 0 0 0 3px;
    padding: 0;
    vertical-align: top;
    width: 22px;
    &.active {
      background-color: #1e1e1e;
      background-color: rgba(30, 30, 30, 0.7);
      background-image: url("../img/down.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  .per-page {
    color: #7f7f7f;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 0 3px;
  }
  .price-regulator {
    color: #7f7f7f;
    font-size: 12px;
    width: 323px;
    b {
      color: #1e1e1e;
      display: inline-block;
      margin-top: 5px;
    }
    .layout-slider {
      float: right;
      margin-top: 5px;
      width: 270px;
    }
  }
}

.jslider .jslider-pointer {
  background: none;
  height: 12px;
  margin: 0 0 0 -4px;
  width: 8px;
  svg {
    vertical-align: top;
  }
}

.toolbar {
  .grid-list, .sort-catalog {
    margin-bottom: 20px;
  }
}

#catalog .products {
  clear: both;
  margin-top: 20px;
  &.grid {
    text-align: center;
  }
}

.pagination-box {
  margin: 40px 0 0;
  .pagination {
    margin: 0 10px 0 0;
  }
}

/******************************************************************************
  Product View
*******************************************************************************/

.zoomWindow {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.zoomContainer {
  height: auto !important;
}

.product-page {
  .image-box {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    .general-img {
      background: #f7f7f7;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      overflow: hidden;
    }
    .thumblist-box {
      margin: 30px 0 0;
      max-height: 100px;
      overflow: hidden;
      padding: 0 54px;
      position: relative;
      &.load {
        opacity: 0;
      }
      .prev {
        background-color: #ccc;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        color: #fbfbfb;
        display: block;
        float: right;
        height: 40px;
        line-height: 40px;
        left: 0;
        margin: -20px 0 0 0;
        position: absolute;
        text-align: center;
        top: 50%;
        text-decoration: none;
        width: 40px;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      .next {
        background-color: #ccc;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        color: #fbfbfb;
        display: block;
        float: right;
        height: 40px;
        line-height: 40px;
        left: 0;
        margin: -20px 0 0 0;
        position: absolute;
        text-align: center;
        top: 50%;
        text-decoration: none;
        width: 40px;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
        left: auto;
        right: 0;
      }
      .prev *, .next * {
        vertical-align: middle;
      }
      .prev:hover, .next:hover {
        background-color: #000;
        color: #efefef;
        text-decoration: none;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      .prev polygon, .next:hover polygon {
        fill: #efefef;
      }
    }
    #thumblist a {
      background: #f7f7f7;
      display: block;
      float: left;
      height: 100px;
      margin: 0 9px;
      width: 100px;
      img {
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }
      &:hover img {
        opacity: .8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }
    }
  }
  .reviews-box {
    margin: 0 0 38px;
  }
  .description {
    color: #7f7f7f;
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 32px;
  }
  .col-md-7 .price-box {
    border-bottom-width: 1px;
    font-size: 48px;
    line-height: 1;
    margin: 0 0 40px;
    padding: 0 0 35px;
    .price-old {
      font-size: 30px;
    }
  }
}

.price-box {
  .emergence {
    .price {
      display: none;
    }
    .emergence-price {
      color: #7f7f7f;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .price-label {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 14px;
  }
}

.product-page {
  .add-cart-form {
    margin: 0;
    .add-cart {
      font-weight: bold;
      margin: 0 17px 40px 0;
      min-width: 290px;
      text-transform: uppercase;
    }
    .number {
      display: inline-block;
      margin-bottom: 40px;
      vertical-align: top;
      label {
        color: #7f7f7f;
        font-weight: normal;
        line-height: 54px;
        margin: 0 7px 0 0;
        vertical-align: top;
      }
      input {
        color: #7f7f7f;
        display: inline-block;
        height: 54px;
        padding: 15px 12px;
        text-align: center;
        vertical-align: top;
        width: 80px;
      }
      .regulator {
        display: inline-block;
        vertical-align: top;
        a {
          background: url("../img/arrow-up.png") 50% 50% no-repeat #ccc;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          display: block;
          height: 24px;
          width: 24px;
          -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
        }
        .number-down {
          background: url("../img/arrow-down.png") 50% 50% no-repeat #ccc;
          margin: 6px 0 0 0;
        }
        a:hover {
          background-color: #1e1e1e;
          background-color: rgba(30, 30, 30, 0.5);
          -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
        }
      }
    }
  }
  .availability-sku {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 36px;
    .availability span {
      color: #2ca073;
    }
  }
  .product-options-border {
    border-top: 1px solid #1e1e1e;
    padding: 40px 0 0;
  }
  .product-options {
    font-size: 12px;
    line-height: 20px;
    .selectBox, .select {
      margin: 0 0 10px;
    }
    .selectBox:last-child, .select:last-child {
      margin: 0;
    }
  }
  .product-options-table table {
    margin: 0 0 35px;
    th, td {
      line-height: 20px;
      padding: 16px 25px 13px;
      text-align: left;
      vertical-align: middle;
    }
    .price {
      text-align: right;
      width: 150px;
    }
    td.price {
      font-size: 16px;
    }
    .qty {
      text-align: right;
      width: 70px;
    }
    td.qty {
      padding: 5px 25px;
    }
    .qty input {
      margin: 0;
      width: 50px;
    }
  }
  .product-options-samples {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 30px;
    .title {
      display: block;
      font-size: 14px;
      line-height: 1;
      margin: 0 0 14px;
    }
    label {
      line-height: 20px;
      margin: 0;
      .new-checkbox {
        margin-top: 1px;
      }
    }
    .required-box {
      font-size: 11px;
      line-height: 1;
      text-align: right;
    }
    .required {
      font-size: 11px;
      line-height: 1;
    }
  }
  .product-price-configured {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 30px;
  }
  .col-md-7 .actions {
    margin: 0 0 40px;
    a {
      background: #f2f2f2;
      display: inline-block;
      height: 54px;
      margin: 0 -2px;
      padding-top: 19px;
      text-align: center;
      text-decoration: none;
      width: 54px;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      + a {
        border-left-width: 1px;
      }
    }
    .add-wishlist {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }
    .add-compare {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
    a {
      &:hover {
        background: #1e1e1e;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      path {
        fill: #1e1e1e;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
      &:hover path {
        fill: #fff;
        -webkit-transition: all .2s linear;
        transition: all .2s linear;
      }
    }
  }
}

.product-bottom {
  padding: 40px 0 0;
  overflow: hidden;
}

.product-options-detail {
  background: #f2f2f2;
  font-size: 12px;
  line-height: 20px;
  padding: 27px 30px 30px;
  .detail-box {
    margin: 0 0 25px;
  }
  .pull-right.required {
    font-size: 11px;
    line-height: 18px;
  }
  .title {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 11px;
  }
  .checkbox, .radio {
    line-height: 20px;
    margin: 0;
  }
  .checkbox .new-checkbox, .radio .new-radio {
    margin-top: 0;
  }
  .qty {
    margin: 15px 0 0;
    label {
      float: left;
      font-size: 12px;
      line-height: 40px;
      margin: 0 12px 0 0;
    }
    input {
      margin: 0;
      width: 48px;
    }
  }
  .price {
    font-size: 48px;
    line-height: 1;
  }
  .add-cart {
    float: right;
    font-weight: bold;
    margin: -14px 17px 0 0;
    min-width: 290px;
    text-transform: uppercase;
  }
}

.product-page .product-tab {
  margin: 80px 0;
  .tab-pane {
    padding: 36px 0 0;
    .title {
      margin: 0 0 18px;
    }
  }
}

/* Related Products */

.product-bottom .related-products {
  .select-all {
    margin: -4px 0 13px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 24px 0;
    + li {
      border-top-width: 1px;
    }
    .product-image {
      border: 1px solid #f5f5f5;
      background: #f7f7f7;
      float: left;
      height: 64px;
      margin: 0 15px 1px 0;
      padding: 2px;
      width: 64px;
    }
    .product-name {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      margin: 0 0 4px;
    }
    .price-box {
      font-size: 16px;
      line-height: 22px;
    }
    .button-box {
      margin: 10px 0 0;
      overflow: hidden;
      text-align: right;
      label, .wishlist {
        color: #ccc;
        float: right;
        margin: 0 0 0 10px;
      }
      .new-checkbox {
        margin-top: 0;
      }
      .wishlist {
        svg path {
          fill: #ccc;
          -webkit-transition: fill .2s linear;
          transition: fill .2s linear;
        }
        &:hover svg path {
          fill: #1e1e1e;
          -webkit-transition: fill .2s linear;
          transition: fill .2s linear;
        }
      }
    }
  }
}

.commentlist {
  list-style: none;
  margin: 0;
  padding: 0 0 33px;
  li {
    padding: 0 0 25px;
    + li {
      border-top-width: 1px;
      padding: 25px 0 0;
    }
  }
  .meta {
    color: #7f7f7f;
    font-size: 11px;
    line-height: 18px;
    margin: 0 0 4px;
  }
}

.evaluation-box {
  overflow: hidden;
}

.evaluation {
  float: left;
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  margin: 0 10px 14px 0;
  + .evaluation {
    border-left-width: 1px;
    padding-left: 10px;
  }
  .rating-box {
    display: inline-block;
    margin: 0 0 0 7px;
  }
  .add-rating {
    float: left;
    margin: 0 0 0 10px;
    overflow: hidden;
    label {
      float: left;
      height: 30px;
      margin: 0 1px;
      padding: 0 0 0 14px;
      position: relative;
      width: 2px;
      .new-radio {
        margin: 0px 0 0 -14px;
      }
      .number {
        bottom: 0;
        color: #7f7f7f;
        font-size: 11px;
        line-height: 1;
        left: 3px;
        position: absolute;
      }
    }
  }
}

.comments-form {
  .evaluation-box .evaluation {
    margin-bottom: 30px;
  }
  .row {
    label {
      font-weight: bold;
      margin: 0 0 4px;
    }
    textarea {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      height: 128px;
      margin: 0 0 10px;
      width: 100%;
    }
    i {
      color: #999;
      font-size: 12px;
    }
    .btn {
      margin: 34px 0 0 0;
    }
  }
}

/******************************************************************************
  Login Page
*******************************************************************************/

#main.login-register {
  overflow: hidden;
  padding-bottom: 40px;
}

.form-box.forgot-form {
  margin-bottom: 0;
}

.info-box {
  margin-bottom: 40px;
  padding: 27px 0 0;
  .title {
    font-weight: normal;
    margin: 0 0 17px;
  }
}

.form-box .title {
  font-weight: normal;
  margin: 0 0 17px;
}

.info-box .descriptions {
  margin: 0 0 26px;
}

.form-box {
  .descriptions {
    margin: 0 0 26px;
  }
  background: #f2f2f2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0 0 40px 0;
  padding: 27px 30px 25px;
  .checkbox {
    font-size: 12px;
    margin: 0px 0 28px;
    .new-checkbox {
      margin-top: 0;
    }
  }
  .buttons-box {
    font-size: 11px;
    line-height: 1.5;
    .btn {
      margin: 3px 8px 5px 0;
    }
    .required {
      font-size: 11px;
      margin: 3px 8px 5px 0;
    }
  }
}

.buttons-box .required {
  font-size: 11px;
}

.form-box {
  .buttons-box .forgot {
    display: inline-block;
    margin: 3px 8px 5px 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
  .form-group {
    position: relative;
  }
  .help-block {
    bottom: -20px;
    margin: 0;
    position: absolute;
  }
}

/******************************************************************************
  Login Page 2
*******************************************************************************/

.page-login-promo {
  .page-box {
    display: table;
    height: 100% !important;
    width: 100%;
  }
  .header {
    min-height: 1px;
    position: absolute;
    width: 100%;
  }
  #main {
    display: table-cell;
    padding: 134px 0 30px;
    vertical-align: middle;
  }
  .form-box {
    margin: 0;
    .title {
      small {
        color: #c1c1c2;
        font-size: 24px;
      }
      a {
        color: #c1c1c2;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
          filter: alpha(opacity = 80);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
      }
    }
    form {
      margin: 0;
    }
  }
  .rotation {
    position: relative;
  }
  .front-end, .back-end {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .form-content {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
  .back-end {
    left: 0;
    position: absolute;
    top: 0;
  }
}

/******************************************************************************
  Shopping Cart
*******************************************************************************/

.shopping-cart-table.table {
  border-bottom-width: 0;
  margin: 0;
  thead th {
    padding: 16px 30px 12px;
    text-align: left;
    vertical-align: top;
  }
  tbody td {
    font-size: 12px;
    line-height: 20px;
    padding: 30px;
  }
  .td-images {
    width: 131px;
  }
  .product-image {
    background: #f2f2f2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    width: 70px;
  }
  .td-name {
    text-align: left;
    .product-name {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
    }
  }
  .td-edit {
    padding-left: 20px;
    padding-right: 20px;
    width: 92px;
  }
  .td-price {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    width: 112px;
  }
  .td-qty {
    min-width: 48px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    width: 100px;
  }
  td.td-qty {
    padding-top: 19px;
  }
  .td-qty input {
    margin: 0;
    min-width: 40px;
    text-align: center;
  }
  .td-total {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    width: 102px;
  }
  .td-remove {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    width: 60px;
  }
}

.shopping-cart-table-button.table {
  border-bottom-width: 1px;
  border-right-width: 0;
  margin: 0 0 40px;
  tbody td {
    border-left-width: 0;
    border-top-width: 0;
    height: 49px;
    padding: 0;
    text-align: left;
    vertical-align: middle;
  }
  a {
    line-height: 22px;
    i {
      font-size: 17px;
      line-height: 20px;
      vertical-align: top;
    }
  }
  .update {
    float: right;
    i {
      font-size: 12px;
    }
  }
}

#car-bottom {
  margin-bottom: 40px;
  .car-bottom-box {
    font-size: 12px;
    line-height: 18px;
    margin: 0 auto 40px;
    max-width: 350px;
    padding: 27px 30px 30px;
    h5 {
      margin: 0 0 17px;
    }
    p {
      line-height: 18px;
      margin: 0 0 18px;
    }
    label {
      line-height: 20px;
    }
    .btn {
      margin: 8px 0 0;
    }
  }
  .total {
    padding-bottom: 26px;
    text-align: right;
    table {
      margin: 0 0 32px auto;
      td {
        font-size: 13px;
        height: 35px;
        line-height: 24px;
        padding: 0 0 0 5px;
        vertical-align: top;
      }
      .price {
        font-size: 18px;
      }
      .tr-total {
        td {
          font-size: 18px;
          line-height: 30px;
          &:first-child {
            padding-right: 12px;
          }
        }
        .price {
          font-size: 30px;
        }
      }
    }
    .checkout {
      font-weight: bold;
      margin: 0 0 19px;
      text-transform: uppercase;
    }
  }
}

/******************************************************************************
  Checkout
*******************************************************************************/

#checkoutsteps {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    margin: 0 0 11px;
  }
  .step-title {
    background: #f2f2f2;
    border: 1px solid #e1e1e1;
    color: #1e1e1e;
    display: block;
    height: 50px;
    padding: 0 18px;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &.collapsed {
      border-color: #f2f2f2;
      color: #7f7f7f;
    }
  }
  a.step-title:hover {
    opacity: 0.85;
    filter: alpha(opacity = 85);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  .step-title {
    .number {
      color: #7f7f7f;
      float: right;
      font-size: 16px;
      line-height: 49px;
    }
    h6 {
      font-size: 16px;
      font-weight: normal;
      line-height: 49px;
      margin: 0;
    }
  }
  .step-content {
    border: 1px solid #e1e1e1;
    border-top: 0 none;
    font-size: 12px;
    line-height: 20px;
    padding: 22px 18px 19px;
    label {
      font-size: 12px;
      line-height: 22px;
    }
    .radio, .checkbox {
      line-height: 18px;
    }
    .radio .new-radio, .checkbox .new-checkbox {
      margin-top: 0;
    }
    .title {
      border-bottom: 1px solid #e1e1e1;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      margin: 0 0 17px;
      padding: 0 0 10px;
      position: relative;
      &:before {
        background: #1e1e1e;
        bottom: 0px;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin: 0 0 -1px;
        position: absolute;
        width: 40px;
      }
    }
    .register-radio {
      margin: 0 0 22px 39px;
    }
    ul {
      list-style-type: disc;
    }
    .col-1 .buttons-box {
      margin: 25px 0 0 0;
    }
    input.last {
      margin-bottom: 14px;
    }
    .buttons-box {
      font-size: 11px;
      line-height: 1.5;
      margin-top: 6px;
      overflow: hidden;
      padding-top: 10px;
      text-align: right;
      .btn {
        float: left;
        margin: -10px 10px 0 0;
      }
      .required {
        display: inline-block;
        font-size: 11px;
        margin-left: 12px;
      }
    }
  }
}

.sidebar.checkout-progress {
  header {
    margin: 0 0 13px;
  }
  .progress-list {
    margin: 0;
    li {
      color: #7f7f7f;
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      padding: 12px 0 7px;
      + li {
        border-top-width: 1px;
      }
      a {
        color: #7f7f7f;
        float: right;
      }
      &.active {
        color: #1e1e1e;
        font-weight: bold;
        a {
          display: none;
        }
      }
    }
  }
}

/******************************************************************************
  My Account
*******************************************************************************/

.my-account {
  &.margin-top {
    margin-top: -16px;
  }
  .subtitle {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .info-box {
    margin-bottom: 40px;
  }
  .buttons-box .required {
    float: right;
    margin: 12px 0 0 10px;
  }
  .pagination-text {
    color: #999;
    font-size: 12px;
  }
  .btn.margin-top {
    margin-top: 10px;
  }
}

/******************************************************************************
  Compare Product
*******************************************************************************/

#compare-table {
  border: 0 none;
  margin: 0;
  tr {
    th:last-child, td:last-child {
      border-right-width: 1px;
    }
  }
  .remove td {
    border-width: 0;
    height: 26px;
    padding: 0;
    &:last-child {
      border-right-width: 0;
    }
  }
  .remove-bottom td {
    border-top-width: 1px;
    vertical-align: bottom;
  }
  th, td {
    vertical-align: top;
  }
  th {
    background: #f7f7f7;
  }
  td {
    font-size: 12px;
    line-height: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .first {
    font-size: 13px;
    min-width: inherit;
    padding: 19px 24px;
    text-align: left;
    width: 190px;
  }
  .product-name {
    color: #1e1e1e;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
  .product-image {
    background: #f2f2f2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: 100px;
  }
  .price-old {
    font-size: 11px;
    margin: 0 0 3px;
    text-decoration: line-through;
    &:after {
      display: none;
    }
  }
  .price {
    font-size: 16px;
  }
  .rating-box {
    margin: 0 auto;
  }
  .description td {
    text-align: left;
  }
}

/******************************************************************************
  About Us
*******************************************************************************/

.employee {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  float: none;
  margin: 0 0 40px;
  min-width: 260px;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: top;
}

.carousel .employee {
  margin-top: 40px;
}

.employee {
  .image {
    background: #f2f2f2;
    display: block;
    line-height: 0;
    margin: 0;
    > img {
      width: 100%;
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
    }
  }
  .description {
    background: #f2f2f2;
    border-top-width: 1px;
    color: #505050;
    display: table;
    font-size: 13px;
    height: 72px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    .vertical {
      display: table-cell;
      height: 72px;
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: middle;
    }
  }
  .name {
    color: #1e1e1e;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    margin: 0 0 4px;
  }
  .role {
    font-size: 11px;
  }
  .employee-hover {
    background: #1e1e1e;
    background: rgba(30, 30, 30, 0.9);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    bottom: 0;
    color: #7f7f7f;
    display: none;
    font-size: 12px;
    line-height: 20px;
    left: 15px;
    padding: 16px 20px 90px;
    position: absolute;
    right: 15px;
    top: 0;
    .name {
      color: #fff;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.4;
      margin: 0 0 7px;
    }
    .role {
      line-height: 1;
      margin: 0 0 24px;
    }
    .contact {
      color: #fff;
    }
    .image {
      background: #f7f7f7;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      display: block;
      height: 60px;
      float: right;
      line-height: 0;
      margin: 4px 0 0 5px;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 60px;
      z-index: 1;
    }
    .social {
      bottom: 20px;
      left: 20px;
      position: absolute;
      text-align: center;
    }
  }
}

.rotation {
  .employee-hover {
    display: block;
    height: inherit;
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-transform: rotateY(-180deg);
    -moz-transform-style: preserve-3d;
    -moz-backface-visibility: hidden;
    -o-transition: all .6s ease-in-out;
    -ms-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    z-index: 13;
  }
  &.hover .employee-hover {
    -moz-transform: rotateX(0deg) rotateY(0deg);
    -moz-transition-delay: .2s;
    -ms-transition-delay: .2s;
    -o-transition-delay: .2s;
    transform: rotateY(0deg);
    transition-delay: .2s;
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transition-delay: .2s;
    z-index: 46;
  }
}

.employee.employee-single {
  margin: 0;
  max-width: inherit;
  width: auto;
}

.employee-single {
  .images-box {
    .carousel-box {
      overflow: hidden;
    }
    .image {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      margin: 0 0 20px;
      text-align: center;
    }
    img {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    .pagination {
      margin-top: 0;
    }
    .carousel-box.load {
      max-height: 390px;
      overflow: hidden;
    }
  }
  .employee-description {
    .name {
      font-size: 22px;
      line-height: 1.3;
      margin: 0 0 5px;
    }
    .role {
      font-size: 14px;
      margin: 0 0 22px;
    }
    .social {
      margin: 22px 0 0;
    }
  }
}

.employee .employee-hover .social .item {
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left;
  margin: 0 10px 0 0;
  .sbtnf {
    float: none;
    margin: 0;
    overflow: inherit;
  }
}

.employee-single-rotation .content-box {
  padding-left: 15px;
  padding-right: 15px;
}

/******************************************************************************
  Contact Us
*******************************************************************************/

.contact-info {
  margin-top: 30px;
  .title {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.3;
    margin: 0 0 22px;
  }
  address {
    margin-top: 5px;
    margin-bottom: 35px;
  }
  hr {
    margin: 0 0 36px;
  }
}

.contact-form .buttons-box {
  margin: 8px 0 0;
}

.map-box {
  position: relative;
  .contact-info {
    background: #fff;
    margin: 0;
    padding: 5px 10px;
    position: absolute;
    top: 150px;
    z-index: 1;
    address {
      margin-bottom: 15px;
    }
  }
}

/******************************************************************************
  Blog
*******************************************************************************/

.post {
  margin-bottom: 40px;
  padding: 0;
}

.blog .post {
  margin-top: -3px;
}

.post {
  + .post {
    border-top-width: 1px;
    padding-top: 37px;
  }
  .entry-title {
    font-size: 26px;
    font-weight: normal;
    margin: 0 0 17px;
    a {
      color: #1e1e1e;
      text-decoration: none;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        text-decoration: none;
        opacity: .8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
  .entry-content {
    margin: 0 0 25px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .entry-meta {
    color: #7f7f7f;
    font-size: 12px;
    line-height: 1.3;
    .separator {
      margin: 0 10px;
    }
    .comments-link {
      background: url("../img/comment-link.png") 0 50% no-repeat;
      padding: 0 0 0 18px;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
      a {
        color: #7f7f7f;
        text-decoration: none;
      }
    }
  }
}

.blog-post {
  .post {
    margin-bottom: 76px;
  }
  .commentlist {
    margin: 0 0 54px;
    padding: 0;
    li {
      padding: 22px 0;
      &:first-child {
        padding-top: 10px;
      }
    }
  }
}

.commentlist li .avatar {
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  border-radius: 42px;
  float: left;
  height: 84px;
  margin: 0 25px 0 0;
  width: 84px;
}

.blog-post {
  .commentlist li {
    .meta {
      margin: 0 0 10px;
    }
    .description {
      font-size: 13px;
      line-height: 20px;
      margin: 0;
      overflow: hidden;
    }
  }
  .comments-form {
    margin: 6px 0 0;
    .comment-box {
      margin: 0;
      text-align: right;
      textarea {
        margin: 0 0 15px;
      }
      i {
        color: #7f7f7f;
        font-size: 11px;
        line-height: 12px;
        vertical-align: top;
      }
    }
    .button-set {
      margin: 19px 0 0;
      .required {
        font-size: 11px;
        line-height: 40px;
        b {
          font-size: 14px;
        }
      }
    }
  }
}

.carousel .post {
  border: 0 none;
  float: left;
  line-height: 22px;
  margin: 0 15px;
  padding: 0;
}

/******************************************************************************
  Search Result
*******************************************************************************/

.search-result {
  .search-form {
    margin-bottom: 40px;
    position: relative;
    .search-string {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      color: #1e1e1e;
      font-size: 24px;
      height: 60px;
      line-height: 30px;
      margin: 0;
      padding: 14px 50px 14px 18px;
      &:focus {
        border-color: #ccc;
      }
    }
    .search-submit {
      background: none;
      border-left: 1px solid #e1e1e1;
      height: 30px;
      line-height: 1;
      position: absolute;
      right: 0;
      top: 15px;
      text-align: center;
      width: 46px;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      svg {
        margin-top: 2px;
      }
      &:hover {
        opacity: .5;
        filter: alpha(opacity = 50);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
  .filter-buttons {
    margin-bottom: 30px;
  }
  .text-results {
    margin-bottom: 30px;
    color: #999;
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 10px;
    text-align: right;
  }
  .item {
    background: none;
    border: 0 none;
    border-top: 1px solid #e1e1e1;
    margin-top: 0 -15px 40px;
    padding: 37px 0 0;
  }
  .pagination {
    margin-top: 0;
  }
}

/******************************************************************************
  Posts Variants
*******************************************************************************/

.post.carousel {
  line-height: 22px;
  .images-box {
    overflow: hidden;
    img, iframe {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
  }
  .carousel-box {
    &.load {
      max-height: 300px;
    }
    margin-bottom: 42px;
    position: relative;
    &.no-pagination {
      margin-bottom: 8px;
    }
    .next, .prev {
      left: 0;
      margin: -20px 5px 0;
      opacity: 0;
      filter: alpha(opacity = 0);
      position: absolute;
      top: 50%;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    &:hover {
      .next, .prev {
        opacity: 1;
        filter: alpha(opacity = 100);
        -webkit-transition: opacity .2s linear, background-color .2s linear;
        transition: opacity .2s linear, background-color .2s linear;
      }
      .next:hover, .prev:hover {
        -webkit-transition: background-color .2s linear;
        transition: background-color .2s linear;
      }
    }
    .next {
      left: auto;
      right: 0;
    }
    .pagination {
      left: 0;
      position: absolute;
      right: 0;
    }
  }
}

/******************************************************************************
  Sitemap
*******************************************************************************/

.sitemap {
  list-style: none;
  margin: 0;
  padding: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0 0 0 15px;
  }
  li {
    background: url("../img/point.png") right 13px repeat-x #fff;
  }
  a {
    background: #fff;
    color: #1e1e1e;
    font-size: 12px;
    line-height: 1;
    padding: 0 5px 0 12px;
    position: relative;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    &:hover {
      opacity: .8;
      filter: alpha(opacity = 80);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    &:before {
      content: "\f105";
      display: block;
      font-family: FontAwesome;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 12px;
      left: 0;
      position: absolute;
      top: 2px;
    }
  }
}

/******************************************************************************
  Pricing
*******************************************************************************/

.pricing {
  background-color: #fff;
  margin: 0 auto 40px;
  max-width: 270px;
  position: relative;
  text-align: left;
  * {
    position: relative;
  }
  &:before {
    background: #f7f7f7;
    background: rgba(30, 30, 30, 0.06);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .title {
    line-height: 32px;
    padding: 17px 20px 21px;
    a {
      color: #1e1e1e;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      text-decoration: none;
    }
  }
  .price-box {
    font-size: 12px;
    line-height: 1;
    overflow: hidden;
    padding: 0 20px 20px;
    .icon {
      background: #fff;
      color: #505050;
      height: 60px;
      text-align: center;
      width: 60px;
      z-index: 1;
      i {
        background: none;
        font-size: 30px;
        height: auto;
        line-height: 52px;
        margin: 0;
        width: auto;
      }
      .livicon {
        background: none;
        font-size: 30px;
        height: auto;
        line-height: 52px;
        margin: 0;
        width: auto;
        height: 60px !important;
        svg {
          top: 0 !important;
          vertical-align: middle;
        }
      }
    }
    .price {
      font-size: 36px;
      font-weight: bold;
      margin: 13px 0 0;
      span {
        font-size: 12px;
      }
    }
  }
  .options {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-top: 1px solid rgba(30, 30, 30, 0.1);
      color: #999;
      color: rgba(30, 30, 30, 0.4);
      line-height: 22px;
      padding: 13px 10px 13px 45px;
      position: relative;
      span {
        color: #1e1e1e;
        display: none;
        left: 25px;
        line-height: 1;
        position: absolute;
        top: 16px;
      }
      &.active {
        color: #1e1e1e;
        span {
          display: block;
        }
      }
    }
  }
  .bottom-box {
    border-top: 1px solid rgba(30, 30, 30, 0.1);
    background: rgba(30, 30, 30, 0.05);
    overflow: hidden;
    padding: 19px 19px 20px;
    .more {
      color: #7f7f7f;
      color: rgba(30, 30, 30, 0.7);
      display: block;
      float: left;
      font-size: 12px;
      line-height: 1;
      text-decoration: none;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .65;
        filter: alpha(opacity = 65);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
      span {
        font-size: 17px;
        line-height: 12px;
        margin: 0 0 0 3px;
        vertical-align: top;
      }
    }
    .rating-box {
      float: right;
    }
    .btn {
      font-weight: bold;
      margin: 19px 0 0;
      width: 100%;
    }
  }
  &.prising-info {
    &:before {
      background: rgba(1, 165, 219, 0.06);
    }
    .title a {
      color: #01a5db;
    }
    .price-box .icon {
      color: #35beeb;
      border-color: #35beeb;
    }
    .options li {
      border-color: rgba(1, 165, 219, 0.1);
      color: rgba(1, 165, 219, 0.4);
    }
    .bottom-box {
      border-color: rgba(1, 165, 219, 0.1);
      color: rgba(1, 165, 219, 0.4);
      border-top: 1px solid rgba(1, 165, 219, 0.1);
      background: rgba(1, 165, 219, 0.05);
    }
    .options li span, .bottom-box .more, .options li.active {
      color: #01a5db;
    }
  }
  &.pricing-success {
    &:before {
      background: rgba(132, 162, 0, 0.06);
    }
    .title a {
      color: #84a200;
    }
    .price-box .icon {
      border-color: #9ab71a;
      color: #9ab71a;
    }
    .options li {
      border-color: rgba(132, 162, 0, 0.1);
      color: rgba(132, 162, 0, 0.4);
    }
    .bottom-box {
      border-color: rgba(132, 162, 0, 0.1);
      color: rgba(132, 162, 0, 0.4);
      border-top: 1px solid rgba(132, 162, 0, 0.1);
      background: rgba(132, 162, 0, 0.05);
      .more {
        color: #84a200;
      }
    }
    .options li {
      span, &.active {
        color: #84a200;
      }
    }
  }
  &.pricing-error {
    &:before {
      background: rgba(212, 7, 70, 0.06);
    }
    .title a {
      color: #d40746;
    }
    .price-box .icon {
      border-color: #de2a61;
      color: #de2a61;
    }
    .options li {
      border-color: rgba(212, 7, 70, 0.1);
      color: rgba(212, 7, 70, 0.4);
    }
    .bottom-box {
      border-color: rgba(212, 7, 70, 0.1);
      color: rgba(212, 7, 70, 0.4);
      border-top: 1px solid rgba(212, 7, 70, 0.1);
      background: rgba(212, 7, 70, 0.05);
    }
    .options li span, .bottom-box .more, .options li.active {
      color: #d40746;
    }
  }
}

/******************************************************************************
  404
*******************************************************************************/

.box-404 {
  -webkit-border-radius: 177px;
  -moz-border-radius: 177px;
  border-radius: 177px;
  font-weight: normal;
  height: 354px;
  margin: 0 auto;
  padding: 92px 0 0;
  text-align: center;
  width: 354px;
  h1 {
    font-size: 150px;
    font-weight: normal;
    line-height: 0.9;
    margin: 0;
  }
  h2 {
    color: #7f7f7f;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    margin: 0;
  }
}

/******************************************************************************
  FAQ
*******************************************************************************/

.filter-elements-wrapper {
  margin: 0 auto;
}

.filter-buttons {
  display: block;
  margin-bottom: 30px;
  .dropdown-toggle {
    background: transparent;
    border: 1px solid #e1e1e1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #1e1e1e;
    display: block;
    float: left;
    font-weight: bold;
    height: 38px;
    line-height: 38px;
    margin-right: 20px;
    padding: 0 10px 0 20px;
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
    .caret {
      margin: -2px 0 0 5px;
    }
    &:hover {
      background: transparent;
      text-decoration: none;
      opacity: .7;
      filter: alpha(opacity = 70);
      -webkit-transition: opacity .2s ease-out;
      transition: opacity .2s ease-out;
    }
  }
  &.open .dropdown-toggle {
    background: transparent;
  }
  .dropdown-menu {
    border: 1px solid #e1e1e1;
    .active {
      background-color: #f5f5f5;
    }
  }
  &.white .dropdown-toggle {
    color: #fff;
    .caret {
      color: #fff;
    }
  }
}

/******************************************************************************
  Portfolio
*******************************************************************************/

.isotope {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
  }
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
  &.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
    .isotope-item {
      -webkit-transition-duration: 0s;
      -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
      -o-transition-duration: 0s;
      transition-duration: 0s;
    }
  }
  .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
  }
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.year-regulator {
  padding-top: 13px;
  .label {
    color: inherit;
    display: table-cell;
    font-size: 12px;
    line-height: 14px;
    padding: 0 20px 0 0;
    text-align: left;
    vertical-align: middle;
  }
  .layout-slider {
    display: table-cell;
    width: 100%;
    input[type="slider"] {
      visibility: hidden;
    }
  }
}

.portfolio {
  .filter-elements {
    overflow: visible !important;
  }
  .work {
    margin-bottom: 40px;
  }
}

.work {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  display: block;
  position: relative;
  text-align: center;
  text-shadow: 0 1px #000;
  &:visited {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    display: block;
    position: relative;
    text-align: center;
    text-shadow: 0 1px #000;
  }
  &:hover {
    display: block;
    text-decoration: none;
  }
}

.carousel .work {
  margin: 2px 1px;
}

.work {
  img {
    background-color: #555;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    width: 100%;
  }
  .shadow {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(29%, rgba(255, 255, 255, 0.01)), color-stop(30%, rgba(0, 0, 0, 0.01)), color-stop(33%, rgba(0, 0, 0, 0.01)), color-stop(62%, rgba(0, 0, 0, 0.17)), color-stop(68%, rgba(0, 0, 0, 0.24)), color-stop(90%, rgba(0, 0, 0, 0.62)), color-stop(100%, rgba(0, 0, 0, 0.73)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .work-title {
    bottom: 37px;
    left: 0;
    margin: 0 0 -17px;
    padding: 0 5px;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transition: bottom .2s ease-in;
    transition: bottom .2s ease-in;
  }
  .title {
    bottom: 46px;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 0 6px;
    text-align: center;
  }
  .description {
    font-size: 11px;
    font-style: italic;
    font-family: Georgia;
    line-height: 16px;
    text-align: center;
  }
  .bg-hover {
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity = 0);
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  &:hover {
    text-shadow: none;
    .work-title {
      bottom: 46%;
      color: #1e1e1e;
      -webkit-transition: bottom .2s ease-in;
      transition: bottom .2s ease-in;
    }
    .bg-hover {
      opacity: 1;
      filter: alpha(opacity = 100);
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in;
    }
  }
}

.work-single {
  .carousel-box {
    overflow: hidden;
    .carousel img {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    &.load {
      max-height: 270px;
    }
  }
  .title {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 5px;
  }
  .type {
    color: #7f7f7f;
    font-size: 14px;
    margin: 0 0 22px;
  }
}

/* Portfolio One */

.work-one {
  color: #7f7f7f;
  max-width: 100%;
  overflow: hidden;
  + .work-one {
    margin-top: 40px;
  }
  .title {
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    margin: 0 0 7px;
    a {
      color: #1e1e1e;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .8;
        filter: alpha(opacity = 80);
        text-decoration: none;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
  .description {
    font-size: 11px;
    line-height: 14px;
    margin: 0 0 27px;
  }
  .work-image {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: block;
    line-height: 0;
    overflow: hidden;
    img {
      -webkit-transition: all 1s ease;
      -ms-transition: all 1s ease;
      transition: all 1s ease;
      &:hover {
        -webkit-filter: grayscale(100%);
      }
    }
  }
}

/******************************************************************************
  Galery
*******************************************************************************/

.gallery .images-box {
  margin-bottom: 30px;
}

.gallery-images {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: block;
  line-height: 0;
  max-width: 100%;
  position: relative;
  img {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .bg-images {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  &:hover > .bg-images {
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  .bg-images i {
    margin: 5px 0 0 5px;
    &:before {
      color: #fff;
      font-size: 20px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
    }
  }
  &:hover .bg-images i:before {
    color: #000;
    font-size: 30px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
}

/******************************************************************************
  Respond
*******************************************************************************/

.respond {
  background: none;
  border: 0 none;
  .description {
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #1e1e1e;
    font-style: italic;
    font-size: 90%;
    line-height: 1.4;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
  }
  &.bg .description {
    background-color: #a5e573;
  }
  &.border .description {
    border-color: #a5e573;
    border-width: 1px;
  }
  &.bg .description:before {
    border: 10px solid transparent;
    border-top-color: #a5e573;
    bottom: -20px;
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
  }
  &.border .description {
    &:before {
      border: 10px solid transparent;
      border-top-color: #a5e573;
      bottom: -20px;
      content: "";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
    }
    &:after {
      border: 10px solid transparent;
      border-top-color: #fff;
      bottom: -18px;
      content: "";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
    }
  }
  .name {
    color: #7f7f7f;
    min-height: 22px;
    padding: 5px 5px 5px 5px;
  }
  &.white .name {
    color: #fff;
  }
  .name .icon {
    color: #7f7f7f;
    font-size: 30px;
    margin: -5px 0 0;
  }
  &.white .name .icon {
    color: #fff;
  }
}

.respond-carousel {
  overflow: hidden;
  .pagination {
    margin: 0;
  }
}

.respond {
  &.respond-blockquote {
    blockquote {
      border: none;
      margin: 0;
      padding-left: 30px;
      &:before {
        content: open-quote;
        font-size: 50px;
        font-weight: bold;
        left: 16px;
        line-height: 1;
        position: absolute;
        top: 14px;
      }
      &:after {
        content: close-quote;
        display: inline-block;
        font-size: 50px;
        font-weight: bold;
        line-height: 0;
        margin: 0 0 -5px 0;
        vertical-align: bottom;
      }
    }
    &.border blockquote {
      &:before, &:after {
        color: #a5e573;
      }
    }
    .name {
      .icon {
        float: left;
        height: 50px;
        margin: 0 10px 0 0;
        width: 50px;
      }
      strong {
        font-size: 120%;
      }
    }
  }
  .description {
    &.bg-info, &.bg-primary, &.bg-success, &.bg-warning, &.bg-danger {
      color: #fff;
    }
    &.bg-info:before, &.border-info:before {
      border-top-color: #0098ca;
    }
    &.bg-primary:before, &.border-primary:before {
      border-top-color: #037ac5;
    }
    &.bg-success:before, &.border-success:before {
      border-top-color: #738d00;
    }
    &.bg-warning:before, &.border-warning:before {
      border-top-color: #f89406;
    }
    &.bg-danger:before, &.border-danger:before {
      border-top-color: #c10841;
    }
    &.bg-white:before, &.border-white:before {
      border-top-color: #fff;
    }
  }
  &.border .description.border-white {
    border-color: #fff;
  }
  &.respond-blockquote.border {
    .border-info blockquote {
      &:before, &:after {
        color: #0098ca;
      }
    }
    .border-primary blockquote {
      &:before, &:after {
        color: #037ac5;
      }
    }
    .border-success blockquote {
      &:before, &:after {
        color: #738d00;
      }
    }
    .border-warning blockquote {
      &:before, &:after {
        color: #f89406;
      }
    }
    .border-danger blockquote {
      &:before, &:after {
        color: #c10841;
      }
    }
    .border-white blockquote {
      &:before, &:after {
        color: #1e1e1e;
      }
    }
  }
}

/******************************************************************************
  Chart
*******************************************************************************/

.chart {
  position: relative;
  text-align: center;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.graph text {
  overflow: hidden !important;
}

.morris-hover {
  z-index: 700;
}

/******************************************************************************
  Livicons
*******************************************************************************/

.livicon {
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
  svg {
    vertical-align: top;
  }
}

.big-icon .livicon svg, .icon .livicon svg {
  top: 25% !important;
}

.livicon.metro-bg {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin-bottom: 4px;
  padding: 26px 25px 26px 27px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

/******************************************************************************
  Steps
*******************************************************************************/

.steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  .step {
    background: #fff;
    border: 1px solid #e1e1e1;
    display: table-cell;
    padding: 15px;
    position: relative;
    vertical-align: middle;
    .step-wrapper {
      position: relative;
    }
    + .step {
      border-left: 0 none;
    }
    &:first-child {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
    .step-wrapper {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -29px;
        border: 7px solid transparent;
        border-left-color: #e1e1e1;
        z-index: 1;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: -29px;
        border: 7px solid transparent;
        border-left-color: #e1e1e1;
        z-index: 1;
        border-left-color: #fff;
        right: -27px;
        z-index: 2;
      }
    }
    &:last-child .step-wrapper {
      &:after, &:before {
        display: none;
      }
    }
    .bg-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      bottom: 0;
      left: 0;
      opacity: .1;
      filter: alpha(opacity = 10);
      position: absolute;
      right: 0;
      top: 0;
      ~ * {
        position: relative;
      }
    }
    &.border-warning .step-wrapper:after {
      border-left-color: #f89406;
    }
    &.border-error .step-wrapper:after {
      border-left-color: #c10841;
    }
    &.border-info .step-wrapper:after {
      border-left-color: #0098ca;
    }
    &.border-success .step-wrapper:after {
      border-left-color: #738d00;
    }
    &.border-grey .step-wrapper:after {
      border-left-color: #777;
    }
    &.bg-warning .step-wrapper:before {
      border-left-color: #f89406;
    }
    &.bg-error .step-wrapper:before {
      border-left-color: #c10841;
    }
    &.bg-info .step-wrapper:before {
      border-left-color: #0098ca;
    }
    &.bg-success .step-wrapper:before {
      border-left-color: #738d00;
    }
    &.bg-grey {
      background: #777;
      .step-wrapper:before {
        border-left-color: #777;
      }
    }
  }
  &.steps-apart {
    display: block;
    width: auto;
    .step {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      display: block;
      &:last-child .step-wrapper {
        &:after, &:before {
          display: block;
        }
      }
    }
    > [class*="col-md"]:last-child > .step .step-wrapper {
      &:after, &:before {
        display: none;
      }
    }
  }
  &.progress-steps {
    display: block;
    .step {
      background: none !important;
      border: 1px solid #e1e1e1;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      display: block;
      margin: 0 0 40px;
      min-height: 185px;
      padding: 0;
      .step-wrapper {
        padding: 20px 20px 20px 260px;
        position: static;
      }
      &.step-right .step-wrapper {
        padding: 20px 260px 20px 20px;
      }
      .number {
        background: #e1e1e1;
        bottom: 0;
        color: #fff;
        font-size: 150px;
        left: 0;
        line-height: 1.2;
        position: absolute;
        text-align: center;
        top: 0;
        width: 240px;
      }
      &.step-right .number {
        left: auto;
        right: 0;
      }
      .step-wrapper {
        &:after, &:before {
          display: none;
        }
      }
      &:last-child .number {
        &:after, &:before {
          display: none;
        }
      }
      .number {
        &:after {
          border: 7px solid transparent;
          border-top-color: #e1e1e1;
          bottom: -15px;
          content: '';
          left: 50%;
          margin-left: -7px;
          position: absolute;
          z-index: 1;
        }
        &:before {
          border: 7px solid transparent;
          border-top-color: #e1e1e1;
          bottom: -15px;
          content: '';
          left: 50%;
          margin-left: -7px;
          position: absolute;
          z-index: 1;
          bottom: -13px;
          z-index: 2;
        }
      }
      &.border-warning .number:after {
        border-top-color: #f89406;
      }
      &.border-error .number:after {
        border-top-color: #c10841;
      }
      &.border-info .number:after {
        border-top-color: #0098ca;
      }
      &.border-success .number:after {
        border-top-color: #738d00;
      }
      &.bg-warning .number {
        background: #f89406;
        &:before {
          border-top-color: #f89406;
        }
      }
      &.bg-error .number {
        background: #c10841;
        &:before {
          border-top-color: #c10841;
        }
      }
      &.bg-info .number {
        background: #0098ca;
        &:before {
          border-top-color: #0098ca;
        }
      }
      &.bg-success .number {
        background: #738d00;
        &:before {
          border-top-color: #738d00;
        }
      }
      &.border-warning .number {
        color: #f89406;
      }
      &.border-error .number {
        color: #c10841;
      }
      &.border-info .number {
        color: #0098ca;
      }
      &.border-success .number {
        color: #738d00;
      }
      &.bg-warning .number, &.bg-error .number, &.bg-info .number, &.bg-success .number {
        color: #fff;
      }
    }
  }
}

/******************************************************************************
  Full Width Box
*******************************************************************************/

.full-width-box {
  overflow: hidden;
  padding: 76px 0;
  position: relative;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &.auto-width {
    margin-left: -15px;
    margin-right: -15px;
    opacity: 0;
  }
}

.container .full-width-box {
  margin-left: -15px;
  margin-right: -15px;
}

.full-width-box {
  &.auto-width.loaded {
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  .fwb-bg {
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-background-size: cover;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 0;
  }
  .container, .page-header {
    position: relative;
  }
  .fwb-bg .overlay {
    background: #000;
    height: 100%;
    opacity: .2;
    filter: alpha(opacity = 20);
    width: 100%;
  }
  .fwb-fixed {
    background-attachment: fixed;
  }
}

.touch-device .full-width-box {
  .fwb-fixed, .fwb-paralax {
    background-attachment: scroll;
  }
}

.full-width-box {
  .fwb-blur {
    background-position: center top !important;
    .blur-box {
      bottom: 0;
      display: block !important;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -100;
      canvas {
        background-size: cover;
        background-attachment: fixed;
        max-width: inherit;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        vertical-align: top;
        width: 100%;
      }
    }
  }
  .fwb-video {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    video, iframe, img {
      background-attachment: fixed;
      vertical-align: top;
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: inherit;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
    }
  }
}

/******************************************************************************
  Word Rotate
*******************************************************************************/

.word-rotate {
  background: #1e1e1e;
  display: inline-block;
  overflow: hidden;
  padding: 0 8px;
  text-align: center;
  vertical-align: top;
  &:not(.border) {
    color: #fff;
  }
  .words-box {
    display: inline-block;
    position: relative;
    span {
      display: block;
      white-space: nowrap;
      + span {
        display: none;
      }
    }
  }
  &.loaded .words-box span + span {
    display: block;
  }
  &.border {
    background: none;
    border: 1px solid #1e1e1e;
  }
}

/******************************************************************************
  Timeline
*******************************************************************************/

.timeline {
  padding: 20px 0;
  position: relative;
  &:before {
    background-color: #d2d2d2;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px;
  }
  .post {
    border: 0 none;
    margin: 0;
    padding: 0 40px 5px 0;
    width: 50%;
    &:last-child, &:nth-child(even):last-child {
      padding-bottom: 0;
    }
    .timeline-time {
      color: #999;
      left: 50%;
      margin: 31px 0 0 40px;
      position: absolute;
    }
    .timeline-icon {
      background: #1e1e1e;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      color: #fff;
      font-size: 18px;
      height: 40px;
      left: 50%;
      line-height: 40px;
      margin: 22px 0 0 -20px;
      position: absolute;
      text-align: center;
      width: 40px;
      .livicon svg {
        left: 0 !important;
      }
    }
    .timeline-content {
      background: #fff;
      border: 1px solid #ccc;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      padding: 46px;
      position: relative;
    }
    &.no-padding .timeline-content {
      padding: 0;
    }
    .timeline-content {
      .video-box:last-child, blockquote:last-child {
        margin-bottom: 0;
      }
      .map-canvas {
        height: 250px;
      }
      .img-rounded {
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
      &:before {
        border: 15px solid transparent;
        border-left-color: #ccc;
        content: "";
        display: block;
        position: absolute;
        right: -30px;
        top: 26px;
      }
      &:after {
        border: 15px solid transparent;
        border-left-color: #ccc;
        content: "";
        display: block;
        position: absolute;
        right: -30px;
        top: 26px;
        border: 14px solid transparent;
        border-left-color: #fff;
        right: -28px;
        top: 27px;
      }
      .entry-title {
        font-size: 18px;
        margin: 0 0 10px;
      }
      .entry-content {
        margin: 0;
      }
    }
    &:nth-child(even) {
      margin-left: 50%;
      padding: 0 0 5px 40px;
      .timeline-time {
        left: auto;
        margin: 31px 40px 0 0;
        right: 50%;
        text-align: right;
      }
      .timeline-content {
        &:before {
          border: 15px solid transparent;
          border-right-color: #ccc;
          left: -30px;
          right: auto;
        }
        &:after {
          border: 14px solid transparent;
          border-right-color: #fff;
          left: -28px;
          right: auto;
        }
      }
    }
    .timeline-content.bg {
      background: #ccc;
    }
    &:not(.no-padding) .timeline-content.bg:after {
      display: none;
    }
    .timeline-content {
      &.bg {
        color: #fff;
        .entry-title a {
          color: #fff;
        }
        blockquote {
          footer, small, .small {
            color: #fff;
          }
        }
      }
      &.bg-danger, &.border-danger {
        border-color: #c10841;
      }
      &.bg-danger:before, &.border-danger:before {
        border-left-color: #c10841;
      }
    }
    &:nth-child(even) .timeline-content {
      &.bg-danger:before, &.border-danger:before {
        border: 15px solid transparent;
        border-right-color: #c10841;
      }
    }
    .timeline-content {
      &.bg-warning, &.border-warning {
        border-color: #f89406;
      }
      &.bg-warning:before, &.border-warning:before {
        border-left-color: #f89406;
      }
    }
    &:nth-child(even) .timeline-content {
      &.bg-warning:before, &.border-warning:before {
        border: 15px solid transparent;
        border-right-color: #f89406;
      }
    }
    .timeline-content {
      &.bg-info, &.border-info {
        border-color: #0098ca;
      }
      &.bg-info:before, &.border-info:before {
        border-left-color: #0098ca;
      }
    }
    &:nth-child(even) .timeline-content {
      &.bg-info:before, &.border-info:before {
        border: 15px solid transparent;
        border-right-color: #0098ca;
      }
    }
    .timeline-content {
      &.bg-success, &.border-success {
        border-color: #738d00;
      }
      &.bg-success:before, &.border-success:before {
        border-left-color: #738d00;
      }
    }
    &:nth-child(even) .timeline-content {
      &.bg-success:before, &.border-success:before {
        border: 15px solid transparent;
        border-right-color: #738d00;
      }
    }
    .timeline-content {
      &.bg-primary, &.border-primary {
        border-color: #428bca;
      }
      &.bg-primary:before, &.border-primary:before {
        border-left-color: #428bca;
      }
    }
    &:nth-child(even) .timeline-content {
      &.bg-primary:before, &.border-primary:before {
        border: 15px solid transparent;
        border-right-color: #428bca;
      }
    }
  }
}

/******************************************************************************
  Social Feed
*******************************************************************************/

.social-feed {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 100px;
  background-size: 40px;
  > * {
    opacity: 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
  }
  &.loaded {
    background: none;
    > * {
      opacity: 1;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
  .isotope-item {
    margin-bottom: 20px;
  }
  .post {
    background: #fff;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0;
    padding: 19px;
  }
  .add-post {
    background: none;
    border: 0 none;
    padding: 0;
    .nav-tabs {
      border: 0 none;
      > li {
        > a {
          -webkit-border-radius: 3px 3px 0 0;
          -moz-border-radius: 3px 3px 0 0;
          border-radius: 3px 3px 0 0;
          padding: 6px 10px 7px;
          .fa {
            color: #7f7f7f;
          }
        }
        &.active > a {
          font-weight: normal;
          &:hover, &:focus {
            font-weight: normal;
          }
          .fa {
            color: #555;
          }
        }
      }
    }
    .tab-content {
      background: #fff;
      border: 1px solid #ddd;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      padding: 20px;
      .tab-pane {
        padding: 0;
        form {
          margin: 0;
        }
      }
    }
    .tab-pane input {
      margin-bottom: 22px;
    }
  }
  .author-info {
    .avatar {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      float: left;
      height: 50px;
      margin: 0 20px 0 0;
      overflow: hidden;
      width: 50px;
    }
    .user-name {
      font-size: 16px;
      a {
        color: #1e1e1e;
        text-decoration: none;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
        &:hover {
          opacity: .8;
          filter: alpha(opacity = 80);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
      }
    }
    time {
      color: #7f7f7f;
      font-style: italic;
    }
  }
  .preview {
    margin: 0 -20px 20px;
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .buttons {
    margin-bottom: 20px;
    text-align: right;
    .btn {
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      &:hover {
        opacity: .8;
        filter: alpha(opacity = 80);
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
      }
    }
  }
  .comments {
    background: rgba(0, 0, 0, 0.1);
    margin: 0 -20px -20px;
    padding: 20px;
    .comment {
      margin: 0 0 25px;
      .avatar {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        float: left;
        height: 40px;
        margin: 0 10px 5px 0;
        overflow: hidden;
        width: 40px;
      }
      .author-details {
        margin-bottom: 5px;
      }
      .user-name a {
        color: #1e1e1e;
        text-decoration: none;
        -webkit-transition: opacity .2s linear;
        transition: opacity .2s linear;
        &:hover {
          opacity: .8;
          filter: alpha(opacity = 80);
          -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
        }
      }
      time {
        color: #7f7f7f;
        font-size: 12px;
        font-style: italic;
      }
    }
    .form {
      margin: 0;
      .form-control {
        height: 62px;
        margin: 0;
      }
      textarea {
        resize: none;
      }
    }
  }
  .post {
    &.bg {
      background: #ccc;
      color: #fff;
    }
    &.bg-danger {
      border-color: #c10841;
    }
    &.bg-warning {
      border-color: #f89406;
    }
    &.bg-info {
      border-color: #0098ca;
    }
    &.bg-success {
      border-color: #738d00;
    }
    &.bg-primary {
      border-color: #428bca;
    }
  }
  .author-info {
    margin-bottom: 15px;
  }
  .post.number {
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    text-align: center;
    .post-icon {
      font-size: 24px;
      left: 10px;
      opacity: .8;
      filter: alpha(opacity = 80);
      position: absolute;
      top: 10px;
    }
    .social-data {
      display: inline-block;
      font-style: italic;
      line-height: 1;
      vertical-align: middle;
      .count {
        font-size: 60px;
        font-style: normal;
      }
    }
  }
  .bg {
    .author-info .user-name a, .comments .comment .user-name a {
      color: #fff;
    }
    .author-info time, .comments .comment time {
      color: rgba(255, 255, 255, 0.8);
    }
    .buttons .btn {
      background: #fff;
      border-color: #fff;
      color: #1e1e1e;
    }
  }
  .bg-danger .buttons .btn {
    color: #c10841;
  }
  .bg-warning .buttons .btn {
    color: #f89406;
  }
  .bg-info .buttons .btn {
    color: #0098ca;
  }
  .bg-success .buttons .btn {
    color: #738d00;
  }
  .bg-primary .buttons .btn {
    color: #428bca;
  }
  .post.number {
    &.twitter {
      background: #00aced;
      border-color: #00aced;
    }
    &.facebook {
      background: #3B5998;
      border-color: #3B5998;
    }
    &.google {
      background: #dd4b39;
      border-color: #dd4b39;
    }
    &.pinterest {
      background: #cb2027;
      border-color: #cb2027;
    }
    &.linkedin {
      background: #007bb6;
      border-color: #007bb6;
    }
    &.youtube {
      background: #bb0000;
      border-color: #bb0000;
    }
  }
}

/******************************************************************************
  Blur Pages
*******************************************************************************/

.blur-page {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  .page-box {
    opacity: 0;
  }
  .blur-box {
    background-repeat: no-repeat;
    background-position: center top;
    -webkit-background-size: cover;
    background-size: cover;
    bottom: 0;
    display: block !important;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    text-align: center;
    z-index: -1;
  }
  &.blur-load {
    background-image: inherit;
    -webkit-transition: background-image .2s linear;
    transition: background-image .2s linear;
    .page-box {
      opacity: 1;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
    .blur-box {
      opacity: 1;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
      -webkit-transition: opacity .4s linear;
      transition: opacity .4s linear;
    }
  }
  .blur-box {
    img, canvas {
      -webkit-background-size: cover;
      background-size: cover;
      left: 0;
      max-width: inherit;
      min-width: 100%;
      min-height: 100%;
      position: fixed;
      top: 0;
      vertical-align: top;
      width: auto;
      z-index: 0;
    }
    img {
      visibility: hidden;
    }
  }
}

/******************************************************************************
  Pages 404
*******************************************************************************/

.page-404-promo {
  .page-box {
    background: #2b8cd6;
    background: rgba(43, 140, 214, 0.9);
    display: table;
    height: 100% !important;
    width: 100%;
  }
  #main {
    display: table-cell;
    padding: 20px 0;
    vertical-align: middle;
    .title {
      border-bottom: 1px solid #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      color: #fff;
      padding: 0 10px;
      text-align: center;
      h1 {
        display: inline-block;
        font-size: 200px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
        position: relative;
        &:before {
          background: #fff;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
    .content {
      color: #fff;
      padding: 35px 10px 0;
      text-align: center;
      h2 {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 38px;
      }
      .back-home {
        color: #2b8cd6;
        font-size: 18px;
        padding-right: 30px;
        padding-left: 20px;
        .fa {
          font-size: 22px;
          margin-right: 8px;
        }
      }
    }
  }
}

/******************************************************************************
  One Page
*******************************************************************************/

.one-page {
  .header .primary .navbar .nav .active {
    color: #c10841;
  }
  #main {
    padding-top: 0;
    padding-bottom: 0;
  }
  .full-width-box:last-child {
    padding-bottom: 0;
  }
  .page-header {
    margin-bottom: 40px;
  }
}

/******************************************************************************
  Other Pages
*******************************************************************************/

.body-bg-img {
  background: url("../img/content/coming-soon.jpg") 50% 0 no-repeat;
  &.under-construction {
    background: url("../img/content/under-construction.jpg") 50% 0 no-repeat;
  }
  .page-box {
    display: table;
    height: 100% !important;
    width: 100%;
  }
  .header-three {
    min-height: 1px;
    position: absolute;
    width: 100%;
  }
  #main {
    display: table-cell;
    padding: 119px 0 192px;
    vertical-align: middle;
  }
}

.services {
  .service, .features-block {
    margin-bottom: 40px;
  }
}

.services-box-three-left {
  margin-bottom: 40px;
  padding: 20px;
}

.count-down-box {
  float: right;
}

#count-down {
  text-align: right;
  width: auto !important;
  .county-days-wrapper, .county-hours-wrapper, .county-minutes-wrapper, .county-seconds-wrapper {
    background: #c10841;
    background: rgba(193, 8, 65, 0.5);
    display: inline-block;
    height: 135px;
    margin-left: 2px;
    margin-right: 2px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 23% !important;
  }
  .county-hours-wrapper {
    background: #f89406;
    background: rgba(248, 148, 6, 0.5);
  }
  .county-minutes-wrapper {
    background: #0098ca;
    background: rgba(0, 152, 202, 0.5);
  }
  .county-seconds-wrapper {
    background: #738d00;
    background: rgba(115, 141, 0, 0.5);
  }
  .county-days, .county-hours, .county-minutes, .county-seconds {
    font-size: 60px;
    color: #fff;
    line-height: 135px;
    display: inline-block !important;
    opacity: 1 !important;
    width: auto !important;
  }
  .county-label-days, .county-label-hours, .county-label-minutes, .county-label-seconds {
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 10px;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 23% !important;
  }
}

.coming-text {
  margin-top: -4px;
  form {
    position: relative;
  }
  .success {
    bottom: 100%;
    margin-bottom: 5px;
    position: absolute;
  }
  .form-control {
    padding-right: 100px;
  }
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
  }
}

.under-contact {
  .phone, .address {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0 0 34px;
    position: relative;
  }
  .footer-icon {
    color: #000;
    left: 16px;
    position: absolute;
    top: 0;
    &:before {
      font-size: 20px;
      line-height: 18px;
      width: auto;
    }
  }
}

.list-images > .col-md-4 {
  margin-bottom: 30px;
}

/******************************************************************************
  Footer
*******************************************************************************/

#footer {
  color: #7f7f7f;
  margin: -310px 0 0 0;
  overflow-x: hidden;
  &.footer-two {
    margin: -176px 0 0 0;
  }
  .footer-top {
    background: #f2f2f2;
    font-size: 12px;
    line-height: 21px;
    padding: 50px 0 13px;
  }
  &.footer-two .footer-top {
    padding-bottom: 43px;
  }
  .sidebar .widget {
    margin-bottom: 30px;
    &:nth-child(4n+1) {
      clear: left;
    }
    header, .title-block {
      margin: 0 0 15px;
      padding: 0 0 12px;
    }
    header:before, .title-block:before {
      display: none;
    }
    header .title, .title-block .title {
      color: #1e1e1e;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3;
      text-transform: uppercase;
    }
    .next, .prev {
      height: 26px;
      line-height: 26px;
      width: 26px;
    }
    .next svg, .prev svg {
      height: 14px;
    }
  }
  .widget p {
    margin: 0 0 16px;
  }
  .sidebar {
    .compare-products li {
      background-color: #f2f2f2;
      .title, .close {
        background-color: #f2f2f2;
      }
    }
    .wishlist .add-cart {
      background-color: #f2f2f2;
    }
  }
}

.footer-two .social {
  text-align: center;
  .sbtnf {
    display: inline-block;
    float: none;
    margin: 0 3px;
  }
}

#footer {
  .footer-bottom {
    background: #f7f7f7;
    border-top-width: 1px;
    font-size: 11px;
    line-height: 17px;
    padding: 21px 0 20px;
  }
  .phone, .address {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 34px;
    position: relative;
  }
  .footer-icon {
    color: #c6c6c6;
    left: 16px;
    position: absolute;
    top: 0;
    &:before {
      font-size: 20px;
      line-height: 18px;
      width: auto;
    }
  }
  .up {
    background: #c6c6c6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    float: right;
    height: 40px;
    text-align: center;
    width: 40px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    &:hover {
      background: #000;
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
    }
    .glyphicon {
      color: #fff;
      margin: 14px 0 0 -.5px;
      vertical-align: top;
    }
  }
}

/******************************************************************************
  Boxed
*******************************************************************************/

.boxed {
  background: #fff;
  margin: 0 auto;
  max-width: 1300px;
  padding-top: 20px;
  .page-box {
    background: #fff;
    -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 18px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 18px;
    overflow: hidden;
    position: relative;
  }
  &.loaded .page-box {
    overflow: visible;
  }
  &.fixed-header {
    .header {
      margin-top: 0;
      position: absolute;
    }
    &.fixed .header {
      position: fixed;
      top: 0;
    }
    &.hidden-top {
      .page-box {
        padding-top: 0;
        -webkit-transition: none;
        transition: none;
      }
      #top-box {
        left: 0;
        margin-top: 0;
        margin-bottom: 104px;
        position: static;
        -webkit-transition: none;
        transition: none;
      }
      .header {
        top: 40px;
      }
      &.fixed .header {
        top: 0;
      }
    }
  }
  .header-wrapper {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    max-width: 1300px;
  }
  .banner-set.banner-set-mini.banner-set-no-pagination {
    .pagination {
      display: inline-block !important;
    }
    .prev, .next {
      margin: 15px 0 0 !important;
      position: static;
    }
  }
  #footer {
    min-width: 100%;
    padding-bottom: 20px;
    position: relative;
    &.footer-two {
      margin-bottom: 0;
    }
  }
}

/******************************************************************************
  Fancybox
*******************************************************************************/

.fancybox-margin .fixed-header {
  .header, #top-box {
    left: -7.5px;
  }
}

.fancybox-close {
  background: #fff;
  border: 1px solid #7f7f7f;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #1e1e1e;
  font-size: 25px;
  font-weight: normal;
  height: 30px;
  line-height: 29px;
  right: -15px;
  text-align: center;
  text-decoration: none;
  top: -15px;
  width: 30px;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
  &:hover {
    color: #7f7f7f;
    text-decoration: none;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
  }
}

.fancybox-nav span {
  background: #ccc;
  border: 0 none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fbfbfb;
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0 0;
  text-align: center;
  text-decoration: none;
  width: 40px;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  &:hover {
    background-color: #000;
    color: #efefef;
    text-decoration: none;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }
  svg {
    vertical-align: middle;
  }
}

.fontawesome-icon-list {
  color: #7f7f7f;
  .fa {
    color: #1e1e1e;
    width: 22px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    margin-right: 5px;
  }
}

.full-width-box {
  .fwb-fixed, .fwb-paralax {
    background-attachment: fixed;
  }
}

.fixed-header.fixed-top {
  #top-box {
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 871;
  }
  .header {
    margin-top: 40px;
  }
  .page-box {
    padding-top: 144px;
  }
}

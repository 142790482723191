.page-node-160 {
  .title-box {
    border-color: #87ba13;
    &::before {
      background: transparent;
    }
    h2 {
      text-transform: uppercase;
      font-size: 26px;
    }
  }
  h3 {
    color: #87ba13;
  }
  h4 {
    color: #7e4a94;
  }
  .tabs-left {
    .nav-tabs {
      border-right: 1px solid #7e4a94;
    }
    .container  {
      padding-left: 15px;
      padding-right: 15px;
    }
    ul li.active, ul li.active a {
      background: #c10841 !important;
      border-radius: 0;
      border: 0;
    }
    ul li.active a {
      color: #fff;
    }
    .tab-content {
      border-left: 1px solid #7e4a94;
      margin: 0;
      overflow: auto;
    }
    .col-sm-4, .col-sm-6 {
      text-align: center;
    }
  }
}
